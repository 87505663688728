import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { createClient } from "api/services/clients/clients";
import { getUsers } from "api/services/users";
import DrawerWrapper from "components/DrawerWrapper";
import FormInput from "components/FormFields/FormInput";
import FormSelect from "components/FormFields/FormSelect";
import Loader from "components/Loader";
import LoadingButton from "components/LoadingButton";
import { snack } from "components/toast";
import { CLIENT_CATEGORIES } from "data/constants";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { DialogProps, ResType } from "types";
import { createClientDefaultValues, CreateClientSchema } from "validations/createCllient";
import { getClientPrefixId } from "api/services/quantum/prifix";
import { useEffect } from "react";

interface Props extends DialogProps {
  successCb?: () => void;
}

function QuantumAddClient({ open, setOpen, successCb }: Props) {
  const navigate = useNavigate();


  const { data: users, isLoading: userLoading }: ResType = useQuery("users", getUsers, {
    enabled: open,
    onError: (err) => {
      if (!err?.error) {
        snack.error(`An error occurred while fetching user-data`);
      }
    }
  });


  const { data: getClientPrefix, isLoading: PrefixIdLoading }: ResType = useQuery("getClientId",
    getClientPrefixId, {
    enabled: open,
  })
  const { mutate, isLoading } = useMutation(createClient, {
    onSuccess: (res: any) => {
      snack.success("Client Created");
      setOpen(false);
      if (successCb) {
        successCb();
        return;
      }
      navigate({
        pathname: `/quantumclients/${res.data.id}/profile/`,
        search: `?displayName=${res.data?.displayName}&clientId=${res.data?.clientId}`,
      });
    },
    onError: (err: any) => {
      if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
        snack.error(err.response.data.message);
      } else if (!err.error) {
        snack.error('An error occurred while creating Client')
      }
    },
  });

  const subCategoriesExist = (category: any) => {
    let foundCategory = CLIENT_CATEGORIES.find((item: any) => item.value === category);
    return foundCategory?.subCategories?.length;
  };

  const { watch, control, handleSubmit, reset, getValues, setValue } = useForm({
    defaultValues: createClientDefaultValues,
    mode: "onChange",
    resolver: yupResolver(CreateClientSchema({ subCategoriesExist })),
  });

  const setData = (data: any) => {
    reset({
      ...getValues(),
      ...data,
    });
  };

  let subCategories = CLIENT_CATEGORIES.find(
    (item) => item.value === watch("category")
  )?.subCategories;

  const onFormSubmit = (data: any) => {
    mutate({
      ...data,
      clientManager: data?.clientManager?.value,
      gstVerified: data?.gstRegistered === "yes" && data?.gstVerified,
      panVerified: data?.gstRegistered === "no" && data?.panVerified,
      clientPortalAccess: data?.clientPortalAccess === "yes",
    });
  };
  useEffect(() => {
    if (getClientPrefix) {
      setValue("clientId", getClientPrefix.data?.prefix);
    }
  }, [getClientPrefix, setValue]);

  return (
    <DrawerWrapper
      open={open}
      setOpen={() => {
        reset(createClientDefaultValues);
        setOpen(false);
      }}
      title="Add Client"
    >
      {userLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormSelect
            control={control}
            required
            name="category"
            label="Category"
            options={CLIENT_CATEGORIES.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
          />
          {subCategories && (
            <Box mt={2}>
              <FormSelect
                control={control}
                required
                name="subCategory"
                label="Sub Category"
                options={subCategories.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
              />
            </Box>
          )}

          <Box mt={2}>
            <FormInput inputProps={{ maxLength: 100 }} control={control} name="displayName" label="Display Name" required />
          </Box>
          <Box mt={2}>
            <FormInput inputProps={{ minLength: 6, maxLength: 15 }} control={control} name="clientId" label="Client ID" required />
          </Box>
          <Box mt={2}>
            <FormInput inputProps={{ minLength: 10, maxLength: 10, pattern: "[1-9]{1}[0-9]{9}" }} control={control} name="mobileNumber" label="Mobile Number" required />
          </Box>
          <Box mt={2}>
            <FormInput control={control} name="email" label="Email ID" required />
          </Box>
          <LoadingButton
            loading={isLoading}
            fullWidth
            sx={{ mt: 3 }}
            type="submit"
            loadingColor="white"
            title="Create Client"
            color="secondary"
          />
        </form>
      )}
    </DrawerWrapper>
  );
}

export default QuantumAddClient;