import { IconButton, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { StyledTaskBox } from 'views/dashboard/OrgDashboard/styles';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ResType } from 'types';
import { useQuery } from 'react-query';
import { getPriorityStatus } from 'api/services/quantum/home';
import { colors } from 'views/tasks/board/utils';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

function NumberOfDocuments() {
  const [priority, setPriority] = useState<any>([])
  const navigate = useNavigate();

  const { data, isLoading }: ResType = useQuery(
    ["get-priority-status", {
    }],
    getPriorityStatus,
    {
      onSuccess: (res) => {
        setPriority(res?.data?.Priority)
      },
    }
  );


  const result = [
    {
      name: "High",
      value: priority?.high,
      color: "#FB0505",
      key: "high",
    },
    {
      name: "Medium",
      value: priority?.medium,
      color: "#f17f23",
      key: "medium",
    },
    {
      name: "Low",
      value: priority?.low,
      color: "#019335",
      key: "low",
    },
    {
      name: "None",
      value: priority?.none,
      color: "#64B5F6",
      key: "none",
    },
  ];

  return (
    <StyledTaskBox width={"300px"} margin={"30px"}>
      <header>
        <Typography variant="h6">Number of Documents</Typography>
      </header>
      <main>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={result} layout="vertical" style={{ padding: 0, fontSize: "12px" }}>
            <Bar
              dataKey="value"
              barSize={8}
              radius={[0, 4, 4, 0]}
              style={{ cursor: "pointer" }}
            // onClick={(v) => handleClick(v)}
            >
              {result?.map((entry: any, index: number) => (
                <Cell key={index} fill={result[index].color} />
              ))}
            </Bar>
            <YAxis type="category" dataKey="name" />
            <Tooltip
              labelStyle={{ color: "#000", fontWeight: "bold", fontSize: 13 }}
              cursor={{ fill: "transparent" }}
            />
            <XAxis type="number" domain={[0, "dataMax + 10"]} />
          </BarChart>
        </ResponsiveContainer>
      </main>
      <footer>
        <Typography variant="body2" color="secondary">
          View Documents
        </Typography>
        {/* <IconButton color="secondary" size="small" onClick={(v) => handleClick(v)}> */}
        <IconButton color="secondary" size="small" onClick={() => navigate("/alldocuments")}>
          <ArrowForwardIcon fontSize="small" />

        </IconButton>
      </footer>
    </StyledTaskBox>
  );
}






export default NumberOfDocuments;
export { }
