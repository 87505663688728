import { Box, Button, Divider, Grid, MenuItem, Paper, TextField, TextareaAutosize, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PercentageBar from "./progressBar";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useMutation, useQuery } from "react-query";
import { ResType } from "types";
import LeftSideBar from "./leftSideBar";
import { getDriveTemplate, updateSectionData, updateDynamicData1, submitDynamicData1, UpdateMutuallyExclusiveSectionsData, updateTabularSection } from "api/services/quantum/template"
import { useNavigate, useParams } from "react-router-dom";
import { snack } from "components/toast";
import RightPanel from "../buildNav/rightPanel/rightPanel";
import useTitle from "hooks/useTitle";
import { ArrowBack } from "@mui/icons-material";
import ClientPrefillSelection from "./Prefill/ClientSelection";
import { DesktopDatePicker } from "@mui/lab";
import { handleKeyDown } from "utils/handleKeyDown";
import LoaderPage from "./LoaderPage";
import { useDemoData } from "context/DemoProfile";
import { useConfirm } from "context/ConfirmDialog";
import SelectDownloadMethod from "./downloadConfirm";
// import FooterSelection from "./FooterSelection";



function BuildDynamicAndStaticTemplate() {
  const [previousClassName, setPreviousClassName] = useState("")
  const [percentage, setPercentage] = useState(0);
  const navigate = useNavigate();
  let [stringValue, setStringValue] = useState("");
  const [isSubmitCLick, setIsSubmitCLick] = useState(false);
  const [matchedFieldCount, setMatchedFieldCount] = useState(0);
  const [templateActivity, setTemplateActivity]: any = useState([]);
  const [dynamicLoading, setDynamicLoading] = useState(false)
  useTitle(<Button onClick={() => navigate("/alldocuments")} color="primary" startIcon={<ArrowBack />}>
    {templateActivity?.templateName}
  </Button>)
  const confirm = useConfirm();

  const processId = useParams()?.id;
  const [currentField, setCurrentField] = useState<any>("");
  const [sequenceing, setSequenceing] = useState({})
  const [currentKey, setCurrentKey] = useState("");
  const sequenceKeys = Object.keys(sequenceing || {});
  const [currentMetadata, setCurrentMetadata] = useState<any>(null);
  const [currentSectionSequence, setCurrentSectionSequence] = useState<any>("")
  const [templateDocMetadata, setTemplateDocMetadata] = useState<any>("")
  const [lastKey, setLastKey] = useState("")
  const [sectionLoadingStatus, setSectionLoadingStatus] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [open, setOpen] = useState(false);

  const { data: demodata } = useDemoData();
  let isdemo: any = {};

  if (demodata && JSON.parse(demodata).hasOwnProperty('demo')) {
    isdemo = { ...JSON.parse(demodata) };
  }


  const { data, isLoading: staticLoading }: ResType = useQuery(
    ["get Template",
      processId],
    getDriveTemplate, {
    onSuccess(res: any) {
      setTemplateActivity(res?.data?.templateData?.[0]);
      setStringValue(res?.data?.templateData?.[0]?.rawdata);
      setSequenceing(res?.data?.getSectionData?.data?.templateDocMetadata?.sequencing);
      setTemplateDocMetadata(res?.data?.getSectionData?.data?.templateDocMetadata)
      const sequenceKeys = Object.keys(res?.data?.getSectionData?.data?.templateDocMetadata?.sequencing || {});
      if (sequenceKeys.length > 0) {
        const firstKey = sequenceKeys[0];
        setCurrentKey(firstKey);
      }

      // const sequenceKeys = Object.keys(res?.data?.sequencing || {});
      if (sequenceKeys.length > 0) {
        const lastKey = sequenceKeys[sequenceKeys.length - 1];
        // 'lastKey' will now hold the last key in the 'sequenceKeys' array.
        setLastKey(lastKey);
      }
    }
  }
  );
  const { mutate: updateSection } = useMutation(updateSectionData, {
    onSuccess: (data2: any) => {
      setSequenceing(data2?.data?.data?.templateDocMetadata?.sequencing);
      setTemplateDocMetadata(data2?.data?.data?.templateDocMetadata)
      setSectionLoadingStatus(false)
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  })

  const { mutate: updateDynamic } = useMutation(updateDynamicData1, {
    onSuccess: (data2: any) => {
      setStringValue(data2?.data?.qtmActivity?.rawdata);
      setSequenceing(data2?.data?.jsonData?.templateDocMetadata?.sequencing);
      setTemplateDocMetadata(data2?.data?.jsonData?.templateDocMetadata)
      setDynamicLoading(false)
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  })

  const { mutate: updateTabularSectionData } = useMutation(updateTabularSection, {
    onSuccess: (data2: any) => {
      setStringValue(data2?.data?.qtmActivity?.rawdata);
      setSequenceing(data2?.data?.jsonData?.templateDocMetadata?.sequencing);
      setTemplateDocMetadata(data2?.data?.jsonData?.templateDocMetadata)
      setDynamicLoading(false)
      const sequenceKeys = Object.keys(data2?.data?.jsonData?.templateDocMetadata?.sequencing || {});
      if (sequenceKeys.length > 0) {
        const currentIndex = sequenceKeys?.indexOf(currentKey);
        if (currentIndex < sequenceKeys?.length - 1) {
          const nextKey = sequenceKeys[currentIndex + 1];
          setCurrentKey(nextKey);
        }
      }
      setDisableButton((prev) => true)
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  })

  const { mutate: UpdateMutuallyExclusiveSections } = useMutation(UpdateMutuallyExclusiveSectionsData, {
    onSuccess: (data2: any) => {
      setStringValue(data2?.data?.qtmActivity?.rawdata);
      setSequenceing(data2?.data?.jsonData?.templateDocMetadata?.sequencing);
      setTemplateDocMetadata(data2?.data?.jsonData?.templateDocMetadata)
      setDynamicLoading(false)

      const sequenceKeys = Object.keys(data2?.data?.jsonData?.templateDocMetadata?.sequencing || {});
      if (sequenceKeys.length > 0) {
        const currentIndex = sequenceKeys?.indexOf(currentKey);
        if (currentIndex < sequenceKeys?.length - 1) {
          const nextKey = sequenceKeys[currentIndex + 1];
          setCurrentKey(nextKey);
        }
      }
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  })

  const handleSave = async () => {

    if (currentSectionSequence?.repeatOnField === true && currentSectionSequence?.tabularSectionField === true) {
      setDynamicLoading(true)
      updateTabularSectionData({
        id: processId,
        data: templateDocMetadata,
        currentField
      })
    } else if (currentSectionSequence?.repeatOnField === true) {
      setDynamicLoading(true)
      updateDynamic({
        id: processId,
        data: templateDocMetadata,
        currentField
      })
    } else if (currentSectionSequence?.mutuallyExclusiveSectionSelectorField === true) {
      setDynamicLoading(true)
      UpdateMutuallyExclusiveSections({
        id: processId,
        data: templateDocMetadata,
        currentField
      })
    }

    const currentIndex = sequenceKeys?.indexOf(currentKey);
    if (currentIndex < sequenceKeys?.length - 1) {
      const nextKey = sequenceKeys[currentIndex + 1];
      setCurrentKey(nextKey);
    }
  }


  const handleNext = async () => {
    if (currentMetadata && currentField?.fieldValue !== null) {
      sequenceing[currentKey].metadata.fieldValue = currentField?.fieldValue;
    }
    if (currentSectionSequence?.repeatOnField === false) {
      setSectionLoadingStatus(true)

      await updateSection({
        id: processId,
        data: templateDocMetadata,
        currentField,
        percentage
      })
    }

    // if (currentSectionSequence?.repeatOnField === true) {
    //     setDynamicLoading(true)
    //     await updateDynamic({
    //         id: processId,
    //         data: templateDocMetadata,
    //         currentField
    //     })
    // }
    const currentIndex = sequenceKeys?.indexOf(currentKey);
    if (currentIndex < sequenceKeys?.length - 1) {
      const nextKey = sequenceKeys[currentIndex + 1];
      setCurrentKey(nextKey);
    }
    setDisableButton((prev) => true)
    if (percentage !== 100 && currentField?.fieldName === lastKey) {
      console.log("Complete document first")
      snack.success("Complete Total document and go to download page")
    }
  };

  const handleBack = () => {
    const currentIndex = sequenceKeys?.indexOf(currentKey);
    if (currentIndex > 0) {
      const previousKey = sequenceKeys[currentIndex - 1];
      setCurrentKey(previousKey);
    }
    setDisableButton((prev) => true)
  };

  useEffect(() => {
    if (currentKey && sequenceing && sequenceing?.[currentKey]) {
      const metadata = sequenceing?.[currentKey]?.metadata;
      setCurrentSectionSequence(sequenceing[currentKey]);
      setCurrentMetadata(metadata);
      setCurrentField(metadata);
    }
  }, [currentKey, sequenceing]);

  useEffect(() => {
    let updatedStringValue = stringValue;
    if (sequenceing) {
      Object.keys(sequenceing).forEach((key) => {
        const field = sequenceing[key].metadata;
        const fieldValue = field?.fieldValue || `{ ${field?.fieldName} }`;

        const elementsToUpdate = document?.querySelectorAll(`.${field?.fieldName}`);
        if (elementsToUpdate && elementsToUpdate.length > 0) {
          elementsToUpdate?.forEach((element) => {
            element.textContent = fieldValue;
          });
        }

        // Replace occurrences of `{{fieldName}}` in updatedStringValue with the field value
        const regex = new RegExp(`{{${field?.fieldName}}}`, 'g');
        updatedStringValue = updatedStringValue?.replace(regex, fieldValue);
      });
    }
    setStringValue(updatedStringValue)

    if (currentField && currentField?.fieldName !== "") {
      const className = currentField?.fieldName;
      // setCurrentClassName(className)
      // Remove previous red background color (if any)
      if (previousClassName && previousClassName !== className) {
        const elementsWithPreviousColor = document.querySelectorAll(`.${previousClassName}`);
        elementsWithPreviousColor?.forEach((element: any) => {
          element.style.backgroundColor = ""; // Reset the background color
        });
      }          // Highlight elements with the new class name
      const regex = new RegExp(`class=['"]${className}['"]`, 'g');
      const matchedElements = document?.querySelectorAll(`[class*="${className}"]`);

      matchedElements?.forEach((element: any) => {
        element.style.backgroundColor = "#CCFFFF"; // Apply red background color
      });
      if (matchedElements?.length > 0) {
        matchedElements[0].scrollIntoView({ behavior: 'smooth' }); // You can use 'auto' instead of 'smooth' for instant scrolling
      }

      const matchArray = stringValue?.match(regex);
      setMatchedFieldCount(matchArray ? matchArray?.length : 0);
      setPreviousClassName(className);
    }

    let filledCount = 0;
    let totalCount = 0;

    // Iterate through the "sequencing" object
    for (const key in sequenceing) {
      if (sequenceing?.hasOwnProperty(key)) {
        const fieldValue = sequenceing[key]?.metadata?.fieldValue;
        // Check if the fieldValue is not null and not an empty string
        if (fieldValue !== null && fieldValue !== "") {
          filledCount++;
        }

        totalCount++;
      }
    }
    // Calculate the percentage
    const percentage = (totalCount > 0) ? (filledCount / totalCount) * 100 : 0;
    setPercentage(percentage);
  }, [sequenceing, currentField, stringValue, previousClassName])

  useEffect(() => {
    let updatedStringValue = stringValue;
    const field = currentField
    // let updatedStringValue = stringValue;
    const fieldValue = field?.fieldValue || `{ ${field?.fieldName} }`;
    const elementsToUpdate = document?.querySelectorAll(`.${field?.fieldName}`);
    if (elementsToUpdate && elementsToUpdate?.length > 0) {
      elementsToUpdate?.forEach((element) => {
        element.textContent = fieldValue;
      });
    }
    const regex = new RegExp(`{{${field?.fieldName}}}`, 'g');
    updatedStringValue = updatedStringValue?.replace(regex, fieldValue);
    setStringValue(updatedStringValue)

  }, [currentField, stringValue]);
  const handleInputChange = (value, a) => {
    if (a === "date") {
      const formattedDate = new Date(value).toLocaleDateString('en-GB');
      value = formattedDate
    }

    if (currentSectionSequence?.repeatOnField || currentSectionSequence?.mutuallyExclusiveSectionSelectorField) {
      setDisableButton((prev) => false);
    } else {
      setDisableButton((prev) => true)
    }
    setCurrentField((prevField) => {
      return { ...prevField, fieldValue: value };
    });
  };

  const { mutateAsync: submuteUpdateProcess } = useMutation(submitDynamicData1, {
    onSuccess: (res) => {
      if (isdemo?.demo && res?.data?.taskId !== null) {
        console.log("bwudwuwdb")
        navigate(`/template/download/${processId}`);
      } else if (isdemo?.demo) {
        console.log("is demo")
        navigate(`/template/demoDownload/${processId}`);
      } else if (res?.data?.documentStatus === "under_review") {
        snack.success('Submmited for Approval')
      } else if (res?.data?.documentStatus === "completed") {
        snack.success('Document Completed Successfully')
        setOpen(true)
      }

    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });

  const handleSubmit = async () => {
    setIsSubmitCLick(true)
    let approvalStatus = templateActivity?.approvalStatus
    if ((approvalStatus === "starting") && percentage === 100) {
      approvalStatus = "processing"
    } else if (approvalStatus === "false" && percentage === 100) {
      approvalStatus = "processing"
    }

    if (currentMetadata && currentField?.fieldValue !== null) {
      sequenceing[currentKey].metadata.fieldValue = currentField?.fieldValue;
    }

    if (currentSectionSequence?.repeatOnField === false) {
      await submuteUpdateProcess({
        id: processId,
        data: templateDocMetadata,
        currentField,
        percentage,
        approvalStatus
      });

    }

  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter' && disableButton) {
      handleNext();
    }
  };


  const handleMenuItemClick = ({ pageIndex, fieldName }) => {
    const nextKey = sequenceKeys[pageIndex];
    setCurrentKey(nextKey);

  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click context menu
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = (selectedValue) => {
    setIsDrawerOpen(false);
    if (selectedValue) {

      setCurrentField((prevField) => ({
        ...prevField,
        fieldValue: selectedValue,
      }));
    }
  };

  function formatDate(inputDate) {
    if (inputDate !== "" && inputDate !== null) {
      const parts = inputDate.split('/');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      return formattedDate;
    }

  }

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [forFocus, setForFocus] = useState(true);

  const handleSelect = () => {
    setIsDatePickerOpen(false);
    setForFocus(false);
  };

  const handleFocus = () => {
    if (forFocus)
      setIsDatePickerOpen(true);
    setForFocus(true);
  }


  if (staticLoading) return <LoaderPage />;
  if (dynamicLoading) return <LoaderPage />;
  // if (sectionLoadingStatus) return <Loader />
  return (
    <Grid display={"flex"} flexDirection={"row"} height={"75vh"} justifyContent={"center"} >
      <Grid>
        <LeftSideBar onMenuItemClick={handleMenuItemClick} sequenceing={sequenceing} />
      </Grid>


      <SelectDownloadMethod
        open={open}
        setOpen={setOpen}
        processId={processId} />


      <Grid width={"40%"} height={"87vh"} mt={4} p={"2"} m={"1"} ml={12}>
        <Paper variant="elevation"
          elevation={8}
          sx={{
            borderRadius: "10px",
            marginLeft: "10px",
            width: "95%",
            height: "75vh",
            backgroundColor: "white",
          }}
        >
          <Box p={3}>
            <Box mt={2}>
              <Typography variant="h5">{currentField?.question}</Typography>
            </Box>
            <Box mt={3}>
              <Box display={"flex"} alignItems={"center"} justifyContent='space-between' >
                <Box width={"70%"}>
                  <Box display={"flex"} alignItems={"center"} >

                    {(currentField?.type === "text_box" || currentField?.type === "textBox") && (
                      <TextField
                        required
                        // label="Enter answer"
                        onKeyUp={handleEnterKey} // Add event listener for 'Enter' key 
                        variant="outlined"
                        value={currentField.fieldValue || ""}
                        onChange={(event) => handleInputChange(event.target.value, "textBox")}
                        placeholder={currentField?.placeHolder || "Enter Value"}
                        style={{ width: "100%" }}
                      />
                    )}
                    {(currentField?.type === "text_area" || currentField?.type === "textArea") && (
                      <TextareaAutosize
                        required
                        minRows={3}  // Set the minimum number of rows (initial height)
                        maxRows={5}  // Set the maximum number of rows (maximum height)
                        onKeyUp={handleEnterKey} // Add event listener for 'Enter' key 
                        value={currentField.fieldValue || ""}
                        onChange={(event) => handleInputChange(event.target.value, "textArea")}
                        placeholder={currentField?.placeHolder || "Enter Value"}
                        style={{
                          minWidth: "100%",
                          maxWidth: "100%", // Specify the width in pixels
                          minHeight: "100px", // Specify the minimum height in pixels
                          maxHeight: "100px"
                        }}
                      />
                    )}
                    {currentField?.type === "date" && (
                      <DesktopDatePicker
                        value={formatDate(currentField.fieldValue) || ""}
                        onChange={(v) => {
                          handleInputChange(v, "date");
                          handleSelect();
                        }}
                        open={isDatePickerOpen}
                        renderInput={(params) => <TextField
                          onKeyUp={handleEnterKey}
                          required
                          fullWidth
                          size="medium"
                          {...params}
                          onKeyDown={handleKeyDown}
                          onClick={() => { setIsDatePickerOpen(true); }}
                          onFocus={handleFocus}
                        />}
                      />
                    )}
                    {(currentField?.type === "number" || currentField?.type === "number") && (
                      <TextField
                        type="number"
                        required
                        variant="outlined"
                        onKeyUp={handleEnterKey} // Add event listener for 'Enter' key 
                        value={currentField.fieldValue || ""}
                        onChange={(event) => handleInputChange(event.target.value, "number")}
                        placeholder={currentField?.placeHolder || "Enter Value"}
                        sx={{ width: "100%" }}
                      />
                    )}
                    {(currentField?.type === "dropdown" || currentField?.type === "dropdown") && (
                      <TextField
                        sx={{ minWidth: 120 }}
                        select
                        value={currentField.fieldValue || ""}
                        onKeyUp={handleEnterKey} // Add event listener for 'Enter' key 
                        onChange={(e) => handleInputChange(e.target.value, "dropdown")}
                        variant="outlined"
                        label={currentField?.question || "select"}
                        style={{ width: "100%" }}
                      >
                        {currentField?.options?.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {(currentField?.type === "radio") && (
                      <div>

                        {/* <p>{currentField?.question || "Select an option:"}</p>
                                                {currentField?.options?.map((option, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={option}
                                                        control={<Radio />}
                                                        label={option}
                                                        onChange={() => handleInputChange(option)}
                                                    />
                                                ))} */}
                        {currentField?.options?.map((option, index) => (
                          <div key={index}>
                            <input
                              style={{ height: "10px" }}
                              type="radio"
                              onKeyUp={handleEnterKey}
                              id={`radio-${index}`}
                              name="radio-group" // Set a common name for the radio buttons to group them
                              value={option}
                              checked={currentField.fieldValue === option}
                              onChange={(e) => handleInputChange(e.target.value, 'radio')}
                            />
                            <label htmlFor={`radio-${index}`}>{option}</label>
                          </div>
                        ))}
                      </div>

                    )}


                  </Box>
                </Box>
                <Box width={"30%"} display={"flex"} justifyContent={'flex-end'} >
                  <Button variant="outlined" style={{ marginLeft: "5px", color: "#ADADAD", borderColor: "#ADADAD", height: "50px" }} onClick={handleOpenDrawer} ><AutoFixHighIcon /></Button>
                  {/* <Button variant="outlined" style={{ marginLeft: "5px", color: "#ADADAD", borderColor: "#ADADAD", height: "50px" }} ><PersonAddIcon /></Button> */}
                </Box>

                <ClientPrefillSelection
                  isOpen={isDrawerOpen}
                  onClose={handleCloseDrawer}
                />
              </Box>
              <Box pt={2} display={"flex"} flexDirection={"row"} justifyContent={"end"} >
                <Button variant="outlined" style={{ borderColor: "#E44652", marginRight: "10px" }} onClick={handleBack}>
                  Back
                </Button>

                {((currentSectionSequence?.repeatOnField === true) || (currentSectionSequence?.mutuallyExclusiveSectionSelectorField === true)) && (
                  <Button variant="contained"
                    disabled={disableButton}
                    color="error"
                    onClick={handleSave}>
                    Save
                  </Button>
                )}

                {/* 
                                {percentage === 100 && lastKey !== "" && currentField.fieldName === lastKey && (
                                    <Button variant="outlined"
                                        disabled={isSubmitCLick}
                                        style={{
                                            backgroundColor: '#E44652',
                                            color: "#ffffff"

                                        }} onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                )} */}
                {(percentage === 100 && currentField?.fieldName === lastKey) ? (
                  <>
                    <Button variant="outlined"
                      disabled={isSubmitCLick}
                      style={{
                        backgroundColor: '#E44652',
                        color: "#ffffff"

                      }} onClick={handleSubmit}>
                      Submit
                    </Button>


                  </>
                ) : (
                  <Button
                    disabled={!disableButton}
                    style={{
                      backgroundColor: '#E44652',
                      color: "#ffffff",
                      marginLeft: "5px"
                    }} onClick={handleNext}>
                    Next
                  </Button>
                )}
              </Box>
            </Box>

          </Box>
          <Box mb={1} minHeight={"150px"} minWidth={"80%"} borderRadius={"8px"} m={2} mt={5} border={"1px solid #182F53"} p={2} bgcolor={"#E2EFF7"}>
            {currentField?.tips || ""}
          </Box>
        </Paper>
      </Grid>

      <Grid width={"60%"} height={"100%"} mt={'0'} >
        <Box display={"flex"} justifyContent={"space-between"} width={"90%"} mt={'0'}>
          <Typography></Typography>
          <Typography style={{ backgroundColor: "#FFC107", fontSize: '10px', padding: '4px', borderRadius: "2px" }}>{matchedFieldCount} changes applied in the Document</Typography>
          <Typography color={"#E44652"}>{Math.trunc(percentage)}%</Typography>
        </Box>
        <>
          <Paper variant="elevation"
            elevation={4}
            sx={{
              width: "90%",
              borderRadius: "10px",
              height: "73vh",
              backgroundColor: "white",
            }}
          >
            <PercentageBar dataObject={percentage.toFixed(2)} />
            <Typography></Typography>
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundImage: `url(${templateDocMetadata?.header || "none"})`,
              height: "55px"
              // backgroundImage: templateDocMetadata?.header || "none",

            }}>
              {/* <img height={"55px"} width={"80%"} src={templateDocMetadata?.header || "none"} alt="" /> */}
              {/* <Box p={2}>ADD LOGO</Box> */}
              {/* <FooterSelection data={templateDocMetadata} processInstanceId={processId} /> */}
              {/* <Box p={2}
                                color="#707070"

                            >

                                <Typography fontSize={"6px"}>Your company name</Typography>
                                <Typography fontSize={"6px"}>Your company name</Typography>
                                <Typography fontSize={"6px"}>Your company name</Typography>
                            </Box> */}
            </Box>
            <Divider />

            <Box p={3}
              onContextMenu={handleContextMenu}
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "80%",
                userSelect: "none",
                maxWidth: "800px", // Set your desired maximum width
                wordWrap: "break-word",
                // whiteSpace: "pre-wrap", // Prevent text from wrapping
              }}
            >
              {/* Use the scrollable div for the generated HTML content */}
              <div dangerouslySetInnerHTML={{ __html: stringValue }} />
            </Box>
          </Paper>
        </>
      </Grid>

      <Grid>
        {!isdemo?.demo && (
          <RightPanel />
        )}

      </Grid>
    </Grid >
  );
}

export default BuildDynamicAndStaticTemplate;