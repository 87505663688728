import React, { useState } from "react";
import { Button, TextField, Box, Select, MenuItem, Paper, IconButton, InputLabel } from "@mui/material";
import DialogWrapper from "components/DialogWrapper";
import { useMutation, useQueryClient } from "react-query";
import { snack } from "components/toast";
import { sendPrefillSection } from "api/services/quantum/prefill";
import { Close } from "@mui/icons-material";

const PrefillSectionCreation = (Props) => {
    const { open, setOpen, clientId } = Props;
    const queryClient = useQueryClient();
    const [sections, setSections] = useState([
        { title: "", subjects: [{ value: "", inputType: "text", fieldValue: "", subjectName: "" }] },
    ]);
    const { mutate } = useMutation(sendPrefillSection, {
        onSuccess: (res) => {
            snack.success("Section Created Successfully");
            queryClient.invalidateQueries("get-client-prefill")
            setOpen(false)
            setSections([
                { title: "", subjects: [{ value: "", inputType: "text", fieldValue: "", subjectName: "" }] },
            ])
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });

    const addSection = () => {
        // Create a new section with an empty title and one subject
        const newSection = { title: "", subjects: [{ value: "", inputType: "text", fieldValue: "", subjectName: "" }] };
        setSections([...sections, newSection]);
    };

    const addSubject = (sectionIndex) => {
        setSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections[sectionIndex].subjects.push({ value: "", inputType: "text", fieldValue: "", subjectName: "" });
            return updatedSections;
        });
    };

    const removeSubject = (sectionIndex, subjectIndex) => {
        setSections((prevSections) => {
            const updatedSections = [...prevSections];

            // Check if there is only one subject in the section
            if (updatedSections[sectionIndex].subjects.length === 1) {
                snack.error("At least one Subject is required in each Section");
                return updatedSections;
            }

            updatedSections[sectionIndex].subjects.splice(subjectIndex, 1);
            return updatedSections;
        });
    };


    const removeSection = (sectionIndex) => {
        if (sections.length === 1) {
            snack.error('Should contain At least one Section')
            return; // Ensure at least one section remains
        }
        setSections((prevSections) => {
            const updatedSections = [...prevSections];
            updatedSections.splice(sectionIndex, 1);
            return updatedSections;
        });
    };


    const handleSave = () => {
        // Validate sections
        const isValid = sections.every((section) => {
            // Check if the section title is not empty
            if (section.title.trim() === "") {
                snack.error("Section title cannot be empty");
                return false;
            }

            // Check if at least one subject exists in the section
            if (
                section.subjects.length === 0 ||
                section.subjects.some(subject => subject.subjectName.trim() === "")
            ) {
                snack.error("Subjects should not be empty");
                return false;
            }

            return true;
        });

        if (isValid && clientId) {
            // Perform the save operation only if all sections are valid
            mutate({
                id: clientId,
                data: sections,
            });
        }
        // Perform any additional actions, such as saving data to your backend
    };



    return (
        <DialogWrapper
            open={open}
            setOpen={setOpen}
            title={"Add Custom Prefill"}
        >
            <Box height={"450px"} >
                <Box bgcolor="#ffffff" height={"400px"} overflow={"auto"} >
                    {sections.map((section, sectionIndex) => (
                        <Paper elevation={3} key={sectionIndex} sx={{ borderRadius: 3, margin: "10px", marginBottom: "10px", padding: 2 }}>
                            <Box mb={2}>

                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <TextField
                                        type="text"
                                        size="small"
                                        placeholder="Add Title"
                                        value={section.title}
                                        onChange={(e) => {
                                            setSections((prevSections) => {
                                                const updatedSections = [...prevSections];
                                                updatedSections[sectionIndex].title = e.target.value;
                                                return updatedSections;
                                            });
                                        }}
                                    />

                                    <IconButton onClick={() => removeSection(sectionIndex)} sx={{ paddingTop: "0px" }}>
                                        <Close color="primary" />
                                    </IconButton>
                                    {/* <Button
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        onClick={() => removeSection(sectionIndex)}
                                    >Remove section </Button> */}
                                </Box>

                                <Box   >
                                    {section.subjects.map((subject, subjectIndex) => (
                                        <Box key={subjectIndex} display={"flex"} alignItems={"center"} justifyContent={'space-between'}>
                                            <Box display="flex" alignItems="center">
                                                <TextField
                                                    type="text"
                                                    size="small"
                                                    placeholder="Add Field Name"
                                                    value={subject.subjectName}
                                                    onChange={(e) => {
                                                        setSections((prevSections) => {
                                                            const updatedSections = [...prevSections];
                                                            updatedSections[sectionIndex].subjects[subjectIndex].subjectName = e.target.value;
                                                            return updatedSections;
                                                        });
                                                    }}
                                                    style={{ margin: "10px", padding: "5px" }}
                                                />

                                                <InputLabel htmlFor="field-type-select">Field Type</InputLabel>
                                                <Select
                                                    size="small"
                                                    value={subject.inputType}
                                                    onChange={(e) => {
                                                        setSections((prevSections) => {
                                                            const updatedSections = [...prevSections];
                                                            updatedSections[sectionIndex].subjects[subjectIndex].inputType = e.target.value;
                                                            return updatedSections;
                                                        });
                                                    }}
                                                    style={{ margin: "10px" }}
                                                    inputProps={{
                                                        id: 'field-type-select',
                                                    }}
                                                >
                                                    <MenuItem value="text">Text</MenuItem>
                                                    <MenuItem value="number">Number</MenuItem>
                                                    <MenuItem value="date">Date</MenuItem>
                                                </Select>
                                            </Box>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => removeSubject(sectionIndex, subjectIndex)}
                                                size="small"
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    ))}
                                </Box>



                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => addSubject(sectionIndex)}
                                    sx={{ mb: "10px", mt: "5px" }}
                                >
                                    Add Field
                                </Button>
                            </Box>
                        </Paper>


                    ))}
                </Box>
                <Box >

                    <Box display="flex" justifyContent="flex-end" width={"90%"} style={{ position: "absolute", bottom: "0", margin: "5px" }}>
                        <Box p={2} display="flex" justifyContent="space-between">
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={addSection}
                                size="small"
                            >
                                Add More
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleSave}
                                sx={{ ml: "10px" }}
                                size="small"
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </DialogWrapper>
    );
};

export default PrefillSectionCreation;

