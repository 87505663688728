import { Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { updateActivityData } from "api/services/quantum/template";
import { snack } from "components/toast";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { GreyButton } from "views/tasks/styles";

function BottomBar({ data, state, setState, processId }) {
    const queryClient = useQueryClient();
    const [isStateChanged, setIsStateChanged] = useState(false);

    useEffect(() => {
        setIsStateChanged(JSON.stringify(data) !== JSON.stringify(state));
    }, [state, data]);



    const { mutate } = useMutation(updateActivityData, {
        onSuccess: (data2: any) => {
            snack.success("Document details update successfully")
            queryClient.invalidateQueries("Docdetails");
            queryClient.invalidateQueries("get Template");
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });
    const onSubmit = () => {
        if (state?.templateName.length < 3 || state?.templateName.length >= 100) {
            return snack.error("Template Name should be 3-100 characters.");
        }
        mutate({
            id: processId,
            data: state,
        });
    };

    return (
        <Box
            m={2}
            // elevation={3}
            sx={{
                position: "fixed",
                bottom: isStateChanged ? 0 : "-100%",
                // width: "100%",
                // zIndex: "100",
                // transition: "0.8s",
                // left: 0,
            }}
        >
            <Box p={2} display="flex" justifyContent="flex-end" gap={2}>
                <Button onClick={onSubmit} size="large" color="secondary" variant="contained">
                    Update
                </Button>
                <GreyButton
                    onClick={() => setState(data)}
                    size="large"
                    color="secondary"
                    variant="contained"
                >
                    Cancel
                </GreyButton>
            </Box>
        </Box>
    );
}

export default BottomBar;
