import loadable from "@loadable/component";
import DeletedUsers from "pages/settings/deleted-users";
import QuantumClients from "quantumpages/clients";
import Attachments from "quantumpages/clients/Attachments/Attachments";
import Prefill from "quantumpages/clients/Prefill";
import QuantumClientLayout from "quantumpages/clients/QuantumClientLayout";
import QuantumClientProfile from "quantumpages/clients/QuantumClientProfile";
import DashboardActivitylog from "quantumpages/dashboard/dashboard-activitylog";
import DashboardAgreementExpiry from "quantumpages/dashboard/dashboard-agreementexpiry";
import DashboardAnalytics from "quantumpages/dashboard/dashboard-analytics";
import Dashboarddocuments from "quantumpages/dashboard/dashboard-documents";
import DashboardLayout from "quantumpages/dashboard/dashboard-layout";
import DeletedDocument from "quantumpages/settings/Archives/DocumentDelete";
import QtmUserProfile from "quantumpages/settings/my-profile/qtm-user-profile";
import BuildDynamicAndStaticTemplate from "quantumpages/templates/template-page/BuildDynamicAndStaticTemplate";
import DownloadPage from "quantumpages/templates/template-page/downloadPage";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import ViewDocuments from "quantumpages/dashboard/dashboard-documents/view-documents";
import DeletedClients from "quantumpages/settings/Archives/DeletedClients";
import CompletedDocuments from "quantumpages/settings/Archives/CompletedDocuments";
import Configure from "quantumpages/signup/Configure";
import Signdashboard from "quantumpages/uploadandsign/signdashboard";
import SignPage from "quantumpages/templates/template-page/signPage";
import SignOthers from "quantumpages/templates/othersSign";
import { useDemoData } from "context/DemoProfile";
import DemoErrorPage from "quantumpages/templates/DemoErrorPage";
import SignDownloadPage from "quantumpages/templates/template-page/e-signDownloadPage";


// import AddApprovelProsess from "quantumpages/settings/approvalProcess/approvalProcess";
const Layout = loadable(() => import("layout/primarylayout"));


const Join = loadable(() => import("quantumpages/signup/QtmJoin"));

// quantum login
const Demo = loadable(() => import("quantumpages/signup/qtmDemo"));
const Login = loadable(() => import("quantumpages/signup/qtmLogIn"));
const Signup = loadable(() => import("quantumpages/signup/signup"));
const ResetPassword = loadable(() => import("quantumpages/signup/ResetPassword"));
// Documents
const Documents = loadable(() => import("quantumpages/documents"));
const SignForOthers = loadable(() => import("quantumpages/templates/signForOthers"))

// Templates
const Templates = loadable(() => import("quantumpages/templates"));

const TemplatePage = loadable(() => import("quantumpages/templates/layout"));
const BusssinessCardData = loadable(() => import("quantumpages/templates/BussinessCardData"));
const TeamCardList = loadable(() => import("quantumpages/settings/settingsMenuItems/teams.list"));
const Notice = loadable(() => import("quantumpages/templates/notice"));
const PreviewTempateData = loadable(
  () => import("quantumpages/templates/template-page/preview-page")
);
const ActivityLog = loadable(() => import("quantumpages/templates/template-page/activity-log"));
const TemplateBuildPreview = loadable(() => import("quantumpages/templates/template-page"));
const FormBuildQuantum = loadable(() => import("quantumpages/templates/template-page/formBuild"));
const AddApprovelProsess = loadable(
  () => import("quantumpages/settings/approvalProcess/approvalProcess")
);
const DemoDownload = loadable(() => import("quantumpages/templates/template-page/demoDownload"))

// Settings
const Settings = loadable(() => import("quantumpages/settings"));

const OrganizationProfile = loadable(() => import("quantumpages/settings/organization.profile"));
const RolesAndPermissions = loadable(() => import("quantumpages/settings/rolesAndPermisions/role"));
// const ApprovalProcess = loadable(() => import("quantumpages/settings/settingsMenuItems/approval.process"))
const LevelOfHierarchy = loadable(
  () => import("quantumpages/settings/settingsMenuItems/level.of.hierarchy")
);

const InviteUsers = loadable(() => import("pages/settings/manage-users/invited-users"));
const Members = loadable(() => import("quantumpages/settings/settingsMenuItems/member"));
const Teams = loadable(() => import("quantumpages/settings/settingsMenuItems/teams"));
const MembersList = loadable(() => import("quantumpages/settings/settingsMenuItems/memberData"));
const SingleMember = loadable(() => import("quantumpages/settings/settingsMenuItems/singleUser"));
const UserProfile = loadable(() => import("quantumpages/settings/my-profile"));
const RolesAndPermissions1 = loadable(
  () => import("quantumpages/settings/rolesAndPermisions/rolesAndPermissions")
);
const AddLevelOfHierarchy = loadable(
  () => import("quantumpages/settings/levelOfHierarchy/leveOfHierarchy")
);
const NotificationActionsUI = loadable(() => import("quantumpages/uploadandsign/sample"))
const NotificationPreferences = loadable(() => import("quantumpages/settings/Preferences/notification-preferences"))
const NotificationUsers = loadable(() => import("quantumpages/settings/Preferences/notification-user-selection"))
const NotificationsSingleUser = loadable(() => import("quantumpages/settings/Preferences/notification-preferences-setup"))
// import NotificationActionsUI from "quantumpages/uploadandsign/sample";

// Uploadandsign
const Uploadandsign = loadable(() => import("quantumpages/uploadandsign"));

//APPROVAL PROCESS

const LevelsHierarchy = loadable(() => import("pages/settings/manage-users/levels-hirerarchy"));
const AddLevel = loadable(() => import("pages/settings/manage-users/levels-hirerarchy/add-level"));
const ViewLevel = loadable(
  () => import("pages/settings/manage-users/levels-hirerarchy/view-levels")
);

const ApprovalProcess = loadable(() => import("pages/settings/approval-process"));
const ApprovalProcedue = loadable(
  () => import("pages/settings/approval-process/approval-procedure")
);
const ApprovalProcedueView = loadable(
  () => import("pages/settings/approval-process/approval-procedure-view")
);

function QuantumRoutesContainer() {
  const { data: demodata } = useDemoData();
  let isdemo: any = {};

  if (demodata && JSON.parse(demodata).hasOwnProperty('demo')) {
    isdemo = { ...JSON.parse(demodata) };
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout menu="quantum" />}>
          {isdemo?.demo ? <Route index element={<Login />} /> : <Route index element={<Navigate to="dashboard/dashboard-analytics" />} />}
          <Route index element={<Navigate to="dashboard/dashboard-analytics" />} />
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route path="dashboard-analytics" element={<DashboardAnalytics />} />
            <Route path="dashboard-documents" element={<Dashboarddocuments />} />
            <Route path="view-docs" element={<ViewDocuments />} />
            <Route path="dashboard-agreementsexpiry" element={<DashboardAgreementExpiry />} />
            <Route path="dashboard-activitylog" element={<DashboardActivitylog />} />
          </Route>
          <Route path="alldocuments">
            <Route index element={<Documents />} />
          </Route>
          <Route path="Signdashboard/:id">
            <Route index element={<Signdashboard />} />
            <Route path="signyourself/:id" element={<Uploadandsign />} />
            <Route path="signyourself" element={<Uploadandsign />} />

          </Route>

          <Route path="quantumclients">
            <Route index element={<QuantumClients />} />
          </Route>
          <Route path="errorPage" element={<DemoErrorPage />} />
          <Route path="templates" element={<Templates />}>
            <Route index element={<BusssinessCardData />} />
            <Route path=":id" element={<BusssinessCardData />} />
          </Route>
          <Route path="template">
            <Route index element={<Notice />} />
            <Route path=":id" element={<TemplateBuildPreview />}>
              {/* <Route path="build" element={<FormBuildQuantum />} /> */}
              <Route path="build" element={<BuildDynamicAndStaticTemplate />} />

              <Route path="preview" element={<PreviewTempateData />} />
              <Route path="sign" element={<SignPage />} />
              <Route path="signForOthers" element={<SignOthers />} />
              <Route path="activity" element={<ActivityLog />} />
            </Route>
            <Route path="download/:id" element={<DownloadPage />} />
            <Route path="demoDownload/:id" element={<DemoDownload />} />

          </Route>
          <Route path="/signdownload/:id" element ={<SignDownloadPage/>}/>
          <Route path="quantumclients">
            <Route index element={<QuantumClients />} />
            <Route path=":clientId" element={<QuantumClientLayout />}>
              <Route path="prefill" element={<Prefill />} />

              <Route
                path="attachments"
                element={
                  // <PageWithPermission name={Permissions.VIEW_CLIENT_ATTACHMENTS}>
                  <Attachments />
                  // </PageWithPermission>
                }
              />

              <Route
                path="profile"
                element={
                  // <PageWithPermission name={Permissions.VIEW_CLIENT_PROFILE}>
                  <QuantumClientProfile />
                  // </PageWithPermission>
                }
              />
            </Route>
          </Route>
          <Route path="settings" element={<Settings />}>
            <Route index element={<UserProfile />} />
            <Route path="delete-documents" element={<DeletedDocument />} />
            <Route path="deleted-members" element={<DeletedUsers />} />
            <Route path="deleted-clients" element={<DeletedClients />} />
            <Route path="completed-documents" element={<CompletedDocuments />} />

            {/* <Route index element={< OrganizationProfile />} /> */}
            <Route path="profile" element={<QtmUserProfile />} />
            <Route path="organization-profile" element={<OrganizationProfile />} />
            {/* <Route path="preferences" element={<NotificationPreferences />} /> */}
            <Route path="preferences" element={<NotificationUsers />} />
            <Route path="preferences/:id" element={<NotificationsSingleUser />} />

            <Route path="roles-permissions" element={<RolesAndPermissions />} />
            <Route path="roles-permission-list" element={<RolesAndPermissions1 />} />
            <Route path="approval" element={<ApprovalProcess />} />
            <Route path="approval-process">
              <Route index element={<ApprovalProcess />} />
              <Route path="approvel-procedure" element={<ApprovalProcedue />} />
              <Route path="approvel-procedure-view" element={<ApprovalProcedueView />} />
            </Route>
            <Route path="levels-hierarchy">
              <Route index element={<LevelsHierarchy />} />
              <Route path="add-level" element={<AddLevel />} />
              <Route path="view-levels" element={<ViewLevel />} />
            </Route>
            <Route path="approvel-process-list" element={<AddApprovelProsess />} />
            <Route path="level-Of-hierarchy" element={<LevelOfHierarchy />} />
            <Route path="level-of-hierarchy-list" element={<AddLevelOfHierarchy />} />

            <Route path="members">
              <Route index element={<Members />} />
              <Route path=":userId" element={<SingleMember />} />
            </Route>
            <Route path="members-list" element={<MembersList />} />
            <Route path="invited-members" element={<InviteUsers />} />

            <Route path="teams" element={<Teams />} />
            <Route path="teams-list" element={<TeamCardList />} />
          </Route>
        </Route>
        <Route path="/join" element={<Join />} />
        <Route path="/login" element={<Login />} />
        <Route path="/documentSign/:id" element={<SignForOthers />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/configure" element={<Configure />} />

        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/demo/:tid/:did" element={<Demo />} />
        <Route path="/demo/:tid/:did/async" element={<Demo />} />
      </Routes>
    </Router>
  );
}

export default QuantumRoutesContainer;
