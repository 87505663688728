import { Box, Typography } from '@mui/material'
import React from 'react'

function DashboardActivitylog() {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Typography variant='h6'>Coming soon...</Typography>
    </Box>

  )
}

export default DashboardActivitylog