import { createContext, useContext } from "react";

export const DemoProfileContext = createContext<any>(null);

function DemoProfileProvider({ children }) {

  const params = (window.location.href)?.split('demo/')[1]?.split('/');

  const verify = () => {
    let demodata: any = {
      demo: false,
      params: {}
    };
    
    let isdemo = (window.location.href).includes('demo');
    if(isdemo) {
      demodata['params'] = params ? {tid: params[0], did: params[1]} : {};
      demodata['demo'] = localStorage.getItem("demo");
      localStorage.setItem("demo", JSON.stringify(demodata));
    } else {
      const cahcklocalstr: any = localStorage.getItem("demo");
      if(cahcklocalstr !== null && JSON.parse(cahcklocalstr) && JSON.parse(cahcklocalstr)['demo']) {
        return cahcklocalstr;
      }
    }    
    return JSON.stringify(demodata);
  }

  
  return (
    <DemoProfileContext.Provider value={{ data: verify() }}>
      {children}
    </DemoProfileContext.Provider>
  );
}

export const useDemoData = () => useContext(DemoProfileContext);

export default DemoProfileProvider;
