import { http } from "api/http";

const DownloadEmail = (data) => {
  return http.post("download/doc", data);
};

const getDocumentIdToCamunda = (data) => {
  return http.post(`download/word-doc-id`, data);
};

const downloadWithOrgHeaderAndFooter = (data) => {
  return http.post(`download/downloadwithorgheaderfooter`, data);
};

const getSignForOthersData = ({ queryKey }: any) => {
  console.log("gvygyyg", queryKey, queryKey[1]);
  return http.get(`download/signForOthers/${queryKey[1]}`);
};

const getSignDocumentData=(data)=>{
  return http.get(`download/signForOthers/${data?.processInstanceId}`);
}



const sendSignDocumentForEmail = (data) => {
  return http.post(`download/sendSignEmail`, data);
};

export { DownloadEmail, getDocumentIdToCamunda, getSignForOthersData, sendSignDocumentForEmail, getSignDocumentData,downloadWithOrgHeaderAndFooter };
