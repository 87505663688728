import { APPS } from "./constants";

export const getApp = () => {
  // console.log('Host::', window.location.hostname);
  const subdomain = getSubdomain(window.location.hostname);

  const main = APPS.find((app: any) => app.main);
  if (!main) throw new Error("Must have main app");

  if (subdomain == "") return main.app;

  const app = APPS.find((app: any) => subdomain == app.subdomain);

  if (!app) return main.app;

  //   console.log("App::", app.app);
  return app.app;
};

const getSubdomain = (location: string) => {
  const locationparts = location.split(".");

  let sliceTill = -2;

  // for localhost
  const islocalhost = locationparts.slice(-1)[0] === "localhost";
  if (islocalhost) sliceTill = -1;

  return locationparts.slice(0, sliceTill).join("");
};
