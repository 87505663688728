import React, { useState } from 'react'
import { getExpiryData } from 'api/services/quantum/home'
import { ResType } from 'types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Box, Button, Typography } from '@mui/material';
import Table from 'components/Table';
import { formattedDate } from 'utils/formattedDate';
import SearchContainer from 'components/SearchContainer';
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FloatingButton from 'components/FloatingButton';
import { useConfirm } from 'context/ConfirmDialog';
import { deleteDocument } from 'api/services/quantum/template';
import { snack } from 'components/toast';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';



function DashboardAgreementExpiry() {
  const [expairyDocs, setExpairyDocs] = useState<any>([])
  const [search, setSearch] = useState("")
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const navigate = useNavigate()

  const { data, isLoading }: ResType = useQuery(

    ["get-Expiry-date-data", {
      search, limit: pageCount,
      offset: page * pageCount,
    }],
    getExpiryData,
    {
      onSuccess: (res) => {
        setExpairyDocs(res?.data?.result)

      },
    }
  );
  const onClick = () => {

  }

  const { mutate } = useMutation(deleteDocument, {
    onSuccess: (res: any) => {
      snack.success("Document Deleted successfully");
      queryClient.invalidateQueries('get-Expiry-date-data')
      // navigate(`/templates`)
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  })

  const handledelete = (row) => {
    confirm({
      msg: "Are you sure you want to Delete this Document?",
      action: () => mutate(row?.processInstanceId),
    });
  }

  let columns = [
    {
      key: "qtmActivity.templateName",
      title: "Document Name",
      // render: (row: any) => getTitle(row?.category),
    },
    {
      key: 'qtmActivity.createdAt',
      title: "Created At",
      render: (row: any) => formattedDate(row?.qtmActivity?.createdAt),
    },
    {
      key: 'id',
      title: "Expiry Date",
      render: (row: any) => {
        let contractDate = row?.contractDate
        const currentDate = new Date();

        if (typeof contractDate === 'string') {
          contractDate = new Date(contractDate);
        }

        if (isNaN(contractDate.getTime())) {
          // Invalid date, handle the error as needed
          return 'Invalid contract date';
        }

        const timeDifference = contractDate.getTime() - currentDate.getTime();
        const millisecondsInADay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day
        const daysRemaining = Math.floor(timeDifference / millisecondsInADay);;

        if (daysRemaining > 0) {
          return `${daysRemaining} days left`;
        } else {
          // Format the contract date as needed, e.g., to a string in a specific date format
          const formattedContractDate = contractDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          return <Typography variant="body2" color='error'>{formattedContractDate}</Typography>;
        }
      },
    },
    {
      key: 'id',
      title: "",
      render: (row: any) => {
        return <Button onClick={() => handledelete(row)} style={{ color: '#a6a4a4' }} ><DeleteOutlineIcon /></Button>
      },
    },
  ];


  const totalCount = data?.data?.count;
  return (
    <Box width={"95%"} px={3}>
      <Box display="flex" flex={1} gap={2} alignItems="center" margin={"10px"}>
        <SearchContainer
          debounced
          minWidth="400px"
          onChange={setSearch}
          placeHolder="Search"
        />
        {/* <Button
          startIcon={<FilterAltOutlinedIcon />}
          color="primary"
          sx={{ border: "1px solid lightgrey", borderRadius: "4px" }}
        >
          Filters
        </Button> */}

      </Box>
      <Box sx={{ mt: 1, height: "50vh" }}>
        <Table
          sx={{ mt: 3, height: "400px" }}
          loading={isLoading}
          // onRowClick={handleRowClick}
          data={expairyDocs || []}
          columns={columns}
          // selection={{ selected, setSelected }}
          pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
        />


      </Box>
      <FloatingButton
        onClick={() => navigate("/templates")}
      />
    </Box>
  )

}



export default DashboardAgreementExpiry