import { Box, Grid, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import _ from "lodash";
import { Timeline } from "@mui/lab";
import ApprovalLevel from "./ApprovalLevel";
import { useUserData } from "context/UserProfile";
import { usePermissions } from "context/PermissionsProvider";
import { ResType } from "types";
import { useQuery } from "react-query";
import { getApprovals } from "api/services/approval-heirarchy";
import { useParams } from "react-router-dom";
import { getDocumentApprovals, getDocumentStatus } from "api/services/quantum/approvals";
import Loader from "components/Loader";
import moment from "moment";

function Approval() {
  const { data: user } = useUserData();
  const { role } = usePermissions();
  const params = useParams();
  const { id: processInstanceId } = params;

  const { data, isLoading }: ResType = useQuery(
    ["document-approvals", processInstanceId],
    getDocumentApprovals
  );

  const { data: statusData, isLoading: loading }: ResType = useQuery(
    ["document-status", processInstanceId],
    getDocumentStatus
  );

  const shouldBeEnabled = (index: number) => {
    const sorted = _.sortBy(data?.data, "name");

    const lastApprovedIndex = _.findLastIndex(sorted, { status: "APPROVED" });
    const lastDeclinedIndex = _.findLastIndex(sorted, { status: "DECLINED" });

    if (index === lastApprovedIndex + 1) {
      if (data?.data[index].comments === null) {
        return true;
      } else {
        return false;
      }
    }

    if (index === lastDeclinedIndex + 1) {
      if (data?.data[index].comments === null) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const hasPermission = (item: any) => {
    if (item?.assignee) {
      return item.assignee == user?.id;
    }

    if (item?.role) {
      return item.role?.id == role?.id;
    }

    return false;
  };

  if (isLoading || loading) return <Loader />;

  console.log(statusData);

  return data?.data?.length ? (
    <Grid sx={{ opacity: statusData?.data?.documentStatus !== "under_review" ? 0.5 : 1 }}>
      <Typography variant="h6">Document Sent for Approvals</Typography>
      <Box display="flex" m="1"></Box>
      <Box display="flex" justifyContent="flex-start"></Box>
      <Box>
        <Timeline>
          {_.sortBy(data?.data, "level")?.map((item: any, index: number) => (
            <ApprovalLevel
              enabled={
                shouldBeEnabled(index) &&
                hasPermission(item) &&
                statusData?.data?.documentStatus === "under_review"
              }
              item={item}
              index={index}
              key={index}
            />
          ))}
        </Timeline>
      </Box>
    </Grid>
  ) : (
    <Box>
      <Typography variant="h5">No Approval Processes Configured</Typography>
    </Box>
  );
}

export default Approval;
