import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { downloadSignedDocument, getPreviewTemplate, uploadHtmlContentsigned, uploadSignedDocument } from "api/services/quantum/template";
import { useParams } from "react-router";
import html2canvas from 'html2canvas';
import { snack } from "components/toast";
import jsPDF from "jspdf";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";

const SignPage = () => {
  const [imageSrc, setImageSrc] = useState("");
  const htmlContentRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState("");
  const [pdfdownloading, setPdfdownloading] = useState(false);
  const navigate = useNavigate()

  const processInstanceId: any = useParams().id;

  const { isLoading, error } = useQuery(
    ["get preview data", processInstanceId],
    getPreviewTemplate,
    {
      onSuccess: (res) => {
        setHtmlContent(res?.data || "");
      },
      onError: (err) => {
        console.error("Error fetching HTML content:", err);
      },
    }
  );

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click context menu
  };

  useEffect(() => {
    if (htmlContent) {
      console.log('htmlContent loaded');
    }
  }, [htmlContent]);

  // Function to convert HTML content to image
  const convertHtmlToImage = () => {
    setPdfdownloading(true);
    // Get the HTML content
    const htmlContentElement: any = htmlContentRef.current;

    const hideondownload = document.querySelectorAll(".hideondownload") as any;
    if (hideondownload.length > 0) {
      // Loop through each matching element
      hideondownload.forEach((hideonpreview: any, idx: any) => {
        if (hideonpreview) {
          // Set the display style of each element to "none"
          hideonpreview.style.display = "none";
        }
      });
    }

    setTimeout(() => {
      const divToPrint = document.getElementById("dragContainer") as any;
      // Use html2canvas to convert HTML content to an image
      html2canvas(divToPrint, { allowTaint: true, scale: 2 }).then(canvas => {
        canvas.getContext('2d');
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let doc = new jsPDF('p', 'mm');
        let position = 0;
        doc.addImage(imgData, 'PNG', 10, 50, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
          heightLeft -= pageHeight;
        }

        doc = addWaterMark(doc);
        doc = addHeaderandFooter(doc);
        // doc.save('download.pdf');

        mutateuploadHtmlContent({ processInstanceId, content: divToPrint.innerHTML });

        setPdfdownloading(false);
      }).catch(error => {
        console.error("Error converting HTML to image:", error);
      });
    }, 2000);
  };

  const { mutate: mutateuploadHtmlContent } = useMutation(uploadHtmlContentsigned, {
    onSuccess: (res) => {
      console.log(res);
      snack.success("File uploaded successfully");
      navigate(`/template/download/${processInstanceId}`)
    },
    onError: (error: any) => {
      console.error("Error uploading file:", error);
      snack.error("Error uploading file");
    },
  });

  const addWaterMark = (doc: any) => {
    const totalPages = doc.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setTextColor(150);
      doc.setFontSize(80);

      doc.saveGraphicsState();
      doc.setGState(new doc.GState({ opacity: 0.2 }));
      doc.text('', (doc.internal.pageSize.width / 2) - 50, (doc.internal.pageSize.height / 2) + 50, { angle: 45 });
      doc.restoreGraphicsState();
    }

    return doc;
  }

  const addHeaderandFooter = (doc: any) => {
    const totalPages = doc.internal.getNumberOfPages();

    doc.setFont('helvetica', 'italic')
    doc.setFontSize(8)
    for (var i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      const header = 'Report 2014';
      const footer = `Page ${i} of ${totalPages}`;

      // Header
      doc.text('Logo', 40, 15, { baseline: 'top' });

      // Footer
      doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });

      // doc.text('Page ' + String(i) + ' of ' + String(totalPages), doc.internal.pageSize.width / 2, 287, {
      //   align: 'center'
      // })
    }

    return doc;
  }

  const drag_start = (event: any) => {
    var style = window.getComputedStyle(event.target, null);
    var str =
      parseInt(style.getPropertyValue("left")) -
      event.clientX +
      "," +
      (parseInt(style.getPropertyValue("top")) - event.clientY) +
      "," +
      event.target.id;
    event.dataTransfer.setData("Text", str);
  };

  const drop = (event: any) => {
    var offset = event.dataTransfer.getData("Text").split(",");
    var dm = document.getElementById(offset[2]) as any;
    dm.style.left = event.clientX + parseInt(offset[0], 10) + "px";
    dm.style.top = event.clientY + parseInt(offset[1], 10) + "px";

    event.preventDefault();
    return false;
  };

  const drag_over = (event: any) => {
    event.preventDefault();
    event.dataTransfer.setData("Text/html", event.target.id);
    return false;
  };

  // const handleChangeImage = (e: any) => {
  //   var preview = document.getElementById("preview" + e.target.id) as any;
  //   preview.setAttribute("src", URL.createObjectURL(e.target.files[0]));
  // };


  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64Image = reader.result;
      var preview = document.getElementById("preview" + e.target.id) as any;
      preview.setAttribute("src", base64Image);
    };

    reader.readAsDataURL(file);
  };

  const removesign = (e: any) => {
    const ele = document.getElementById("signatureDragBox" + e.target.id.split("_")[1]) as HTMLElement;
    if (ele) {
      ele.remove();
    }
  }

  let signatureCounter = 1;

  const addsignature = () => {
    // Get the signatureDragBox element
    var signatureDragBox = document.getElementById("signatureDragBox");

    // Get the dragbox element
    var dragbox = document.getElementById("dragContainer");

    // Check if both elements exist
    if (signatureDragBox && dragbox) {
      // Clone the signatureDragBox element
      var clonedSignature = signatureDragBox.cloneNode(true) as HTMLElement;

      // Add a unique ID to the cloned signature
      clonedSignature.id = "signatureDragBox" + signatureCounter;
      clonedSignature.style.display = "block";

      // Find the preview element inside the cloned signature
      var clonedPreview = clonedSignature.querySelector(".previewesign") as HTMLElement;

      // Check if the preview element exists
      if (clonedPreview) {
        // Modify the ID of the preview element
        clonedPreview.id = "preview" + signatureCounter;
      }

      // Find the preview element inside the cloned signature
      var clonedhideonpreview = clonedSignature.querySelector("#hideonpreview") as HTMLElement;

      // Check if the preview element exists
      if (clonedhideonpreview) {
        // Modify the ID of the preview element
        clonedhideonpreview.id = "hideonpreview" + signatureCounter;
        clonedhideonpreview.className = "hideondownload";
        clonedhideonpreview.style.display = "block";
      }

      var clonedeSignUpload = clonedSignature.querySelector("#eSignUpload") as HTMLElement;

      // Check if the preview element exists
      if (clonedeSignUpload) {
        // Modify the ID of the preview element
        clonedeSignUpload.id = '' + signatureCounter;
        clonedeSignUpload.addEventListener("change", handleChangeImage);
      }

      // Set draggable attribute to true
      clonedSignature.draggable = true;

      // Attach event listeners for drag events
      clonedSignature.addEventListener("dragstart", drag_start);


      var clonedremovesignbox = clonedSignature.querySelector("#removesignbox") as HTMLElement;
      if (clonedremovesignbox) {
        clonedremovesignbox.id = "removesignbox_" + signatureCounter;
        clonedremovesignbox.addEventListener("click", removesign);
      }

      // Append the cloned signature to the dragbox
      dragbox.appendChild(clonedSignature);

      // Increment the signature counter
      signatureCounter++;
    }
  };

  return (
    <Box display={"flex"} >

      <Box id="download-file" width={"100%"} bgcolor={"#f2f2f2"} style={{ display: "flex", justifyContent: "center", maxHeight: "90%" }}>

        <Box mt={10}
          padding={5}
          margin={2}
          bgcolor={"#ffffff"}
          style={{
            overflowY: "auto",
            overflowX: "auto", height: "75vh", width: "90%", userSelect: "none"
          }}
          onContextMenu={handleContextMenu}
          sx={{
            maxWidth: "900px",
            wordWrap: "break-word"
          }}
        >

          <div
            id="dragContainer"
            className="dragcontainer"
            style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
            onDragOver={(e: any) => drag_over(e)}
            onDrop={(e: any) => drop(e)}
          >
            <div>
              {isLoading && <p>Loading...</p>}

              {htmlContent && !imageSrc && (
                <div ref={htmlContentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
              )}

            </div>

            {htmlContent &&
              <div
                className="dragbox"
                id="signatureDragBox"
                draggable="true"
                onDragStart={(e) => drag_start(e)}
                style={{ display: "none" }}
              >
                <img src="" id="preview" className="previewesign" />
                <div id="hideonpreview" style={{ backgroundColor: "#182f53", color: "white", border: "none", padding: "5px" }}>
                  Signature
                  <span id="removesignbox" onClick={removesign} style={{float: 'right', cursor: 'pointer'}}>X</span>
                  <div id="uploadEsign" style={{ display: "block" }}>
                    <form action="/upload" method="post" encType="multipart/form-data">
                      <input
                        type="file"
                        id="eSignUpload"
                        name="eSignUpload"
                        accept=".jpg, .jpeg, .png"
                        placeholder="Select an e-signature image (200x200)"
                        onChange={handleChangeImage}
                      />
                    </form>
                    <p className="signnote">*Please upload format JPG/PNG image of 100px size</p>
                  </div>
                </div>
              </div>
            }
          </div>
        </Box>
        <Box width={"15%"} bgcolor={"#f2f2f2"} style={{ textAlign: 'right' }} >
          {htmlContent && (
            <>
              {!pdfdownloading ? <Button size="large" color="secondary" variant="contained" onClick={convertHtmlToImage}
                style={{ marginTop: '10px' }}>
                Go to download
              </Button> : <Button size="large" color="secondary" variant="contained"
                style={{ marginTop: '10px' }}>
                Saving
              </Button>}

              <Button size="large" color="secondary" variant="contained" onClick={addsignature}
                style={{ marginTop: '10px' }}>
                Add signature
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SignPage;
