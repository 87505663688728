import { Box } from "@mui/material"
import Loader from "components/Loader"
// import ScaleLoader from "react-spinners/ScaleLoader";
// import PropagateLoader from "react-spinners/PropagateLoader";


const LoaderPage = () => {

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: '70vh',
            width: "100vw"
        }}>
            <Loader />
            {/* <PropagateLoader color="#37789e" /> */}
        </Box>
    )

}
export default LoaderPage