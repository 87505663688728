import { ArrowRightAltOutlined, MoreVert } from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { getCategoryDocs } from 'api/services/quantum/dashboard';
import CustomCard from 'components/CustomCard';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useNavigate } from 'react-router-dom';

function DocumentCategoriesCard() {
  const navigate = useNavigate();

  const [data1, setData1] = useState<any>("");
  const { data, isLoading, isError } = useQuery(["doc-category"], getCategoryDocs, {
    onSuccess: (data) => {
      setData1(data);
    },
  });
  const handleClick = (data, category) => {
    navigate("/dashboard/view-docs", { state: { data, category } })
    // navigate(`/template/`, { state: data });
  }
  return (
    <>
      <Typography color="primary" variant="h4" fontSize={"20px"} sx={{ marginTop: "20px", marginLeft: "20px", marginBottom: "15px" }}>
        Document Categories
      </Typography>
      <Grid container spacing={1} sx={{ mt: 2, overflow: "scroll", paddingBottom: "50px", marginBottom: "50px", paddingRight: "70px" }}>
        {data1.data &&
          Object.entries(data1.data).map(([category]) => (
            <Grid item key={category} xs={4}>
              <CustomCard
                sx={{
                  position: "relative",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  wordBreak: "break-all",
                }}
              >
                <div>
                  <Typography>
                    <DescriptionOutlinedIcon />
                  </Typography>
                </div>
                <Box flex={1} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                  <div>
                    <Typography color="primary" variant="subtitle2">
                      {category}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" color="rgba(0,0,0,0.6)" fontSize="20px">
                      {data1.data[category].length}
                    </Typography>
                  </div>
                </Box>
                <Box textAlign="right">
                  <Divider sx={{ my: 1 }} />
                  <Button endIcon={<ArrowRightAltOutlined />}
                    onClick={() => handleClick(data1.data?.[category], category)}
                  >View documents</Button>
                </Box>
              </CustomCard>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default DocumentCategoriesCard;
