export enum Permissions {
  MANAGE_ORG_PROFILE = "organization_manage_organization_profile",
  MANAGE_ORG_ROLES = "organization_manage_roles",
  MANAGE_USERS = "users_manage_users",
  VIEW_USERS = "users_view_users",
  MANAGE_ORG_BILLING_ENTITIES = "organization_manage_billing_entities",
  VIEW_ONEDRIVE_STORAGE = "storage_onedrive_view",
  VIEW_STORAGE = "storage_view",
  CREATE_STORAGE = "storage_create",
  EDIT_STORAGE = "storage_edit",
  DELETE_STORAGE = "storage_delete",
  VIEW_CALENDAR = "calendar_view",
  CREATE_CALENDAR = "calendar_create",
  EDIT_CALENDAR = "calendar_edit",
  VIEW_CLIENT_PROFILE = "client_profile_view",
  EDIT_CLIENT_PROFILE = "client_profile_edit",
  CREATE_CLIENT_PASSWORDS = "client_passwords_create",
  VIEW_CLIENT_PASSWORDS = "client_passwords_view",
  EDIT_CLIENT_PASSWORDS = "client_passwords_edit",
  DELETE_CLIENT_PASSWORDS = "client_passwords_delete",
  CREATE_CLIENT_KYB = "client_kyb_create",
  VIEW_CLIENT_KYB = "client_kyb_view",
  EDIT_CLIENT_KYB = "client_kyb_edit",
  DELETE_CLIENT_KYB = "client_kyb_delete",
  VIEW_CLIENT_RECURRING_PROFILE = "client_recurring_profile_view",
  EDIT_CLIENT_RECURRING_PROFILE = "client_recurring_profile_edit",
  TERMINATE_CLIENT_RECURRING_PROFILE = "client_recurring_profile_terminate",
  VIEW_CLIENT_GROUPING = "client_grouping_view",
  CREATE_CLIENT_GROUPING = "client_grouping_create",
  EDIT_CLIENT_GROUPING = "client_grouping_edit",
  DELETE_CLIENT_GROUPING = "client_grouping_delete",
  VIEW_CLIENT_BILLING = "client_billing_view",
  CREATE_CLIENT_BILLING = "client_billing_create",
  EDIT_CLIENT_BILLING = "client_billing_edit",
  DELETE_CLIENT_BILLING = "client_billing_delete",
  VIEW_CLIENT_ATTACHMENTS = "client_attachments_view",
  CREATE_CLIENT_ATTACHMENTS = "client_attachments_create",
  EDIT_CLIENT_ATTACHMENTS = "client_attachments_edit",
  DELETE_CLIENT_ATTACHMENTS = "client_attachments_delete",
  VIEW_CLIENT_STORAGE = "client_storage_view",
  CREATE_CLIENT_STORAGE = "client_storage_create",
  EDIT_CLIENT_STORAGE = "client_storage_edit",
  DELETE_CLIENT_STORAGE = "client_storage_delete",
  VIEW_ALL_TASKS = "task_view_all",
  VIEW_ASSIGNED_TASKS = "task_view_assigned",
  CREATE_TASK = "task_create",
  EDIT_TASK = "task_edit",
  DELETE_TASK = "task_delete",
  TERMINATE_TASK = "task_terminate",
  VIEW_CATEGORIES = "categories_view",
  CREATE_CATEGORIES = "categories_create",
  EDIT_CATEGORIES = "categories_edit",
  DELETE_CATEGORIES = "categories_delete",
  VIEW_SERVICES = "services_view",
  CREATE_SERVICES = "services_create",
  EDIT_SERVICES = "services_edit",
  DELETE_SERVICES = "services_delete",
  VIEW_CLIENTS = "clients_view",
  CREATE_CLIENTS = "clients_create",
  EDIT_CLIENTS = "clients_edit",
  DELETE_CLIENTS = "clients_delete",
  VIEW_REPORTS = "reports_view_reports",
  VIEW_ADMIN_DASHBOARD = "dashboard_view_admin_dashboard",
}
