import { Box, Grid, Paper, TextField, Typography, styled, Button, Divider } from "@mui/material";
import LoadingButton from "components/LoadingButton";
import { useState } from "react";
import { agreement } from "assets";
import { useMutation } from "react-query";
import React from "react";
import { snack } from "components/toast";
import { setOrgPrefix } from "api/services/quantum/prifix";
import { useTheme } from '@mui/material/styles';
import {
  RendaringGraphic, BellWithNotification, AtomNew
} from "assets";

const images = [
  {
    label: 'Dashboard',
    imgPath: `${RendaringGraphic}`,
    description: "Uncover actionable insights with Quantum's powerful analytical dashboard."
  },
  {
    label: 'Remainders',
    imgPath: `${BellWithNotification}`,
    description: "Never miss a contract expiration with Quantum's timely reminders."
  },
  {
    label: 'ATOM',
    imgPath: `${AtomNew}`,
    description: "Streamline your document workflow by seamlessly integrating Quantum with our other product ATOM."
  }
];

const Configure = () => {
  const [docprefix, setPrefix] = useState("");
  const [docnumber, setNumber] = useState("");
  const [clientprefix, setClientPrefix] = useState("");
  const [clientnumber, setClientNumber] = useState("");
  const [formErrors, setFormErrors] = useState({
    docprefix: "",
    docnumber: "",
    clientprefix: "",
    clientnumber: "",
  });

  const validateInput = (fieldName, value) => {
    const minLength = 3;
    const maxLength = 6;

    switch (fieldName) {
      case "docprefix":
      case "clientprefix":
        return value.trim().length < minLength || value.trim().length > maxLength
          ? "Length: 3 and 6 characters"
          : "";
      case "docnumber":
      case "clientnumber":
        return (
          isNaN(value) ||
            value.trim() === "" ||
            value <= 0 ||
            !Number.isInteger(Number(value)) ||
            value.length < minLength ||
            value.length > maxLength
            ? "Length 3 and 6 characters"
            : "");

      default:
        return "";
    }
  };



  const { mutate } = useMutation(setOrgPrefix, {
    onSuccess: (res) => {
      snack.success("prefix set successfully");
      window.location.href = "/dashboard/dashboard-analytics";
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();


    // Validate inputs
    const errors = {
      docprefix: validateInput("docprefix", docprefix),
      docnumber: validateInput("docnumber", docnumber),
      clientprefix: validateInput("clientprefix", clientprefix),
      clientnumber: validateInput("clientnumber", clientnumber),
    };

    setFormErrors(errors);
    // Check if there are no errors
    if (Object.values(errors).every((error) => error === "")) {
      mutate({
        docprefix, docnumber, clientprefix, clientnumber
      })
    }
  };

  return (
    <Grid container style={{
      backgroundImage: "linear-gradient(217deg, #dfb6f0, #dbdeb6, #b6deb9, #84b3bd  70.71%)",
      height: '100vh', display: "flex", justifyContent: "center", alignItems: "center"
    }}>
      <Grid
        sx={{
          borderRadius: "10px",
          backgroundColor: "#F6F0F0",
          height: { xs: "100%", sm: "80%", md: "80%" }, // Adjust height for different screen sizes
          width: { xs: "100%", sm: "100%", md: "70%" }, // Adjust width for different screen sizes
        }}
        container>
        <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "90%" }}>
          <Grid item xs={6} lg={6} order={{ xs: 1, lg: 0 }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <video autoPlay muted loop playsInline style={{ width: "400px", borderBottomColor: "none" }}>
              <source src={agreement} type="video/mp4" />
              Sorry, your browser doesn't support videos.
            </video>

          </Grid>
        </Grid>

        {/* <Divider orientation="vertical" flexItem /> */}


        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box
            mt={5}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          // minHeight="100vh"
          >
            <Box maxWidth="400px" width="100%" marginBottom="70px">
              <Typography variant="subtitle1" marginBottom="30px" color="primary" fontSize="bold" fontWeight="800">Set Custom Prefix</Typography>
              <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <Box>
                  <Typography variant="subtitle2" style={{ display: "flex", justifyContent: "flex-start", color: "primary", fontSize: "bold", fontWeight: "800" }}>Set Document Id</Typography>
                  <Box display="flex" gap={2} mt={2}>
                    <TextField
                      label="Prefix *"
                      variant="outlined"
                      placeholder="Eg: Doc"
                      size="small"
                      value={docprefix}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
                        setPrefix(trimmedValue);
                        setFormErrors({ ...formErrors, docprefix: validateInput("docprefix", trimmedValue) });
                      }}
                      helperText={formErrors.docprefix}
                      error={Boolean(formErrors.docprefix)}
                      inputProps={{
                        minLength: 3,
                        maxLength: 6,
                      }}
                    />
                    <TextField
                      label="Number *"
                      variant="outlined"
                      placeholder="Eg: 001"
                      size="small"
                      // type="number"
                      value={docnumber}
                      onChange={(e) => {
                        setNumber(e.target.value);
                        setFormErrors({ ...formErrors, docnumber: validateInput("docnumber", e.target.value) });
                      }}
                      helperText={formErrors.docnumber}
                      error={Boolean(formErrors.docnumber)}
                      inputProps={{
                        minLength: 3,
                        pattern: "[0-9]{3,6}",
                        maxLength: 6,
                      }}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography variant="subtitle2" style={{ display: "flex", justifyContent: "flex-start", color: "primary", fontSize: "bold", fontWeight: "800", marginTop: "10px" }}>Set Client Id</Typography>
                  <Box display="flex" gap={2} mt={2}>
                    <TextField
                      label="Prefix *"
                      placeholder="Eg: Client"
                      variant="outlined"
                      size="small"
                      value={clientprefix}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
                        setClientPrefix(trimmedValue);
                        setFormErrors({ ...formErrors, clientprefix: validateInput("clientprefix", trimmedValue) });
                      }}
                      helperText={formErrors.clientprefix}
                      error={Boolean(formErrors.clientprefix)}
                      inputProps={{
                        minLength: 3,
                        maxLength: 6,
                      }}
                    />
                    <TextField
                      label="Number *"
                      variant="outlined"
                      placeholder="Eg: 001"
                      size="small"
                      // type="number"
                      value={clientnumber}
                      onChange={(e) => {
                        setClientNumber(e.target.value);
                        setFormErrors({ ...formErrors, clientnumber: validateInput("clientnumber", e.target.value) });
                      }}
                      helperText={formErrors.clientnumber}
                      error={Boolean(formErrors.clientnumber)}
                      inputProps={{
                        minLength: 3,
                        pattern: "[0-9]{3,6}",
                        maxLength: 6,
                      }}
                    />
                  </Box>
                </Box>

                <LoadingButton
                  loading={false}
                  sx={{ mt: 4 }}
                  size="large"
                  fullWidth
                  type="submit"
                  title="Next"
                  color="secondary"
                />
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Configure;