import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Avatar, Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { updateApproval } from "api/services/approval-heirarchy";
import { snack } from "components/toast";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { formattedDatetime } from "utils/formattedDateTime";
import { handleError } from "utils/handleError";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { approveLevel, rejectLevel } from "api/services/quantum/approvals";
import { useParams } from "react-router-dom";
import moment from "moment";

interface Props {
  item: any;
  index: number;
  queryKey?: string;
  enabled: boolean;
}

function ApprovalLevel(props: Props) {
  const { item, index, enabled, queryKey = "task-approvals" } = props;
  console.log("task item", enabled);
  const queryClient = useQueryClient();
  const [status, setStatus] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [textField, setTextField] = useState(false);
  const params = useParams();
  const [comments, setComments] = useState("");
  const { id: processInstanceId } = params;

  useEffect(() => {
    setStatus(item?.status);
    setNewStatus(item?.status);
  }, [item]);

  const { mutate } = useMutation(updateApproval, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      snack.success("Updated successfully");
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  const { mutate: reject, isLoading } = useMutation(rejectLevel, {
    onSuccess: () => {
      queryClient.invalidateQueries("document-approvals");
      queryClient.invalidateQueries("document-status");
      queryClient.invalidateQueries("get Template");
    },
  });

  const { mutate: approve, isLoading: isloadingg } = useMutation(approveLevel, {
    onSuccess: () => {
      queryClient.invalidateQueries("document-approvals");
      queryClient.invalidateQueries("document-status");
      queryClient.invalidateQueries("get Template");
    },
  });

  const handleUpdate = () => {
    mutate({
      approvalId: item.id,
      status: newStatus,
      remarks,
    });
  };

  const handleRejection = (id: any, level: string) => {
    reject({
      id: id,
      data: {
        level,
        processInstanceId,
        comments,
      },
    });
    setTextField(false);
  };

  const handleApprove = (id: any, level: string) => {
    setTextField(false);
    approve({
      id: id,
      data: {
        level,
        processInstanceId,
        comments,
      },
    });
  };

  const handleComments = (e) => {
    console.log(e.target.value);
    setComments(e.target.value);
  };

  return (
    <TimelineItem
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          sx={{
            background: item?.status === "APPROVED" ? "#89B152" : "white",
            ...(item?.status === "APPROVED" && {
              border: "2px solid transparent",
            }),
          }}
        >
          {item?.status === "APPROVED" && (
            <CheckRoundedIcon
              sx={{
                color: "green",
              }}
            />
          )}

          {item?.status === "DECLINED" && (
            <DangerousOutlinedIcon
              sx={{
                color: "red",
              }}
            />
          )}

          {item?.status === null && (
            <AccessTimeFilledOutlinedIcon
              sx={{
                color: "yellow",
              }}
            />
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ pb: 5 }}>
        <Box
          sx={{
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Box>
            <Typography>
              {item?.status === null
                ? "Pending..."
                : item?.status === "APPROVED"
                  ? "Approved by"
                  : "Rejectrd by"}
            </Typography>
            <Typography variant="subtitle2">{item?.assigneeName}</Typography>
            <Typography variant="body2" color="primary">
              {item?.assigneeRoles}
            </Typography>
          </Box>
          <Typography fontSize='10px'>
            {item?.lastUpdatedOn && moment(item?.lastUpdatedOn).format("h:mm A")}
          </Typography>

          <Box>
            <Typography fontSize='10px'>
              {item?.lastUpdatedOn && moment(item?.lastUpdatedOn).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
        <Box>
          {!textField && (
            <Button disabled={!enabled}>
              <TextsmsOutlinedIcon onClick={() => setTextField(!textField)} />
            </Button>
          )}

          {textField && (
            <Box display="flex" mt={2}>
              <TextField
                label="Notes"
                name="notes"
                onChange={handleComments}
                // value={data?.notes || ""}
                placeholder="Type your text here…"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
              />
              <Button onClick={() => setTextField(!textField)} sx={{ marginBottom: "50px" }}>
                <UndoOutlinedIcon />
              </Button>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              sx={{ margin: 1 }}
              onClick={() => handleRejection(item.id, item.name)}
              disabled={!enabled}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 1 }}
              onClick={() => handleApprove(item.id, item.name)}
              disabled={!enabled}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
}

export default ApprovalLevel;
