import { Box, Typography } from "@mui/material"
import { warningSign } from "assets"

const DemoErrorPage = () => {
    return (
        <Box height="80vh" width='100vw' display='flex' justifyContent='center' alignItems='center'>
            <Box width="40%" textAlign='center'>
                <img width='150px' src={warningSign} alt="warning" />
                <Typography mt={1} variant="h5" color="primary">Encountered an error loading this page. Please close and try again, or contact us if it persists.</Typography>

            </Box>
        </Box>
    )
}
export default DemoErrorPage