import { http } from "api/http";

const getTemplate = () => {
  return http.get("/camunda/data");
};

const getCatagoryData = ({ queryKey }) => {
  return http.get(`camunda/get-catagory/${queryKey[1]}`);
};

const getTemplateAndCatData = ({ queryKey }) => {
  return http.get(`camunda/get-Template-CatData/${queryKey[1]}`);
};

const getAllTemplates = ({ queryKey }) => {
  return http.get("/camunda/all-details", { params: { ...queryKey[1] } });
};

const getTemplateById = ({ queryKey }) => {
  return http.get("/camunda/templatebyid", { params: { ...queryKey[1] } });
};

const getCatAndSubCatDetails = () => {
  return http.get("/camunda/cat-sub-catagory");
};

const getTemplate1 = () => {
  return http.get("/camunda/data");
};

const getTemplateData = ({ queryKey }: any) => {
  return http.get(`camunda/template-data/${queryKey[1]}`);
};

const startProcess = (data) => {
  return http.post("camunda/process-instance", data);
};

const getDriveTemplate = ({ queryKey }: any) => {
  return http.get(`camunda/get-drive-tempate/${queryKey[1]}`);
};

const getProcessDetails = (data) => {
  return http.get(`camunda/get-process-details/${data.processId}`);
};

const updateProcessDetails = (data) => {
  return http.post("camunda/update-details", data);
};

const submitUpdateProcessDetails = (data) => {
  return http.post("camunda/submit-template", data);
};

const updataTemplateData = (id, data) => {
  return http.put(`/camunda/update-save/${id}`, data);
};

const insertActivity = ({ data }: any) => {
  return http.post("camunda/activity-insert", data);
};

const addUsersToProcess = (data: any) => {
  return http.put("camunda/add-users", data);
};

const getUsersToProcess = (data: any) => {
  return http.get(`camunda/added-users/${data.queryKey[1]}`);
};

const getDocmentDetails = ({ queryKey }: any) => {
  return http.get(`camunda/get-Document-details/${queryKey[1]}`);
};

const getTemplateDrive = (data) => {
  return http.post(`camunda/get-template-drive`, data);
};

const getPreviewTemplate = ({ queryKey }: any) => {
  console.log({queryKey})
  return http.get(`camunda/get-preview-template/${queryKey[1]}`);
};

const getSingelTempateDetailsWithPI = ({ queryKey }: any) => {

  return http.get(`camunda/single-template-details-with-pro-ins/${queryKey[1]}`);
};

const updateSingelProcessDetails = (data) => {
  return http.put(`camunda/single-template-update`, data);
};

const updateActivityData = (data) => {
  return http.put(`camunda/update-activity-data`, data);
};

const downloadTemplate = (data) => {
  return http.post(`camunda/download-template-call`, data);
};

const postSavedTemplates = (data) => {
  return http.post("camunda/saved-templates", data);
};

const sendMessage = (data) => {
  return http.post("camunda/comment-doc", data);
};

const getComments = (data: any) => {
  return http.get(`camunda/added-comments/${data.queryKey[1]}`);
};

const sendPushNotification = (data: any) => {
  return http.post("camunda/push-notification", data);
};

const getCamundaUsers = ({ queryKey }) => {
  return http.get(`/camunda/${queryKey[1]}`);
};

const deleteAssignedUsers = (data: any) => {
  return http.post(`camunda/delete-assigned-users`, data);
};

const getDynamicData = ({ queryKey }) => {
  return http.get(`camunda/get-dynamic-format/${queryKey[1]}`);
};

const updateSectionData = (data) => {
  return http.put(`camunda/update-section-data`, data);
};

const updateDynamicData = (data) => {
  return http.put(`camunda/update-dynamic-data`, data);
};

const updateDynamicData1 = (data) => {
  return http.put(`camunda/update-dynamic-data1`, data);
};

const UpdateMutuallyExclusiveSectionsData = (data) => {
  return http.put(`camunda/update-mutually-sections`, data);
};

const updateTabularSection = (data) => {
  return http.put(`camunda/update-tabular-section`, data);
};

const submitDynamicData1 = (data) => {
  return http.put(`camunda/submit-dynamic-data`, data);
};

const deleteDocument = (id) => {
  return http.delete(`camunda/delete-document/${id}`);
};

const draftforme = (data) => {
  return http.post("camunda/draftforme", data);
};

const updateDocmentFooter = (data) => {
  return http.put("camunda/footerUpdate", data);
};
const orgConfig = (data) => {
  return http.post("camunda/orgconfig", data);
};

const paymantStatusUpdate = (data) => {
  return http.post("camunda/paymant-status-update", data);
};

const uploadSignedDocument = (data) => {
  return http.post("camunda/upload-signed-pdf", data);
};

const downloadSignedDocument =(data)=>{
  console.log(data)
  return http.post("camunda/download-signed-pdf", data);
}

const uploadHtmlContentsigned = (data: any) => {
  return http.post("download/raw-html", data);
};


export {
  getTemplate,
  getTemplateData,
  startProcess,
  getDriveTemplate,
  getProcessDetails,
  updateProcessDetails,
  updataTemplateData,
  insertActivity,
  addUsersToProcess,
  getDocmentDetails,
  getTemplateDrive,
  getPreviewTemplate,
  getUsersToProcess,
  getTemplate1,
  getAllTemplates,
  getTemplateById,
  getSingelTempateDetailsWithPI,
  updateSingelProcessDetails,
  downloadTemplate,
  postSavedTemplates,
  sendMessage,
  getComments,
  sendPushNotification,
  getCamundaUsers,
  deleteAssignedUsers,
  submitUpdateProcessDetails,
  getCatagoryData,
  getTemplateAndCatData,
  getCatAndSubCatDetails,
  getDynamicData,
  updateSectionData,
  updateDynamicData,
  updateDynamicData1,
  submitDynamicData1,
  deleteDocument,
  draftforme,
  updateDocmentFooter,
  UpdateMutuallyExclusiveSectionsData,
  orgConfig,
  updateTabularSection,
  paymantStatusUpdate,
  updateActivityData,
  uploadSignedDocument,
  downloadSignedDocument,
  uploadHtmlContentsigned
};
