import { http } from "api/http";

const setOrgPrefix = (data) => {
  return http.post("prefix/set-prefix", data);
};

const getPrefixNumber = () => {
  return http.get("prefix/get-doc-prefix-number");
};

const getOrgPrefixData = () => {
  return http.get("prefix/get-org-prefix");
};

const getClientPrefixId = () => {
  return http.get("prefix/get-client-prefix-number");
};

const updatePrefixData = (data) => {
  return http.put(`prefix/update-prefix`, data);
};

export { setOrgPrefix, getPrefixNumber, getOrgPrefixData, getClientPrefixId, updatePrefixData };
