import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getUsers } from "api/services/users";
import moment from "moment";
import { useQuery } from "react-query";
import { ResType } from "types";
import { CLIENT_CATEGORIES } from "data/constants";
import TextFieldWithCopy from "views/client-view/ProfileDetails/TextFieldWithCopy";
import { useState } from "react";

const QuantumClientBasicInformation = ({ data, setState }) => {
  // const { data: users }: ResType = useQuery("users", getUsers);
  // let usersData: any = [];
  // if (users && users?.data) {

  //   users?.data.map((item) => (usersData.push([item.id, item.fullName])))
  // }
  const [clientManager, setClientManager] = useState(data?.clientManager || "");


  const handleChange = (e: any) => {
    setState({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCategoryChange = (e: any) => {
    setState({
      ...data,
      category: e.target.value,
      subCategory: null,
    });
  };

  const handleClientManagerChange = (e: any, value) => {
    setClientManager(value)
    const index = e.target.value
    setState({
      ...data,
      // clientManager: users.data[index].id
    })

  }

  const isValidMobile = (mobile: any) => {
    if (typeof (mobile) !== "undefined" && typeof (mobile) !== undefined && mobile !== "" && typeof (mobile) !== null) {
      const isPhoneValid = (phoneNumver) => {
        const PHONE_REGEX = /^[1-9]{1}[0-9]{9}$/;
        return PHONE_REGEX.test(phoneNumver);
      }
      if (isPhoneValid(mobile)) {
        return true
      } else {
        return false
      }
    }
  }
  const isValidEmail = (email) => {
    if (typeof (email) !== "undefined" && typeof (email) !== undefined && email !== "" && typeof (email) !== null) {
      var emailPattern = /^[a-zA-Z0-9~!#$%^&*_+{}|?.'=`]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email);
    }
  }

  let subCategories = CLIENT_CATEGORIES.find(
    (item) => item.value === data?.category
  )?.subCategories;

  return (
    <Box mt={2}>
      <Typography color="primary" variant="h6" sx={{ mb: 2 }}>
        Basic Information
      </Typography>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              disabled
              label="Client Id"
              name="clientId"
              value={data?.clientId || ""}
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Display Name"
              name="displayName"
              value={data?.displayName || ""}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              select
              onChange={handleCategoryChange}
              value={data?.category ?? ""}
              InputLabelProps={{ shrink: true }}
              required
              name="category"
              label="Client Category"
            >
              {CLIENT_CATEGORIES.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {subCategories && (
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                select
                onChange={handleChange}
                value={data?.subCategory ?? ""}
                InputLabelProps={{ shrink: true }}
                required
                name="subCategory"
                label="Client Sub Category"
              >
                {subCategories?.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item xs={4}>
            <TextField
              label="Authorized Person"
              name="authorizedPerson"
              onChange={handleChange}
              value={data?.authorizedPerson || ""}
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Designation"
              name="designation"
              onChange={handleChange}
              value={data?.designation || ""}
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldWithCopy
              label="Mobile Number"
              name="mobileNumber"
              value={data?.mobileNumber || ""}
              onChange={handleChange}
              inputProps={{ maxLength: 10 }}
              error={!isValidMobile(data?.mobileNumber) && data?.mobileNumber !== "" && data?.mobileNumber !== null}
              helperText={(!isValidMobile(data?.mobileNumber) && data?.mobileNumber !== "" && data?.mobileNumber !== null) && 'Invalid Mobile Number'}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldWithCopy
              label="Alternate Mobile Number"
              name="alternateMobileNumber"
              value={data?.alternateMobileNumber || ""}
              inputProps={{ maxLength: 10 }}
              onChange={handleChange}
              error={!isValidMobile(data?.alternateMobileNumber) && data?.alternateMobileNumber !== "" && data?.alternateMobileNumber !== null}
              helperText={(!isValidMobile(data?.alternateMobileNumber) && data?.alternateMobileNumber !== "" && data?.alternateMobileNumber !== null) && 'Invalid Alternate Mobile Number'}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldWithCopy
              label="Email"
              name="email"
              value={data?.email || ""}
              onChange={handleChange}
              error={!isValidEmail(data?.email) && data?.email !== "" && data?.email !== null}
              helperText={(!isValidEmail(data?.email) && data?.email !== "" && data?.email !== null) && 'Invalid Email ID'}
            />
          </Grid>
          {data?.category === "individual" && (
            <Grid item xs={4}>
              <TextField
                label="Date of birth"
                name="dob"
                onChange={handleChange}
                value={data?.dob || ""}
                fullWidth
                type="date"
                variant="outlined"
                size="small"
                inputProps={{
                  max: moment().format("YYYY-MM-DD"),
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default QuantumClientBasicInformation;
