import { Box, Button, Typography } from "@mui/material"
import { getCompletedDocuments } from "api/services/quantum/document";
import { useQuery } from "react-query";
import { ResType } from "types";
import Table from "components/Table";

const CompletedDocuments = () => {
    const { data, isLoading }: ResType = useQuery(
        ["deleted-documents"],
        getCompletedDocuments, {
        onSuccess(res: any) {
            console.log(res?.data)
        }
    }
    );
    return (
        <Box p={2}>
            <Typography variant="subtitle1" mb={2}>
                Completed Documents
            </Typography>
            <Table
                loading={isLoading}
                data={data?.data || []}
                columns={[
                    {
                        key: "id",
                        title: "Document Id",
                    },
                    {
                        key: "templateName",
                        title: "Tempalte Name",
                    },
                    {
                        key: 'documentStatus',
                        title: "doc status"
                    }
                    // {
                    //     key: "Action",
                    //     title: "Action",
                    //     render: (item: any) => {
                    //         return (
                    //             <Button
                    //                 size="small"
                    //                 variant="contained"
                    //                 color="secondary"
                    //             // onClick={() => handleRestore(item?.id)}
                    //             >
                    //                 Restore
                    //             </Button>
                    //         );
                    //     },
                    // },
                ]}
            />
        </Box>
    )
}

export default CompletedDocuments