import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { deleteAssignedUsers } from "api/services/quantum/template";
import { useParams } from "react-router-dom";
import { snack } from "components/toast";

const UserCard = ({ userData, users }) => {
  const processInstanceId = useParams()?.id;
  const queryClient = useQueryClient();
  const userId = parseInt(localStorage.getItem("userId") || "");
  let { mutate } = useMutation(deleteAssignedUsers, {
    onSuccess: () => {
      snack.success("User Removed from the document Successfully")
      queryClient.invalidateQueries("get-users");
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });
  const handleClick = (id) => {
    if (users.length === 0 || users.length === 1) {
      snack.error("Document should contain at least one user");
    } else {
      mutate({
        id: id,
        processInstanceId,
      });
    }
  };

  return (
    <Box
      style={{
        // display: "flex",
        // alignItems: "center",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        marginBottom: "10px",
        width: "100%"
        // position: "relative",
      }}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Grid container>
          <Grid item xs={2}>
            {userData?.imageUrl ? (
              <Avatar
                sx={{
                  width: "35px",
                  height: "35px",
                  border: "2px solid white",
                  boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
                }}
                src={userData?.imageUrl}
              ></Avatar>
            ) : (
              <Avatar
                sx={{
                  width: "35px",
                  height: "35px",
                  border: "2px solid white",
                  boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
                }}
              >
                {userData?.fullName[0]}
              </Avatar>
            )}
          </Grid>
          <Grid item xs={8}>
            <Box>
              <Typography color="primary" variant="subtitle2">{userData?.fullName}</Typography>
              <Typography variant="body2" sx={{ color: "#787675" }} >{userData?.email}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display={"flex"} mt={1} textAlign={"center"}>
              {users.length > 1 && (
                <Button
                  onClick={() => handleClick(userData.id)}
                  variant="contained"
                  size="small"
                  sx={{ backgroundColor: "#ffffff", color: "#2EA2FF" }}
                // startIcon={<CancelPresentationIcon color="secondary" />}
                >
                  Remove

                </Button>
              )}

            </Box>
          </Grid>
        </Grid>
        {/* <Box
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            overflow: "hidden",
            marginRight: "10px",
          }}
        >
          {userData?.imageUrl ? (
            <Avatar
              sx={{
                width: "35px",
                height: "35px",
                border: "2px solid white",
                boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
              }}
              src={userData?.imageUrl}
            ></Avatar>
          ) : (
            <Avatar
              sx={{
                width: "35px",
                height: "35px",
                border: "2px solid white",
                boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
              }}
            >
              {userData?.fullName[0]}
            </Avatar>
          )}
        </Box>
        <Box width={"230px"}>
          <Typography color="primary" variant="subtitle2">{userData?.fullName}</Typography>
          <Typography variant="body2" sx={{ color: "#787675" }} >{userData?.email}</Typography>
        </Box>
        <Box display={"flex"} width={"50px"} textAlign={"center"}>
          <Button
            onClick={() => handleClick(userData.id)}
            startIcon={<CancelPresentationIcon color="secondary" />}
          // style={{
          //   // position: "absolute",
          //   top: "5px",
          //   right: "5px",
          // }}
          > </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default UserCard;
