import { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from 'react-query';
import { snack } from 'components/toast';
import { contractDataRemander, getContractDate } from 'api/services/quantum/document';
import { useParams } from 'react-router-dom';
import { ResType } from 'types';
import { DesktopDatePicker } from '@mui/lab';

const ContractExpairy = ({ setDrawerOpen }) => {
    const processId = useParams()?.id;
    const [contractEndDate, setContractEndDate]: any = useState("");
    const [firstReminder, setFirstReminder] = useState(false);
    const [reminderDays, setReminderDays] = useState(0);
    const [noReminder, setNoReminder] = useState(false);
    const [contractEndDateError, setContractEndDateError] = useState("");
    const [reminderError, setReminderError] = useState("");
    const inputStyle = {
        // borderBottom: '1px solid #000',
        width: '60px',
    };

    const { mutate } = useMutation(contractDataRemander, {
        onSuccess: (res: any) => {
            snack.success("Contract date created successfully")
            setDrawerOpen(false)
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });

    const { data, isLoading }: ResType = useQuery(
        ["get-contract-date", processId],
        getContractDate,
        {
            onSuccess: (res) => {
                if (res?.data !== null) {
                    const initialDate = new Date(res?.data?.contractDate);
                    const formattedDate = initialDate.toISOString().split('T')[0];
                    setContractEndDate(formattedDate);
                    setReminderDays(res?.data?.remainderBefore);
                    setNoReminder(res?.data?.remainderStatus);
                    setFirstReminder(res?.data?.remainderDaysStatus);
                }
            },
        }
    );

    const handleFirstReminderChange = () => {
        setFirstReminder(!firstReminder);
        setNoReminder(false);
    };

    const handleNoReminderChange = () => {
        setNoReminder(!noReminder);
        setFirstReminder(false);
        setReminderDays(0);
        setReminderError("");
    };

    const handleApplyClick = () => {
        let isValid = true;
        if (contractEndDate === "") {
            setContractEndDateError("Contract End Date is mandatory");
            isValid = false;
        } else {
            setContractEndDateError("");
        }

        if (!firstReminder && !noReminder) {
            setReminderError("You must select at least one reminder option");
            isValid = false;
        } else if (firstReminder && reminderDays <= 0) {
            setReminderError("Please enter a valid number of days for the first reminder");
            isValid = false;
        } else {
            setReminderError("");
        }

        if (isValid) {
            const data = {
                id: processId,
                contractEndDate,
                firstReminder,
                reminderDays,
                noReminder,
            };
            mutate(data);
        }
    };


    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [forFocus, setForFocus] = useState(true);

    const handleSelect = () => {
        setIsDatePickerOpen(false);
        setForFocus(false);
    };

    const handleFocus = () => {
        if (forFocus)
            setIsDatePickerOpen(true);
        setForFocus(true);
    }

    const handleBack = () => {
        setDrawerOpen(false)
    }

    return (
        <Box p={1}>
            <Typography variant="h6" fontSize={"20px"} color="primary">Contract End Date</Typography>

            <DesktopDatePicker
                label="Contract End Date"
                value={contractEndDate}
                onChange={(v) => {
                    setContractEndDate(v);
                    handleSelect();
                }}
                open={isDatePickerOpen}
                renderInput={(params) => <TextField
                    sx={{ mt: 2 }}
                    required
                    fullWidth
                    size="small"
                    {...params}
                    // onKeyDown={handleKeyDown}
                    onClick={() => { setIsDatePickerOpen(true); }}
                    onFocus={handleFocus}
                />}
            />

            {/* <TextField
                id="contract-end-date"
                type="date"
                size='small'
                title='select contract end date'
                value={contractEndDate}
                onChange={(e) => setContractEndDate(e.target.value)}
                error={contractEndDateError !== ""}
                helperText={contractEndDateError}
            /> */}
            <Typography variant="h5" fontSize={'20px'} py={1} color="#000000" my={1}>Set up Reminder</Typography>
            <Typography variant="subtitle2" color={'#6E6E6E'} mb={2}>Receive email reminder of the upcoming important date</Typography>
            <FormControlLabel
                control={<Checkbox
                    checked={firstReminder}
                    onChange={handleFirstReminderChange}
                />}
                label={
                    <Typography p={1} variant='body2'>
                        Send first reminder
                        <TextField
                            type="number"
                            // label="Enter number of days"
                            variant="standard"
                            style={{ width: '50px', marginLeft: '10px', marginRight: '10px' }}
                            value={reminderDays}
                            onChange={(e) => setReminderDays(parseInt(e.target.value, 10))}
                        />
                        Days before the due date
                    </Typography>
                }
            />
            <FormControlLabel
                control={<Checkbox
                    checked={noReminder}
                    onChange={handleNoReminderChange}
                />}
                label={
                    <Typography p={1} variant='body2'>
                        No reminder
                    </Typography>
                }
            />
            <Typography color={'error'}>{reminderError ? "**" : ""} {reminderError}</Typography>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button variant="outlined" color="error" onClick={handleBack}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleApplyClick}>
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ContractExpairy;
