import { http } from "api/http";

const getRecentDocs = (data: any) => {
  return http.get(`document/recent-docs`);
};

const getCategoryDocs = (data: any) => {
  return http.get(`document/docs-category`);
};

const getUserstableData = (data: any) => {
  return http.get(`document/users-table`);
};

export { getRecentDocs, getCategoryDocs, getUserstableData };
