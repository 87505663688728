import { http } from "api/http";

const sendPrefillSection = ({ id, data }) => {
  return http.put(`/prefill/section/${id}`, data);
};

const getClientPrefill = ({ queryKey }: any) => {
  return http.get(`prefill/prefill-client-data/${queryKey[1]}`);
};

const getClientPrefillData = (data) => {
  return http.get(`prefill/prefill-client-data/${data?.id}`);
};

const updateClientPrefill = ({ id, data }) => {
  return http.put(`/prefill/update-prefill-client/${id}`, data);
};

export { sendPrefillSection, getClientPrefill, updateClientPrefill, getClientPrefillData };
