import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { StyledCard } from 'views/forms/styles';
import { signyourself } from 'assets';
import { sendforothers } from 'assets';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router";

const Signdashboard = () => {
  const navigate = useNavigate()
  const processInstanceId: any = useParams().id;
  console.log(processInstanceId)
  const handlesignyourself = () => {
    // navigate("/Signdashboard/signyourself")
    // navigate(`/template/${processInstanceId}/sign`)
    navigate(`/template/${processInstanceId}/sign`)


  }

  const handleSignForOthers = () => {
    navigate(`/template/${processInstanceId}/signForOthers`)
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "50px", gap: "60px" }}>
      <Typography variant='h3' >E-Sign</Typography>
      <Grid spacing={2} sx={{ display: "flex", justifyContent: "center", height: "80vh", gap: "100px" }}>
        <StyledCard sx={{ py: 3, width: "300px", height: "300px" }} onClick={handlesignyourself}>
          <Grid item xs={12} md={6}>
            <Box p={2}>

              <img src={signyourself} style={{ height: "250px", width: "250px" }} alt='signYourSelf' />
              <Typography variant="h6" mt={1} color="primary" textOverflow='ellipsis' overflow='hidden' sx={{ display: "flex", justifyContent: "center", fontSize: "20px" }}>
                Sign Yourself
              </Typography>
            </Box>
          </Grid>
        </StyledCard>
        <StyledCard sx={{ py: 3, width: "300px", height: "300px" }} onClick={handleSignForOthers}>

          <Grid item xs={12} md={6}>
            <Box p={2}>

              <img src={sendforothers} style={{ height: "250px", width: "250px" }} alt='signForOthers' />

              <Typography variant="h6" color="primary" mt={1} textOverflow='ellipsis' overflow='hidden' sx={{ display: "flex", justifyContent: "center", fontSize: "20px" }}>
                Send For Others
              </Typography>
            </Box>
          </Grid>
        </StyledCard>

      </Grid>

    </Box>
  );
};

export default Signdashboard;
