import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { handleApply, handleFilters, handleSelected, resetFilters } from "redux/reducers/taskboardSlice";
import { getTitle } from "utils";
import { colors } from "views/tasks/board/utils";
import { StyledTaskBox } from 'views/dashboard/OrgDashboard/styles';
import { getDocumentStatus } from "api/services/quantum/home";
import { ResType } from "types";
import { useQuery } from "react-query";
import { useState } from "react";

function DocumentByStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [docStatus, setDocStatus] = useState<any>([])


  const { data, isLoading }: ResType = useQuery(

    ["get-docment-status", {
    }],
    getDocumentStatus,
    {
      onSuccess: (res) => {
        setDocStatus(res?.data?.documentStatus)
      },
    }
  );

  const result = [
    {
      name: "IN PROGRESS",
      value: docStatus?.in_progress,
      color: "#FB0505",
      key: "in_progress",
    },
    {
      name: "UNDER REVIEW",
      value: docStatus?.under_review,
      color: "#019335",
      key: "under_review",
    },
    {
      name: "COMPLETED",
      value: docStatus?.completed,
      color: "#f17f23",
      key: "completed",
    },
  ];


  const handleClick = (v: any) => {
    dispatch(resetFilters());
    dispatch(handleSelected("status"));
    dispatch(
      handleFilters({
        checked: true,
        value: { label: getTitle(v.key), value: v.key },
      })
    );
    dispatch(handleApply());
    // navigate("/task-board");
  };

  return (
    <StyledTaskBox width={"400px"} margin={"30px"}>
      <header>
        <Typography variant="h6">Document by Status</Typography>
      </header>
      <Box display={"flex"}>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} textAlign={"center"}>
          <Typography width={"100px"} height={"50px"} color="primary" variant="h3">
            {docStatus?.total}
          </Typography>
        </Box>

        <main>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={result} layout="vertical" style={{ padding: 0, fontSize: "8px" }}>
              <Bar
                dataKey="value"
                barSize={8}
                radius={[0, 4, 4, 0]}
                style={{ cursor: "pointer" }}
                onClick={(v) => handleClick(v)}
              >
                {result?.map((entry: any, index: number) => (
                  <Cell key={index} fill={result[index].color} />
                ))}
              </Bar>
              <YAxis type="category" dataKey="name" />
              <Tooltip
                labelStyle={{ color: "#000", fontWeight: "bold", fontSize: 13 }}
                cursor={{ fill: "transparent" }}
              />
              <XAxis type="number" domain={[0, "dataMax + 10"]} />
            </BarChart>
          </ResponsiveContainer>
        </main>

      </Box>


      <footer>
        <Typography variant="body2" color="secondary">
          View Documents
        </Typography>
        <IconButton color="secondary" size="small" onClick={() => navigate("/alldocuments")}>
          <ArrowForwardIcon fontSize="small" />
        </IconButton>
      </footer>
    </StyledTaskBox>
  );
}

export default DocumentByStatus;
