import { Box, Grid } from "@mui/material";
import RouterLink from "components/RouterLink";
import { StyledProfileNav, StyledProfileNavItem } from "views/clients/styles";
import { quantumDashboardMenu } from "data/constants";
import {  Outlet, useLocation, useParams } from "react-router-dom";
import Nav from "./nav";

const DashboardLayout = () => {
    const params = useParams();
  const location = useLocation();
  return (

    <Grid container position={"fixed"} direction="column" >
    <Grid item>
      <Box ml={2}>
      </Box>
    </Grid>
    <Grid item>
      <Nav />
    </Grid>
    <Grid item sx={{ flex: 1 }}>
      <Outlet />
    </Grid>
  </Grid>
    // <Box>
    //   {"Home/Analytics"}
    //   <StyledProfileNav>
    //     {quantumDashboardMenu.map((item, index) => (
    //       <RouterLink to={`dashboard/${item.path}`} key={index}>
    //         <StyledProfileNavItem active={location.pathname.includes(item.path) ? 1 : 0}>
    //           {item.title}
    //         </StyledProfileNavItem>
    //       </RouterLink>
    //     ))}
    //   </StyledProfileNav>
    //   <Outlet
    //    />

    // </Box>
  );
};

export default DashboardLayout;
