import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { useClientData } from "context/ClientData";
import { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import QuantumClientBasicInformation from "./QuantumClientBasicInformation";
import PrefillSectionCreation from "./Prefill/PrefillSectionCreation";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import { useMutation, useQuery } from "react-query";
import { getClientPrefill, updateClientPrefill } from "api/services/quantum/prefill";
import { snack } from "components/toast";
import { cloneDeep } from "lodash";
import BottomBar from "components/BottomBar";
import { useConfirm } from "context/ConfirmDialog";
import CustomTooltip from "components/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';

function Prefill() {
  const [state, setState] = useState<any>({});
  const { data } = useClientData();
  const [open, setOpen] = useState(false)
  const { clientId } = useParams();
  const [prefill, setPrefill] = useState<any>("")
  const [prefillData, setPrefillData] = useState<any>("")
  const [formData, setFormData] = useState(prefillData);
  const confirm = useConfirm();

  useEffect(() => {
    setState(data?.data);
    setFormData(prefillData)
  }, [data, prefillData]);


  const { data: data1, isLoading }: ResType = useQuery(
    ["get-client-prefill", clientId],
    getClientPrefill, {
    onSuccess: (res: any) => {
      setPrefillData(res?.data?.prefill)
      setPrefill(cloneDeep(res?.data?.prefill));
    }
  }
  );

  const { mutate } = useMutation(updateClientPrefill, {
    onSuccess: (res) => {
      snack.success("Section Prefill Updated");
      setPrefillData(res?.data?.prefill)
      setPrefill(cloneDeep(res?.data?.prefill));
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });

  const handleSectionDelete = (sectionIndex) => {
    confirm({
      msg: "Are you sure you want to delete this section?",
      action: () => {
        // User confirmed, proceed with deletion
        const updatedFormData = [...formData];
        updatedFormData.splice(sectionIndex, 1);
        setFormData(updatedFormData);


        ///
        mutate({
          id: clientId,
          data: updatedFormData
        })

      },
    });
  };


  const handleInputChange = (sectionIndex, subjectIndex, value) => {
    const updatedFormData = [...formData];
    updatedFormData[sectionIndex].subjects[subjectIndex].value = value;
    setFormData(updatedFormData);
  };
  const handleSaveChanges = async () => {
    // Perform the save operation for the changed data
    mutate({
      id: clientId,
      data: formData
    })
  };

  if (data?.data !== "") {
    return (
      <Box px={4} pt={2} pb={10}>

        <QuantumClientBasicInformation data={state} setState={setState} />
        {formData &&
          formData.map((section, sectionIndex) => (
            <Box key={sectionIndex} >
              <Box mt={2.5} border="1px solid #d1d1e0" p={2} borderRadius="10px">
                <Box display={"flex"} m={1} justifyContent={"space-between"}>
                  <Typography color="primary" variant="h6" sx={{ mb: 2 }}>{section.title}</Typography>
                  <Button
                    // variant="outlined"
                    color="error"
                    size="small"
                    // sx={{ height: '30px' }}
                    onClick={() => handleSectionDelete(sectionIndex)}
                  >
                    <CustomTooltip title="Delete Section"><DeleteIcon /></CustomTooltip>
                  </Button>
                </Box>
                <Grid container spacing={3}>
                  {section.subjects.map((subject, subjectIndex) => (


                    <Grid key={subjectIndex} item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        label={subject.subjectName}
                        type={subject.inputType}
                        // placeholder={`Add ${subject.subjectName}`}
                        value={subject?.value || ""}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          handleInputChange(sectionIndex, subjectIndex, e.target.value);
                        }}
                      />
                    </Grid>

                  ))}
                </Grid>
              </Box>

            </Box>
          ))}
        <Box mt={10}>
          <Button variant="outlined" color="error" sx={{ justifyContent: "center", alignItems: "center", marginTop: "20px", marginBottom: "20px" }} onClick={() => setOpen(true)}>
            Add Custom Prefill
          </Button>
        </Box>

        <PrefillSectionCreation open={open} setOpen={setOpen} clientId={clientId} />
        <BottomBar
          data={prefill}
          state={formData}
          onCancel={() => setFormData(prefill)}
          onUpdate={handleSaveChanges}
        />
      </Box>
    );
  } else {
    return null
  }
}

export default Prefill;
