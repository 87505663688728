import React, { useEffect, useState } from "react";
import { AvatarProps, Box, Button, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { getUsers } from "api/services/users";
import { ResType } from "types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import UserCard from "./userCard";
import Members from "components/Members";
import { addUsersToProcess, getCamundaUsers, getUsersToProcess } from "api/services/quantum/template";
import { snack } from "components/toast";
import { useParams } from "react-router-dom";
import { includes } from "lodash";

type userDetail = {
  id: number;
  createdAt: string;
  email: string;
  fullName: string;
  image: string;
  imageUrl: string;
  isSuperUser: boolean;
  lastLogin: string;
  mobileNumber: string;
  organization: {
    id: number;
    category: string;
    legalName: string;
    tradeName: string;
    placeOfSupply: string;
  };
  password: string;
  role: {
    id: number;
    name: string;
    description: string;
    createdAt: string;
    active: boolean;
  };
  status: string;
  type: string;
  updatedAt: string;
};

const AddUserForm = () => {
  const userId = localStorage.getItem("userId") || "";

  const [usersList, setUsersList] = useState<any[]>([]);
  const [usersListDeatils, setUsersList1]: any = useState([]);
  const [usersListDetails, setUsersListDetails] = useState<any[]>([]);
  const loggedInUserId = userId; // Replace with the actual ID of the logged-in user

  const queryClient = useQueryClient();
  const processId1 = useParams()?.id;
  console.log(".............................", processId1);
  const [invitedUsers, setInvitedUsers] = useState<string[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const { data: userNames, isLoading }: ResType = useQuery(["users", processId1], getCamundaUsers, {
    onSuccess(res1: any) {
      const remainingUsers = res1?.data?.filter(
        (user) => !invitedUsers.includes(user.id.toString())
      );
      setUsersListDetails(remainingUsers);
    },
  });
  const { data: users, isLoading: loader }: ResType = useQuery(
    ["get-users", processId1],
    getUsersToProcess,
    {
      onSuccess(res: any) {
        setUsersList(res?.data);
        console.log(res?.data, "user data");
      },
    }
  );


  interface Props extends AvatarProps {
    title: string;
    src?: string;
    size: "small" | "medium";
  }
  const selectedUser = userNames?.data?.find((user) => user.id === selectedUserId);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUserId(e.target.value !== "" ? parseInt(e.target.value) : null);
  };

  const { mutate } = useMutation(addUsersToProcess, {
    onSuccess: (res) => {
      snack.success("User has been added to the document Successfully.");
      queryClient.invalidateQueries("get-users");
      setUsersListDetails((prevRemainingUsers) =>
        prevRemainingUsers.filter((user) => user.id !== selectedUserId)
      );
      setSelectedUserId(null);

    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });
  const handleInvite = () => {
    if (selectedUserId !== null && !invitedUsers.includes(selectedUserId.toString())) {
      setInvitedUsers((prevInvitedUsers) => [...prevInvitedUsers, selectedUserId.toString()]);
      mutate({ processId1, selectedUserId });
      setSelectedUserId(null);
    }
  };
  const filteredUsersListDetails = usersListDetails.filter(
    (item) => item.id !== loggedInUserId
  );
  return (
    <Box>
      <Typography color={"primary"} variant="h6" p={1}>Add Users</Typography>
      <TextField
        label="Name"
        variant="outlined"
        value={selectedUserId || ""}
        onChange={handleInputChange}
        size="small"
        fullWidth
        select
        sx={{ mb: 2 }}
      >

        {filteredUsersListDetails.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.fullName}
          </MenuItem>
        ))}
      </TextField>
      <Box textAlign={"center"} >
        <Button onClick={handleInvite} variant="contained" size="small" sx={{ backgroundColor: "#CAE8FF", color: "#2EA2FF" }} >
          Add User
        </Button>
      </Box>



      <div>
        <Typography variant="h6" sx={{ color: "#787675" }} m={2}>Existing Users in the Document</Typography>
        {/* <div style={{ margin: "20px", color: "grey" }}>Existing Users in the Document</div> */}
        <div>
          <Box height={"55vh"} overflow={"auto"}>
            {usersList && usersList.length && (
              <div>
                {usersList.map((item: any) => (
                  <UserCard
                    userData={item}
                    users={usersList}
                    key={item.id}
                  />
                ))}
              </div>
            )}
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default AddUserForm;
