import React from 'react';
import { Box, Button, Dialog, DialogTitle, Paper, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ResType } from "types";
import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { getClientsQuantum } from 'api/services/quantum/document';
import { snack } from 'components/toast';
import SearchContainer from 'components/SearchContainer';
import { getClientPrefillData } from 'api/services/quantum/prefill';

const ClientPrefillSelection = (props) => {
  const { isOpen, onClose } = props;
  const [prefillClient, setPrefillClient] = useState<any>("")
  const [filters, setFilters] = useState({
    category: [],
    subCategory: [],
    monthAdded: "",
    labels: [],
    search: "",
  });
  const [selectedClient, setSelectedClient] = useState<any>("")
  const [clients1, setClients] = useState<any[]>([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  const { data: clients }: ResType = useQuery(["clients", {
    // limit: pageCount,
    // offset: page * pageCount,
    query: {
      ...filters,
      category: filters.category.map((c: any) => c?.value),
      subCategory: filters.subCategory.map((c: any) => c?.value),
      labels: filters.labels.map((c: any) => c?.id),
    },
  },], getClientsQuantum,
    {
      onSuccess: (res: any) => {
        const result = partition(res?.data?.result, (el: any) => el.status === 'INACTIVE')
        setClients(result);
      },
      onError: (err: any) => {
        if (!err?.error) {
          snack.error('An error occurred while fetching client-data');
        }
      },

    });


  const { mutate } = useMutation(getClientPrefillData, {
    onSuccess: (res: any) => {
      setPrefillClient(res?.data?.prefill)
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });
  const partition = (arr: any, condition: any) => {
    const actives = arr.filter((el: any) => condition(el));
    const inactives = arr.filter((el: any) => !condition(el));
    return [...inactives, ...actives];
  };

  const handleClose = async () => {
    onClose("");
    setSelectedClient("")
  };

  const handlePropertyClick = (propertyValue) => {
    onClose(propertyValue); // Pass the property value back to the main function
    setSelectedClient("")
  };
  const handleClientItemClick = (client) => {

    if (client) {
      mutate({
        id: client?.id
      })
      setSelectedClient(client);
    }
  };

  function convertToDisplayName(input) {
    // Split the input string into words using a regular expression
    const words = input.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join them with a space
    const displayName = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return displayName;
  }

  return (
    <Box borderRadius={"15px"}>
      <Dialog open={isOpen} sx={{ color: "#EDF4FF", borderRadius: "30px", border: "2px solid #007BFF", boxShadow: "0px 0px 5px 2px #007BFF" }}>
        <Box
          sx={{
            display: "flex",
            boxShadow: "none",
            borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
            p: "0.3rem 1rem",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#204060",
            height: "45px"
          }}
        >
          <DialogTitle color="#ffffff">Prefill Selection</DialogTitle>
          <ClearIcon onClick={handleClose} sx={{ color: "#ffffff" }} />
        </Box>

        {!selectedClient && (
          <Box sx={{ width: '500px', height: '500px' }}>
            <Box mt={1} mx={3}>
              <SearchContainer
                value={filters.search}
                debounced
                minWidth="450px"
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    search: v,
                  });
                }}
                placeHolder="Search by Display Name"
              />
            </Box>

            <Box height={300} mt={3} ml={3} mr={3} overflow={"auto"} sx={{
              overflowY: "auto",
              overflowX: "hidden",
            }}>
              {clients1.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    // width: "350px",
                    // borderBottom: "0.2px solid #182F53",
                    backgroundColor: hoveredItem === item.id ? "#E9E9E9" : "inherit", // Add background color on hover
                  }}
                  onMouseOver={() => setHoveredItem(item.id)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <Typography
                    key={item.id}
                    variant='subtitle1'
                    color="primary"
                    onClick={() => handleClientItemClick(item)}
                    style={{ cursor: 'pointer', fontSize: "17px", padding: "5px", }}
                  >
                    {item.displayName}
                  </Typography>
                </Box>
              ))}
            </Box>

          </Box>
        )}


        {selectedClient && selectedClient && (

          <Box sx={{ width: '550px', height: '550px' }}>
            <Box overflow={"auto"} m={1}>

              <Box display={"flex"} m={1} justifyContent={"space-between"}>
                <Typography color="primary" variant="h6" sx={{ mb: 2 }}>Client Basic Details</Typography>
              </Box>
              {Object.keys(selectedClient).map((property, index) => (

                selectedClient[property] && property !== "id" && (
                  <Box key={index} >
                    <Paper elevation={1} sx={{ borderRadius: 3, margin: "5px", marginBottom: "10px", padding: 2 }}>
                      <Box >
                        <Box display={"flex"} justifyContent={"space-between"}>

                          <Typography p={1} fontSize={"17px"} fontWeight={600}>{convertToDisplayName(property) || ""}</Typography>
                          <Button
                            variant="outlined"
                            onClick={() => handlePropertyClick(selectedClient[property])}
                            size="small"
                            color='error'
                            sx={{ height: '30px', margin: "5px" }}
                          >
                            Prefill
                          </Button>
                        </Box>
                        <Typography p={1}>{selectedClient[property]}</Typography>
                      </Box>
                    </Paper>
                  </Box>
                )

              ))}
              {prefillClient && prefillClient !== null && (
                <Box>         {prefillClient &&
                  prefillClient.map((section, sectionIndex) => (
                    <Box key={sectionIndex}>
                      <Box mt={2} >
                        <Box display={"flex"} m={1} justifyContent={"space-between"}>
                          <Typography color="primary" variant="h6" sx={{ mb: 2 }}>{section.title}</Typography>

                        </Box>
                        {section.subjects.map((subject: any, subjectIndex) => (
                          <Box>
                            {subject && subject.value !== "" && (
                              <Paper elevation={3} sx={{ borderRadius: 3, margin: "5px", marginBottom: "10px", padding: 2 }}>
                                <Box>
                                  <Box display={"flex"} justifyContent={"space-between"}>

                                    <Typography p={1} fontSize={"17px"} fontWeight={600}>{subject.subjectName}</Typography>
                                    <Button
                                      variant="outlined"
                                      onClick={() => handlePropertyClick(subject.value)}
                                      size="small"
                                      color='error'

                                      sx={{ height: '30px', margin: "5px" }}
                                    >
                                      Prefill
                                    </Button>
                                  </Box>
                                  <Typography p={1}>{subject.value}</Typography>
                                </Box>
                              </Paper>
                            )}
                          </Box>
                        ))}
                      </Box>

                    </Box>
                  ))}
                </Box>

              )}
            </Box>
          </Box>
        )}

      </Dialog>
    </Box>
  );
};

export default ClientPrefillSelection;
