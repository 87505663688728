import { Box, Breadcrumbs, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import Members from 'components/Members';
import React, { useState } from 'react'
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import Table, { ColumnType } from "components/Table";
import { getTitle } from 'utils';
import { deleteDocument } from 'api/services/quantum/template';
import { snack } from 'components/toast';
import { useConfirm } from 'context/ConfirmDialog';
import moment from 'moment';
import CircularProgressBar from 'quantumpages/documents/CircularProgressBar';
import SearchContainer from 'components/SearchContainer';
import { LinkRouter } from 'components/BreadCrumbs';
import { Delete, Visibility } from '@mui/icons-material';


const menu = [
  { label: "None", value: "" },
  { label: "IN PROGRESS", value: 'in_progress' },
  { label: "UNDER REVIEW", value: "under_review" },
  { label: "COMPLETED", value: "completed" }
];
const ViewDocuments = () => {
  const confirm = useConfirm();
  const location = useLocation();
  const passedData: any = location.state
  const [categoriesData, setCategoriesData] = useState(passedData?.data)
  const [selectId, setSelectId] = useState('')
  const navigate = useNavigate()
  const [filterBy, setFilterBy] = useState("");

  const { mutate } = useMutation(deleteDocument, {
    onSuccess: (res: any) => {
      snack.success("Document Deleted successfully");
      const filteredItems = categoriesData.filter(item => item?.processInstanceId !== selectId);
      setCategoriesData(filteredItems)
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  })
  const handleDocumentDelete = (id) => {
    setSelectId(id)
    confirm({
      msg: "Are you sure you want to Delete this Document?",
      action: () => mutate(id),
    });


  }
  const columns: Array<ColumnType> = [
    { key: "id", title: "Document Id" },
    { key: "templateName", title: "Document Name" },
    {
      key: "percentage", title: "Percentage of completion",
      render: (row: any) => { return <CircularProgressBar percentage={row.percentage} color='blue' /> }
    },

    {
      key: "documentStatus",
      title: "document status",
      render: (row: any) => (
        <Typography variant="body2" color="black">
          {getTitle(row.documentStatus)}
        </Typography>),


    },
    {
      key: "lastUpdated",
      title: "Last updated",
      render: (row: any) => moment(row?.lastUpdated).format("DD MMM YYYY"),
    },
    {
      key: "Memberss",
      title: "Members",
      render: (v) => (
        <Members
          data={v?.assignedUsers?.map((item: any) => ({
            title: item?.fullName,
            src: item?.imageUrl,
          }))}
        />
      ),
    },
    {
      key: "Action",
      title: "Action",

      render: (item: any) => {
        return (
          <Box display="flex" gap={1}>
            <IconButton
              size="small"
              onClick={() => {
                navigate(`/template/${item?.processInstanceId}/build`);
              }}
            >
              <Visibility />
            </IconButton>
            <IconButton size="small" onClick={() => handleDocumentDelete(item?.processInstanceId)}>
              <Delete />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  // Step 2: Modify handleChange function
  const handleChange = (v) => {
    setSearchQuery(v);
  };

  // Step 3: Filter data based on search query
  const filteredData = categoriesData.filter((item) =>
  (
    item?.templateName?.toLowerCase().includes(searchQuery.toLowerCase())
    ||
    (typeof item?.id === 'number' && item?.id.toString().toLowerCase().includes(searchQuery.toLowerCase()))
  )
  );

  const handleClickFilter = (filterType: any, value) => {

    setFilterBy(filterType);
    let filteredData = [];

    if (value === "") {
      // If filter is "None", include all data
      filteredData = passedData?.data;
    } else {
      filteredData = passedData?.data?.filter((item) => item?.documentStatus === value);
    }
    setCategoriesData(filteredData);
  };


  return (
    <Box sx={{ margin: "20px" }}>


      <Breadcrumbs separator="›">
        <LinkRouter
          underline="hover"
          color="inherit"
          to={`/dashboard/dashboard-documents`}
        >
          All Documents
        </LinkRouter>
        <Typography color={"#1E1E1E"} fontStyle={"Robato"} variant="subtitle2">
          {passedData?.category}
        </Typography>
      </Breadcrumbs>
      <Box display="flex" flex={1} gap={2} alignItems="center" margin={"20px"}>
        <SearchContainer
          debounced
          minWidth="400px"
          onChange={(v) => handleChange(v)}
          placeHolder="Search by document Name or Id"

        />
        <TextField
          sx={{ minWidth: 200, ml: "10px" }}
          select
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
          size="small"
          variant="outlined"
          label="Filter By"
        >
          {menu?.map((item, index) => (
            <MenuItem sx={{ borderRadius: "5px" }} key={item?.label} value={item?.value} onClick={() => handleClickFilter(item?.label, item?.value)}>
              {item?.label}
            </MenuItem>
          ))}

        </TextField>

      </Box>
      <Box width={"95%"}>
        <Table
          data={filteredData || []}
          columns={columns || []}
        />
      </Box>
    </Box>
  )
}

export default ViewDocuments