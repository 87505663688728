import { http } from "api/http";

const getDocumentActivityData = ({ queryKey }) => {
  return http.get("/document/get-documents", { params: { ...queryKey[1] } });
};

const getDocumentDeleteStatus = () => {
  return http.get("/document/get-delete-documents");
};

const getCompletedDocuments = () => {
  return http.get("/document/get-completed-documents");
};

const restoreDocument = (id: number) => {
  return http.post(`/document/${id}/restore`);
};

const getClientsQuantum = ({ queryKey }: any) => {
  let { limit, offset, query } = queryKey[1] || {};
  return http.get("/document/clients", {
    params: {
      limit,
      offset,
      ...query,
    },
  });
};

// async contract-data-remander

const contractDataRemander = (data) => {
  return http.post(`/document/contract-data-remander`, data);
};

const getContractDate = ({ queryKey }) => {
  return http.get(`document/get-contract-date/${queryKey[1]}`);
};

export {
  getDocumentActivityData,
  getDocumentDeleteStatus,
  restoreDocument,
  getClientsQuantum,
  contractDataRemander,
  getContractDate,
  getCompletedDocuments,
};
