import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import GridViewIcon from "@mui/icons-material/GridView";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { ViewType } from "types";
import CustomTooltip from "./Tooltip";

interface IProps<Type> {
  value: Type;
  onChange: (value: Type) => void;
}

function View({ value, onChange }: IProps<ViewType>) {
  return (
    <Box display="flex" gap={1} justifyContent="flex-end">
      <Button
        size="small"
        onClick={() => onChange("grid")}
        variant={value === "grid" ? "contained" : "text"}
        sx={{ minWidth: 0, px: 1 }}
      >
         <CustomTooltip title ="Grid View"><GridViewIcon fontSize="small" /></CustomTooltip>
      </Button>
      <Button
        onClick={() => onChange("list")}
        variant={value === "list" ? "contained" : "text"}
        sx={{ minWidth: 0, px: 1 }}
      >
        <CustomTooltip title ="List View"><FormatListBulletedRoundedIcon fontSize="small" /></CustomTooltip> 
      </Button>
    </Box>
  );
}

export default View;
