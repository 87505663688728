import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { downloadSignedDocument, getPreviewTemplate, uploadHtmlContentsigned, uploadSignedDocument } from "api/services/quantum/template";
import { useParams } from "react-router";
import html2canvas from 'html2canvas';
import { snack } from "components/toast";
import jsPDF from "jspdf";
import Loader from "components/Loader";
import { getSignForOthersData } from "api/services/quantum/download";
import { ResType } from "types";

const SignDownloadPage = () => {
    const [imageSrc, setImageSrc] = useState("");
    const htmlContentRef = useRef(null);
    const [htmlContent, setHtmlContent] = useState("");
    const [pdfdownloading, setPdfdownloading] = useState(false);

    const processInstanceId: any = useParams().id;

    const { data, isLoading }: ResType = useQuery(["Sign-For-Others", processInstanceId], getSignForOthersData, {
        onSuccess(res1: any) {
            console.log({ res1 })
            setHtmlContent(res1?.data?.esignHtml)
        },
    });

    const handleContextMenu = (e) => {
        e.preventDefault(); // Prevent the default right-click context menu
    };

    useEffect(() => {
        if (htmlContent) {
            console.log('htmlContent loaded');
        }
    }, [htmlContent]);

    // Function to convert HTML content to image
    const convertHtmlToImage = () => {
        setPdfdownloading(true);
        // Get the HTML content
        const htmlContentElement: any = htmlContentRef.current;

        const hideondownload = document.querySelectorAll(".hideondownload") as any;
        if (hideondownload.length > 0) {
            // Loop through each matching element
            hideondownload.forEach((hideonpreview: any, idx: any) => {
                if (hideonpreview) {
                    // Set the display style of each element to "none"
                    hideonpreview.style.display = "none";
                }
            });
        }

        setTimeout(() => {
            const divToPrint = document.getElementById("dragContainer") as any;
            // Use html2canvas to convert HTML content to an image
            html2canvas(divToPrint, { allowTaint: true, scale: 2 }).then(canvas => {
                canvas.getContext('2d');
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 190;
                const pageHeight = 290;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let doc = new jsPDF('p', 'mm');
                let position = 0;
                doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                    heightLeft -= pageHeight;
                }

                doc = addWaterMark(doc);
                // doc = addHeaderandFooter(doc);

                mutateuploadHtmlContent({ processInstanceId, content: divToPrint.innerHTML });

                doc.save('download.pdf');



                setPdfdownloading(false);
            }).catch(error => {
                console.error("Error converting HTML to image:", error);
            });
        }, 2000);
    };

    const { mutate: mutateuploadHtmlContent } = useMutation(uploadHtmlContentsigned, {
        onSuccess: (res) => {
            console.log(res);
            snack.success("File uploaded successfully");
        },
        onError: (error: any) => {
            console.error("Error uploading file:", error);
            snack.error("Error uploading file");
        },
    });

    const addWaterMark = (doc: any) => {
        const totalPages = doc.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            doc.setTextColor(150);
            doc.setFontSize(80);

            doc.saveGraphicsState();
            doc.setGState(new doc.GState({ opacity: 0.2 }));
            doc.text('', (doc.internal.pageSize.width / 2) - 50, (doc.internal.pageSize.height / 2) + 50, { angle: 45 });
            doc.restoreGraphicsState();
        }

        return doc;
    }

    const addHeaderandFooter = (doc: any) => {
        const totalPages = doc.internal.getNumberOfPages();

        doc.setFont('helvetica', 'italic')
        doc.setFontSize(8)
        for (var i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            const pageSize = doc.internal.pageSize;
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            const header = 'Report 2014';
            const footer = `Page ${i} of ${totalPages}`;

            // Header
            doc.text('Logo', 40, 15, { baseline: 'top' });

            // Footer
            doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });

            // doc.text('Page ' + String(i) + ' of ' + String(totalPages), doc.internal.pageSize.width / 2, 287, {
            //   align: 'center'
            // })
        }

        return doc;
    }

    const drag_start = (event: any) => {
        var style = window.getComputedStyle(event.target, null);
        var str =
            parseInt(style.getPropertyValue("left")) -
            event.clientX +
            "," +
            (parseInt(style.getPropertyValue("top")) - event.clientY) +
            "," +
            event.target.id;
        event.dataTransfer.setData("Text", str);
    };

    const drop = (event: any) => {
        var offset = event.dataTransfer.getData("Text").split(",");
        var dm = document.getElementById(offset[2]) as any;
        dm.style.left = event.clientX + parseInt(offset[0], 10) + "px";
        dm.style.top = event.clientY + parseInt(offset[1], 10) + "px";

        event.preventDefault();
        return false;
    };

    const drag_over = (event: any) => {
        event.preventDefault();
        event.dataTransfer.setData("Text/html", event.target.id);
        return false;
    };

    const handleChangeImage = (e: any) => {
        var preview = document.getElementById("preview" + e.target.id) as any;
        preview.setAttribute("src", URL.createObjectURL(e.target.files[0]));
    };

    let signatureCounter = 1;


    return (
        <Box display={"flex"} >

            <Box id="download-file" width={"100%"} bgcolor={"#f2f2f2"} style={{ display: "flex", justifyContent: "center", maxHeight: "90%" }}>

                <Box mt={10}
                    padding={5}
                    margin={2}
                    bgcolor={"#ffffff"}
                    style={{
                        overflowY: "auto",
                        overflowX: "auto", height: "75vh", width: "90%", userSelect: "none"
                    }}
                    onContextMenu={handleContextMenu}
                    sx={{
                        maxWidth: "900px",
                        wordWrap: "break-word"
                    }}
                >

                    <div
                        id="dragContainer"
                        className="dragcontainer"
                        style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
                        onDragOver={(e: any) => drag_over(e)}
                        onDrop={(e: any) => drop(e)}
                    >
                        <div>
                            {isLoading && <p>Loading...</p>}

                            {htmlContent && !imageSrc && (
                                <div ref={htmlContentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
                            )}

                        </div>

                        {htmlContent &&
                            <div
                                className="dragbox"
                                id="signatureDragBox"
                                draggable="true"
                                onDragStart={(e) => drag_start(e)}
                                style={{ display: "none" }}
                            >
                                <img src="" id="preview" className="previewesign" />
                                <div id="hideonpreview" style={{ backgroundColor: "#182f53", color: "white", border: "none", padding: "5px" }}>
                                    Signature
                                    <div id="uploadEsign" style={{ display: "block" }}>
                                        <form action="/upload" method="post" encType="multipart/form-data">
                                            <input
                                                type="file"
                                                id="eSignUpload"
                                                name="eSignUpload"
                                                accept=".jpg, .jpeg, .png"
                                                placeholder="Select an e-signature image (200x200)"
                                                onChange={handleChangeImage}
                                            />
                                        </form>
                                        <p className="signnote">*Please upload format JPG/PNG image of 100px size</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Box>
                <Box width={"15%"} bgcolor={"#f2f2f2"} style={{ textAlign: 'right' }} >
                    {htmlContent && (
                        <>
                            {!pdfdownloading ? <Button size="large" color="secondary" variant="contained" onClick={convertHtmlToImage}
                                style={{ marginTop: '10px' }}>
                                Download
                            </Button> : <Button size="large" color="secondary" variant="contained"
                                style={{ marginTop: '10px' }}>
                                Downloading
                            </Button>}

                           
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default SignDownloadPage;
