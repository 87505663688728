import progress from "../../../assets/images/viderwhitelogo.png"
import { Box } from "@mui/material";

const PercentageBar = ({ dataObject }) => {

  return (
    <Box>
      <Box
        style={{
          width: "100%", 
          backgroundColor: "#f1f1f1",
          borderRadius: "10px",
          marginTop: "10px",
          position: "relative", // Set the parent container to relative positioning
        }}
      >
        <Box
          style={{
            width: `${dataObject}%`,
            backgroundColor: "#2A4B7E",
            paddingLeft: "20px",
            height: "10px",
            position: "relative",
            zIndex: 0, // Set z-index to 0 for the progress bar
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <img
            src={progress}
            alt="Progress"
            style={{
              width: "40px",
              height: "30px", // Set the height of the image to be greater than the div
              position: "absolute",
              top: "-10px", // Adjust the positioning to make the image appear above the progress bar
              right: "-15px",
              transition: "left 0.2s ease-out",
              backgroundColor: "#2A4B7E",
              padding: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "50px",
              zIndex: 1, // Set a higher value for z-index to show the image above the progress bar
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PercentageBar;
