import { Box, Button, Grid, ListItemIcon, Menu, MenuItem, Paper, TextField, Typography } from "@mui/material"
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PrinterImg from "../../../assets/images/Printer.svg";
import DownloadImg from "../../../assets/images/download.svg";
import MessageImg from "../../../assets/images/message.svg";
import WhatsUpImg from "../../../assets/images/printer_839184 copy 1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { DialogProps, ResType } from "types";
import { useMutation, useQuery } from "react-query";
import { downloadTemplate, getDocmentDetails, sendPushNotification, updateSingelProcessDetails } from "api/services/quantum/template";
import { useEffect, useState } from "react";
import Loader from "components/Loader";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { snack } from "components/toast";
import React from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';
import ClearIcon from '@mui/icons-material/Clear';
import { DownloadEmail, downloadWithOrgHeaderAndFooter, getDocumentIdToCamunda, getSignDocumentData, getSignForOthersData } from "api/services/quantum/download";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DialogWrapper from "components/DialogWrapper";
import { getOrganization } from "api/services/organization";
interface Props extends DialogProps {
    data: any;
}
const DownloadPage = () => {
    const [docmentDetails, setDocumentDetails]: any = useState({});
    const params = useParams().id;
    const processInstanceId = params;
    const [rename, setRename] = useState(docmentDetails?.templateName);
    const [name, setName] = useState(rename)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [clickedButtonId, setClickedButtonId] = useState(null);
    const [textFieldValue, setTextFieldValue] = useState('');
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElUser1, setAnchorElUser1] = React.useState<null | HTMLElement>(null);
    const [anchorElUser2, setAnchorElUser2] = React.useState<null | HTMLElement>(null);
    const [isPrintClick, setIsPrintClick] = useState(false);
    const [isDownloadPdf, setIsDownloadPdf] = useState(false);
    const [isDownloadWord, setIsDownloadWord] = useState(false);
    const [signData, setSignData] = useState("")
    const [previewOpen, setPreviewOpen] = useState(false);
    const [orgHeader,setOrgHeader] = useState("");
    const [orgFooter,setOrgFooter] = useState("");


    const userId = localStorage.getItem("userId") || "";

    const { data, isLoading }: ResType = useQuery(
        ['get docment name', processInstanceId],
        getDocmentDetails,
        {
            onSuccess: (data1: any) => {
                setDocumentDetails(data1.data[0]);
                setName(data1.data[0].templateName)
            }
        }
    );


    const { mutate: sendPushNotifications } = useMutation(sendPushNotification, {
        onSuccess: (data2: any) => {


        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    })

    const Print = (htmlContent) => {
        var mywindow: any = window.open('', 'my div', 'height=400,width=600');
        if (!mywindow) {
            alert('Popup blocker may be preventing the print window from opening.');
            return false;
        }
        mywindow.document.write('<html><head><title>download</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(htmlContent);
        mywindow.document.write('</body></html>');

        mywindow.print();
        mywindow.close();

        return true;
    };


    const { mutate: tempalateDownload } = useMutation(downloadTemplate, {
        onSuccess: (res: any) => {
            if (isPrintClick) {
                Print(res?.data);
                setIsPrintClick(false);
            } else if (isDownloadWord) {
                setIsDownloadWord(false)
            };
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });


    const handleOpen = () => {
        setOpen(true);
    };


    const handleTemplateNameChange = () => {
        if (name.length > 3) {
            mutate({
                id: processInstanceId,
                data: name,
            });
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setName(rename)
    };

    const { mutate } = useMutation(updateSingelProcessDetails, {
        onSuccess: (data2: any) => {

            setRename(data2?.data.templateName)
            setDocumentDetails(data2?.data)
            setOpen(false);
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });

    const handleonClickEdit = () => {
        if (docmentDetails?.documentStatus === "completed" && docmentDetails?.esignHtml !== null) {
            snack.error("As your document is already completed and signed you are not allowed to go to drafting page")
        } else {
            navigate(`/template/${processInstanceId}/build`);
        }
    };

    const handlePreview = () => {
        console.log('preview')
        setPreviewOpen(true)
    }

    const handleonClickDelete = () => {

    };

    const handleChange = (e) => {

        setName(e.target.value)

    };



    const handleOpenUserMenu = (event, buttonId) => {
        setAnchorElUser(event.currentTarget);
        setClickedButtonId(buttonId); // Update the state with the button's ID
    };

    const handleCloseUserMenu = (e: any) => {
        setAnchorElUser(null);
    };



    //whats App functionality
    const handleOpenUserMenu2 = (event, buttonId) => {

        setAnchorElUser2(event.currentTarget);
        setClickedButtonId(buttonId); // Update the state with the button's ID
    };

    const handleClickWhatsApp = (filterType: any) => {
        setAnchorElUser2(null);
    };


    const handleCloseUserMenu2 = (e: any) => {
        setAnchorElUser2(null);
    };



    //Download functionality
    const handleOpenUserMenu1 = (event, buttonId) => {
        setAnchorElUser1(event.currentTarget);
        setClickedButtonId(buttonId);
    };

    const handleCloseUserMenu1 = (e: any) => {
        setAnchorElUser1(null);
    };

    const { mutate: mutateGetDocumentId, isLoading: isLoadingDownload } = useMutation(getDocumentIdToCamunda, {
        onSuccess: (data2: any) => {
            if (data2?.data) {
                sendPushNotifications({
                    processInstanceId: processInstanceId,
                    docType: data2?.data?.downloadType
                })
                const pdfUrl = `https://docs.google.com/document/d/${data2?.data?.documentId}/export?format=${data2?.data?.downloadType}`;
                window.open(pdfUrl, '_blank');
            }
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    })


    const convertHtmlToImage = (htmlContent: any) => {
        // Create a temporary div element to render HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;

        // var dragContainer = document.getElementById("dragContainer") as HTMLElement;
        // dragContainer.style.padding = "20px";
        // dragContainer.style.paddingTop = "30px"; // Adjust the top padding value as needed
        // dragContainer.style.paddingRight = "30px"; // Adjust the right padding value as needed
        // dragContainer.style.paddingLeft = "30px";
        // dragContainer.style.paddingBottom = "30px";


        var dragContainer = document.getElementById("dragContainer") as HTMLElement;
        dragContainer.style.overflow = "hidden";
        dragContainer.style.paddingTop = "30px";

        // Append the temporary div to the body to render it in the DOM
        dragContainer.appendChild(tempDiv);

        html2canvas(dragContainer, { allowTaint: true, scale: 1.4 }).then((canvas) => {
            // Create a new jsPDF instance with 'p' (portrait), 'pt' (points), 'letter' size
            var pdf = new jsPDF('p', 'pt', 'letter');

            // Get dimensions of the container and canvas
            var containerWidth = dragContainer.clientWidth;
            var containerHeight = dragContainer.clientHeight;
            var pageHeight = 1189;  // height of one page in pixels
            var canvasWidth = containerWidth * 1.4;  // scaled width
            var canvasHeight = containerHeight * 1.4;  // scaled height

            // Define margins in points (1 inch = 72 points)
            var margin = 60;  // 40-0.5 inch margin

            // Calculate the usable area within the margins
            var pdfWidth = 616 - 2 * margin;  // 'letter' width - margins
            var pdfHeight = 810 - 2 * margin;  // 'letter' height - margins

            // Calculate the number of pages needed
            var numPages = Math.ceil(canvasHeight / pageHeight);

            // Loop through each page
            for (var i = 0; i < numPages; i++) {
                // Create a canvas for each page
                var onePageCanvas = document.createElement("canvas") as any;
                onePageCanvas.setAttribute('width', canvasWidth);
                onePageCanvas.setAttribute('height', pageHeight);

                var ctx = onePageCanvas.getContext('2d');
                ctx.drawImage(
                    canvas,
                    0, pageHeight * i, canvasWidth, pageHeight,  // source rect
                    0, 0, canvasWidth, pageHeight  // destination rect
                );

                var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

                // Add the canvas to the PDF
                if (i > 0) {
                    pdf.addPage();
                }
                pdf.setPage(i + 1);
                pdf.addImage(canvasDataURL, 'PNG', margin, margin, pdfWidth, pdfHeight);  // add image with margins

            }

            // Save the generated PDF
            pdf.save('Test.pdf');

            // Remove the temporary div from the DOM
            dragContainer.removeChild(tempDiv);
        });

        // Use html2canvas to convert HTML content to an image
        // html2canvas(dragContainer, { allowTaint: true, scale: 2 }).then(canvas => {
        //     const imgData = canvas.toDataURL('image/png');
        //     const imgWidth = 190;
        //     const pageHeight = 290;
        //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
        //     const headerHeight = 20; // Height of the header
        //     const footerHeight = 20; // Height of the footer
        //     let heightLeft = headerHeight + imgHeight + footerHeight; // Include footer height
        //     let doc = new jsPDF('p', 'mm');
        //     let position = 0;

        //     // Add image to the first page
        //     doc.addImage(imgData, 'PNG', 10, 25, imgWidth, imgHeight + 25);

        //     doc.setFillColor(255, 255, 255);
        //     doc.rect(0, doc.internal.pageSize.height - footerHeight, doc.internal.pageSize.width, footerHeight, 'F');

        //     heightLeft -= pageHeight;

        //     while (heightLeft >= 0) {
        //         // Add a new page
        //         doc.addPage();

        //         // Add blank space for the footer at the top of the page
        //         doc.setFillColor(255, 255, 255);
        //         doc.rect(0, 0, doc.internal.pageSize.width, footerHeight, 'F');


        //         // Add image to the new page with position adjusted for footer
        //         position = heightLeft - imgHeight;
        //         doc.addImage(imgData, 'PNG', 10, position + 30, imgWidth, imgHeight - 25);

        //         const pageWidth = doc.internal.pageSize.getWidth();

        //         // Add blank space for the footer
        //         doc.setFillColor(255, 255, 255);
        //         doc.rect(0, doc.internal.pageSize.height - footerHeight, pageWidth, footerHeight, 'F');

        //         doc.setFillColor(255, 255, 255);
        //         doc.rect(0, 0, pageWidth, headerHeight, 'F');

        //         heightLeft -= (headerHeight - pageHeight - footerHeight); // Adjust for footer
        //     }

        //     // Save the PDF
        //     doc.save('download.pdf');

        //     // Remove the temporary div from the DOM
        //     dragContainer.removeChild(tempDiv);
        // }).catch(error => {
        //     console.error("Error converting HTML to image:", error);
        // });

    };

    const { mutate: mutateSignPdf } = useMutation(getSignDocumentData, {
        onSuccess: (res: any) => {
            snack.success("success")
            console.log("miijijijiji", res?.data);
            convertHtmlToImage(res?.data?.esignHtml)

        },
        onError: (error: any) => {
            console.error("Error uploading file:", error);
            snack.error("Error uploading file");
        },
    });

    const { data: data1, isLoading: loading1 }: ResType = useQuery(["Sign-For-Others", processInstanceId], getSignForOthersData, {
        onSuccess(res1: any) {
            console.log({ res1 })

            setSignData(res1?.data?.esignHtml)
        },
    });
    const { data:orgdata, isLoading: isLoadingOrganization }: ResType = useQuery(["organization"], getOrganization, {
        onSuccess: (res: any) => {
        //   setState(res.data);
        setOrgHeader(res?.data?.header)
        setOrgFooter(res?.data?.footer)

        console.log(res?.data?.header,'orggg')
        },
        onError: (err: any) => {
          if (!err?.error) {
            snack.error(`An error occurred while fetching organization details`);
          }
        },
        cacheTime: 0,
      });
    const { mutate: mutateheaderandfooter ,isLoading: isDownloading} = useMutation(downloadWithOrgHeaderAndFooter, {

        onSuccess: (data2: any) => {
            if (data2?.data) {
                sendPushNotifications({
                    processInstanceId: processInstanceId,
                    docType: data2?.data?.downloadType
                })
                const pdfUrl = `https://drive.google.com/file/d/${data2?.data?.documentId}//view`;
                window.open(pdfUrl, '_blank');
            }
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });
    const handleClickDownload = async (downloadType: any) => {

        if (downloadType === "PDF") {
            await mutateGetDocumentId({ processInstanceId: processInstanceId, type: "pdf" }); // Wait for state update
        } else if (downloadType === "Word") {
            await mutateGetDocumentId({ processInstanceId: processInstanceId, type: "docx" }); // Wait for state update
        } else if (downloadType === 'signPdf') {
            mutateSignPdf({ processInstanceId: processInstanceId });
            // navigate(`/signdownload/${processInstanceId}`)
        }
        else if (downloadType === 'headerandfooter') {
            // Check if header and footer are null
            console.log(orgdata,'orgdata')
    if (orgHeader == null || orgFooter == null) {
        snack.error("Header and/or footer is missing.");
        return;
      }
            mutateheaderandfooter({ processInstanceId: processInstanceId, type: "pdf",userId:userId });
            // navigate(`/signdownload/${processInstanceId}`)
        }
      
        setAnchorElUser1(null);
    };


    // Email share code functionality
    const [selectedOptions, setSelectedOptions]: any = useState([]);

    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            // If the option is already selected, remove it
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            // If the option is not selected, add it
            setSelectedOptions([...selectedOptions, option]);
        }
    };


    const { mutate: downloadMailMutate, isLoading: isLoadingEmail } = useMutation(DownloadEmail, {
        onSuccess: (data2: any) => {
            snack.success("Document Sent Successfully")
            setTextFieldValue("")
            setSelectedOptions([])

        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    })

    const [emailError, setEmailError] = useState(false);

    const validateEmail = (email) => {
        // Basic email validation pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setTextFieldValue(inputValue);

        // Validate email and set error state
        setEmailError(!validateEmail(inputValue));
    };

    const handleSaveButtonClick = () => {
        // Check if the entered text is a valid email
        if (validateEmail(textFieldValue)) {
            if (selectedOptions.length > 0) {
                console.log(selectedOptions)
                const selectedEmails = selectedOptions.join(', ');
                downloadMailMutate({ selectedEmails, textFieldValue, processInstanceId, selectedOptions })
                setAnchorElUser(null);
            } else {
                snack.error("Please select at least one document type.");
            }

        } else {
            snack.error("Invalid email address. Please enter a valid email.");
        }
    };

    const handleOnclickPrint = () => {
        setIsPrintClick(true)
        const downloadType = "html"
        tempalateDownload({
            downloadType,
            processInstanceId
        })
    };

    const isValidName = (email: any) => {
        const status = email?.length > 3
        return status;
    };

    if (isLoadingDownload) {
        return <Loader value='Downloading is progress...' />;
    }

    if (isLoadingEmail) {
        return <Loader value='Email sending is progress...' />;
    }
    if(isDownloading){
        return <Loader value='Please wait ...' />;
 
    }

    if (isLoading) return <Loader />;
    return (<>
        <Box>
            <Grid container height="100vh">
                <Box width={"60%"} m={5}>
                    <Box display={"flex"} m={8}>
                        <Typography variant="h5">Name:</Typography>
                        <Typography pl={3} variant="h5">{docmentDetails?.templateName}</Typography>
                    </Box >
                    <Box display={"flex"} textAlign={"center"} height={150} justifyContent={"center"} m={8}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mr={8}>
                            <Button
                                onClick={(event) => handleOpenUserMenu1(event, "download")}
                                style={{
                                    borderRadius: '50%',
                                    width: '100px',
                                    height: '100px',
                                    border: '5px solid #182F53',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img src={DownloadImg} alt="download" />
                            </Button>
                            <Typography color="#182F53" mt={3} variant="h5">
                                Download
                            </Typography>
                            <Menu
                                // sx={{ mt: '40px' }}
                                id="download"
                                open={Boolean(anchorElUser1)}
                                onClose={handleCloseUserMenu1}
                                anchorEl={anchorElUser1}
                                anchorOrigin={{
                                    vertical: 'bottom',  // Align the top of the Menu with the bottom of the button
                                    horizontal: 'right', // Align the right edge of the Menu with the right edge of the button
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                keepMounted

                                transformOrigin={{
                                    vertical: 'top',     // Align the top of the Menu with the top of the button
                                    horizontal: 'right', // Align the right edge of the Menu with the right edge of the button
                                }}

                            >
                                <MenuItem sx={{ borderRadius: "5px", display: "flex", justifyContent: "space-between" }} onClick={() => handleClickDownload("PDF")}>

                                    Download PDF
                                    <ListItemIcon>
                                        <FileDownloadIcon fontSize="small" />
                                    </ListItemIcon>
                                </MenuItem>
                                {docmentDetails?.esignHtml && (
                                    <MenuItem sx={{ borderRadius: "5px", display: "flex", justifyContent: "space-between" }} onClick={() => handleClickDownload("signPdf")}>

                                        Download Sign PDF
                                        <ListItemIcon>
                                            <FileDownloadIcon fontSize="small" />
                                        </ListItemIcon>
                                    </MenuItem>
                                )}

                                <MenuItem sx={{ borderRadius: "5px", display: "flex", justifyContent: "space-between" }} onClick={() => handleClickDownload("Word")}>

                                    Download Word Document
                                    <ListItemIcon >
                                        <FileDownloadIcon fontSize="small" />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem sx={{ borderRadius: "5px", display: "flex", justifyContent: "space-between" }} onClick={() => handleClickDownload("headerandfooter")}>

Download with organization header & footer
<ListItemIcon>
    <FileDownloadIcon fontSize="small" />
</ListItemIcon>
</MenuItem>
                            </Menu>
                        </Box>
                        {/* <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}  >
                        <Button
                            onClick={handleOnclickPrint}
                            style={{
                                borderRadius: '50%',
                                width: '100px',
                                height: '100px',
                                border: '5px solid #182F53',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img src={PrinterImg} alt="printer" />
                        </Button>
                        <Typography color={"#182F53"} mt={3} variant="h5">Print</Typography>

                    </Box> */}
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}  >
                            <Button
                                style={{
                                    borderRadius: '50%',
                                    width: '100px',
                                    height: '100px',
                                    border: '5px solid #182F53',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={(event) => handleOpenUserMenu(event, "email")}
                            >
                                <img src={MessageImg} alt="email" />
                            </Button>
                            <Typography color={"#182F53"} mt={3} variant="h5">Email</Typography>
                            <Menu
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                // sx={{ mt: '40px' }}
                                id="email"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'bottom',  // Align the top of the Menu with the bottom of the button
                                    horizontal: 'right', // Align the right edge of the Menu with the right edge of the button
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                keepMounted

                                transformOrigin={{
                                    vertical: 'top',     // Align the top of the Menu with the top of the button
                                    horizontal: 'right', // Align the right edge of the Menu with the right edge of the button
                                }}

                            >
                                <MenuItem style={{ borderBottom: "1px solid #ccc", display: "flex", justifyContent: "space-between" }}>
                                    <label>

                                        Share PDF

                                    </label>
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions.includes("pdf")}
                                        onChange={() => handleCheckboxChange("pdf")}
                                    />
                                </MenuItem>

                                <MenuItem style={{ borderBottom: "1px solid #ccc", display: "flex", justifyContent: "space-between" }}>
                                    <label>
                                        Share Word Document

                                    </label>
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions.includes("docx")}
                                        onChange={() => handleCheckboxChange("docx")}
                                    />
                                </MenuItem>

                                <MenuItem>
                                    <TextField
                                        type="email"
                                        size="small"
                                        value={textFieldValue}
                                        onChange={(e) => setTextFieldValue(e.target.value)}
                                    />
                                    <Button variant="contained" size="small" color="error" onClick={handleSaveButtonClick} sx={{ marginLeft: "10px" }}>Save</Button>
                                </MenuItem>
                            </Menu>

                        </Box>
                        {/* <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}  >
                        <Button
                            style={{
                                borderRadius: '50%',
                                width: '100px',
                                height: '100px',
                                border: '5px solid #182F53',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={(event) => handleOpenUserMenu2(event, "whatsApp")}
                        >
                            <img src={WhatsUpImg} alt="whatsUp" />
                        </Button>
                        <Menu
                            // sx={{ mt: '40px' }}
                            id="whatsApp"
                            open={Boolean(anchorElUser2)}
                            onClose={handleCloseUserMenu2}
                            anchorEl={anchorElUser2}
                            anchorOrigin={{
                                vertical: 'bottom',  // Align the top of the Menu with the bottom of the button
                                horizontal: 'right', // Align the right edge of the Menu with the right edge of the button
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            keepMounted

                            transformOrigin={{
                                vertical: 'top',     // Align the top of the Menu with the top of the button
                                horizontal: 'right', // Align the right edge of the Menu with the right edge of the button
                            }}

                        >
                            <MenuItem sx={{ borderBottom: "1px solid #ccc", borderRadius: "5px", display: "flex", justifyContent: "space-between" }} onClick={() => handleClickWhatsApp("PDF")}>

                                Share PDF
                                <ListItemIcon>
                                    <ShareIcon fontSize="small" />
                                </ListItemIcon>
                            </MenuItem>
                            <MenuItem sx={{ borderRadius: "5px", display: "flex", justifyContent: "space-between" }} onClick={() => handleClickWhatsApp("Word")}>
                                Share Word Document
                                <ListItemIcon >
                                    <ShareIcon fontSize="small" />
                                </ListItemIcon>
                            </MenuItem>
                        </Menu>
                        <Typography color={"#182F53"} mt={3} variant="h5">WhatsApp</Typography>

                    </Box> */}
                    </Box>
                </Box>
                <Box width={"30%"}>
                    <Paper elevation={3} style={{ height: '500px', width: '300px', borderRadius: "10px", paddingTop: "20px", marginTop: "30px" }}>
                        <Box display={"flex"} flexDirection={"column"} textAlign={"center"} height={100} justifyContent={"space-between"} m={5}>

                            <Button
                                variant="outlined"
                                startIcon={<BorderColorIcon />}
                                onClick={handleonClickEdit}
                                sx={{
                                    borderRadius: '12px',
                                    marginBottom: '10px',
                                    border: "2px solid #182F53"
                                }}
                            >
                                <Typography variant="h6">Edit</Typography>
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<DriveFileRenameOutlineIcon />}
                                // onClick={handleonClickRename}
                                onClick={handleOpen}
                                sx={{
                                    borderRadius: '12px',
                                    marginBottom: '10px',
                                    border: "2px solid #182F53"
                                }}
                            >
                                <Typography variant="h6">Rename</Typography>
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    borderRadius: '12px',
                                    marginBottom: '10px',
                                    border: "2px solid #182F53"
                                }}
                                onClick={handlePreview}
                            >
                                <Typography variant="h6">Preview</Typography>

                            </Button>
                            {/* <Button
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                            onClick={handleonClickDelete}
                            sx={{
                                borderRadius: '12px',
                                marginBottom: '10px',
                                border: "2px solid #182F53"
                            }}
                        >
                            <Typography variant="h6">Delete</Typography>
                        </Button> */}
                        </Box>
                    </Paper>

                    <Dialog open={open} >
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"400px"} mr={2}>
                            <DialogTitle >Enter the Saved template name</DialogTitle >
                            <ClearIcon onClick={handleClose} />
                        </Box>
                        <DialogContent>
                            <TextField
                                required
                                variant="outlined"
                                value={name || ""}
                                onChange={handleChange}
                                placeholder="Enter value"
                                style={{ width: "100%" }}
                                error={!isValidName(name) && name !== ""}
                                helperText={(!isValidName(name) && name !== "") && 'Enter min length 4'}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleTemplateNameChange} color="error" variant="contained" style={{ borderColor: "#E44652", margin: "auto", width: "100px", height: "35px", borderRadius: "10px" }}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Grid>
            <Box mt={100}
                padding={5}
                margin={2}
                bgcolor={"#ffffff"}
                style={{
                    overflowY: "auto",
                    overflowX: "auto", height: "1300px", width: "690px", userSelect: "none", position: 'relative', margin: '0 auto'
                }}

                sx={{
                    maxWidth: "900px",
                    wordWrap: "break-word"
                }}
            >
                <div id="dragContainer" style={{
                    position: 'relative'
                }}>

                </div>
            </Box>
            {/* <Box >
           {signData} 
            </Box> */}

        </Box>
        {
            <DialogWrapper
                width="lg"
                open={previewOpen}
                setOpen={setPreviewOpen}
                title="Document Preview"
            >

                <>
                    <Box display={"flex"} >
                        <Box id="download-file" width={"100%"} bgcolor={"#f2f2f2"} style={{ display: "flex", justifyContent: "center", maxHeight: "90%" }}>

                            <Box mt={10}
                                padding={5}
                                margin={2}
                                bgcolor={"#ffffff"}
                                style={{
                                    overflowY: "auto",
                                    overflowX: "auto", height: "95vh", width: "70%", userSelect: "none"
                                }}
                                // onContextMenu={handleContextMenu}
                                sx={{
                                    maxWidth: "900px",
                                    wordWrap: "break-word"
                                }}
                            >

                                <div
                                    id="dragContainer"
                                    className="dragcontainer"
                                    style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}

                                >
                                    <div>
                                        {isLoading && <p>Loading...</p>}

                                        {signData && (
                                            <div dangerouslySetInnerHTML={{ __html: signData }} />
                                        )}

                                    </div>

                                </div>
                            </Box>
                            {/* <Box width={"15%"} bgcolor={"#f2f2f2"} style={{ marginLeft: "50px" }} >
                    {htmlContent && (
                        <Box mt={5}>
                            {!pdfdownloading ? <Button size="large" color="secondary" variant="contained" onClick={convertHtmlToImage}
                                style={{ marginTop: '10px' }}>
                                Download
                            </Button> : <Button size="large" color="secondary" variant="contained"
                                style={{ marginTop: '10px' }}>
                                Downloading
                            </Button>}


                        </Box>
                    )}
                </Box> */}
                        </Box>
                    </Box>
                </>

            </DialogWrapper>
        }
    </>
    )
}

export default DownloadPage