import React from "react";
import { useSelector } from "react-redux";
import { selectSignup } from "redux/reducers/signUpSlice";
import CreateAccount from "views/signup/CreateAcount";
import OrgDetails from "views/signup/OrgDetails";
import Otp from "views/signup/Otp";

const Subscription = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center"}}>
        <img
          src="https://vider.in/images/subscription-page.png"
          alt="vider"
          style={{ marginBottom: "20px",width: "100px"}}
        />
        <h1 style={{ fontSize: "24px", marginBottom: "10px",fontFamily: "Muli_bold, sans-serif"}}>Your Subscription has been ended</h1>
        <p style={{ marginBottom: "20px",fontFamily: "muli_regular, sans-serif" }}>Don't miss out! Renew your subscription now for uninterrupted access and benefits.</p>
      <a href="https://vider.in/contactUs.html">  <button
          style={{
            padding: "10px 20px",
            background: "#182F53",
            color: "white",
            border: "none",
            borderRadius: "4px",
            fontFamily: "Muli_bold, sans-serif"
          }}
        >
          Contact Sales
        </button></a>
      </div>
    </div>
  );
};

export default Subscription;
