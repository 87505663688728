import { RootState } from "redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type Level = {
    id: number
    label: string
}
export interface IApprovalLevel {
    roleId: string;
    userId: string;
}

export interface IState {
    name: string;
    description: string;
    approvalValue: string | null;
    approvalLevels: Array<IApprovalLevel>;
}

const initialState: IState = {
    name: "",
    description: "",
    approvalValue: "",
    approvalLevels: [],
};

export const approvalsLevelSlice = createSlice({
    name: "approvalsLevel",
    initialState,
    reducers: {
        setData(state: IState, action: PayloadAction<any>) {
            state.name = action.payload.name;
            state.description = action.payload.description;
            state.approvalValue = action.payload.approvalLevels.length === 1 ? 'Up to 1 Level' :
                (action.payload.approvalLevels.length === 2) ? 'Up to 2 Level' :
                    (action.payload.approvalLevels.length === 3) ? 'Up to 3 Level' :
                        (action.payload.approvalLevels.length === 4) ? 'Up to 4 Level' :
                            (action.payload.approvalLevels.length === 5) ? 'Up to 5 Level' :
                                (action.payload.approvalLevels.length === 6) ? 'Up to 6 Level' : '';
            state.approvalLevels = action.payload.approvalLevels?.map(
                (item: any) => ({
                    roleId: item.role?.id,
                    userId: item.user?.id,
                })
            );
        },
        resetData(state: IState) {
            state.name = "";
            state.description = "";
            state.approvalValue = "";
            state.approvalLevels = [];
        },
        handleApprovalName(state: IState, action: PayloadAction<any>) {
            state.name = action.payload;
        },
        handleApprovalDescription(state: IState, action: PayloadAction<any>) {
            state.description = action.payload;
        },

        handleApprovalValue(state: IState, action: PayloadAction<any>) {
            state.approvalValue = action.payload
        },
        addApprovalLevel1(state: IState) {
            state.approvalLevels.push({
                roleId: "",
                userId: "",
            });
        },

        addApprovalLevel2(state: IState) {
            state.approvalLevels.push({
                roleId: "",
                userId: "",
            },
                {
                    roleId: "",
                    userId: "",
                },
            )
        },

        addApprovalLevel3(state: IState) {
            state.approvalLevels.push(
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
            )
        },
        addApprovalLevel4(state: IState) {
            state.approvalLevels.push(
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
            )
        },

        addApprovalLevel5(state: IState) {
            state.approvalLevels.push(
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
            )
        },

        addApprovalLevel6(state: IState) {
            state.approvalLevels.push(
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
                {
                    roleId: "",
                    userId: "",
                },
            )
        },




        removeApprovalLevel(state: IState, action: PayloadAction<number>) {
            state.approvalLevels.splice(action.payload, 1);
        },

        removeApprovalLevelAll(state: IState,) {
            state.approvalLevels = [];

        },
        handleApprovalLevel(state: IState, action: PayloadAction<any>) {
            let { index, name, value } = action.payload;
            state.approvalLevels[index][name] = value;

            if (name === "roleId") {
                state.approvalLevels[index].userId = "";
            }
        },
    },
});

export const selectApprovalsLevels = (state: RootState) => state.approvalLevels;

export const {
    handleApprovalName,
    handleApprovalDescription,
    handleApprovalValue,
    addApprovalLevel1,
    addApprovalLevel2,
    addApprovalLevel3,
    addApprovalLevel4,
    addApprovalLevel5,
    addApprovalLevel6,
    setData,
    resetData,
    handleApprovalLevel,
    removeApprovalLevel,
    removeApprovalLevelAll
} = approvalsLevelSlice.actions;

export default approvalsLevelSlice.reducer;
