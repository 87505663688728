import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, IconButton, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getUserstableData } from "api/services/quantum/dashboard";
import { useQuery } from "react-query";
import { ResType } from "types";
import { StyledTaskBox } from 'views/dashboard/OrgDashboard/styles';
// import Table from "components/Table";

function UsersTable() {


  const { data: users, isLoading }: ResType = useQuery(
    ["get-usersdata"],
    getUserstableData, {
    onSuccess: (data) => {
    }
  }
  );

  // if (isLoading) return <Loader />;

  return (
    <StyledTaskBox sx={{ margin: "10px" }}>
      <header>
        <Typography variant="h6">Document Status by Users</Typography>

      </header>
      <main style={{ padding: 0 }}>


        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundcolor: "#e0e0e0" }}>
              <TableCell><Typography variant="h6">User Name</Typography></TableCell>
              <TableCell><Typography variant="h6">In Progress</Typography></TableCell>
              <TableCell><Typography variant="h6">Under Review</Typography></TableCell>
              <TableCell><Typography variant="h6">Completed</Typography></TableCell>
              <TableCell><Typography variant="h6">Total Documents</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.data?.map((row, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <Typography variant="caption">{row.fullName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{row.inProgressCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{row.underReviewCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{row.completedCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{row.total}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </main>
    </StyledTaskBox>
  );
}

export default UsersTable;
