import { Box, CircularProgress, Typography } from "@mui/material";

function CircularProgressBar({ percentage, color }) {
    return (
        <div style={{
            position: 'relative', top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            //   position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

        }}>
            <CircularProgress
                variant="determinate"
                value={percentage}
                size={26}
                thickness={2}

                style={{ position: 'absolute', color: color }}
            />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={26}
            >
                <Typography variant="subtitle2" sx={{ fontSize: "6px" }} component="div">
                    {percentage === null ? 0 : percentage}%
                </Typography>
            </Box>
        </div>
    );
}


export default CircularProgressBar