import React from 'react'
import { Box } from '@mui/material'
import RecentDocumentsCard from './dashboard-documents/card'
import DocumentCategoriesCard from './dashboard-documents/categoryCard'

function Dashboarddocuments() {
  return (
    <>
      <Box sx={{ overflow: "scroll", width: "100vw", height: "100vh", padding: "10px" }}>
        <RecentDocumentsCard />
        <DocumentCategoriesCard />
      </Box>
    </>
  )
}

export default Dashboarddocuments