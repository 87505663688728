import { http } from "api/http";

const getDocumentApprovals = ({ queryKey }) => {
  return http.get(`/camunda-approval/${queryKey[1]}`);
};

const rejectLevel = ({ id, data }) => {
  return http.put(`/camunda-approval/reject/${id}`, data);
};

const approveLevel = ({ id, data }) => {
  return http.put(`/camunda-approval/approve/${id}`, data);
};

const getDocumentStatus = ({ queryKey }) => {
  return http.get(`/camunda-approval/status/${queryKey[1]}`);
};

const changeAppStatus = ({ id, data }) => {
  return http.put(`/camunda-approval/status/${id}`, data);
};

const getSnapshots = ({ queryKey }) => {
  return http.get(`/camunda-approval/snapshots/${queryKey[1]}`);
};

export {
  getDocumentApprovals,
  rejectLevel,
  approveLevel,
  getDocumentStatus,
  changeAppStatus,
  getSnapshots,
};
