
import React, { useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box } from '@mui/material';


interface Props {
    onMenuItemClick: (item: any) => void;
    sequenceing
}
const LeftSideBar = ({ onMenuItemClick, sequenceing }: Props) => {
    const handleClick = (pageIndex: number, fieldName: string) => {
        setSelectedItem(fieldName);
        onMenuItemClick({ pageIndex, fieldName });
        // }
    };
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const handleMouseEnter = () => {
        setMenuVisible(true);

    };

    const handleMouseLeave = () => {
        setMenuVisible(false);
    };

    type MenuItemProps = {
        text?: any;
        selected: boolean;
        onClick: () => void;
        isCompleted: boolean;
        isMenuVisible:boolean
    };

    const MenuItem: React.FC<MenuItemProps> = ({ text, selected, onClick, isCompleted , isMenuVisible}) => {
        const textColor = selected ? '#182F53' : '#89898A';
        return (
            <Box onClick={onClick} style={{ fontWeight: selected ? 'bold' : 'normal', cursor: 'pointer', fontFamily: 'muli_regular', fontSize: '14px', color: textColor }}>
                <Box style={{ display: 'inline-block', padding: '5px', lineHeight: '18px' }}>
                    {isCompleted ? <DoneIcon style={{ color: '#00A653', fontSize: '10px', borderRadius: '50%', border: '1px solid #00A653' }} /> : <FiberManualRecordIcon style={{ fontSize: '8px', marginRight: '5px' }} />}
                </Box>
                {isMenuVisible? text:""}
            </Box>
        );
    };



    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                width: isMenuVisible ? '250px' : '50px',
                transformOrigin: 'left',
                transition: 'transform 0.9s ease',
                overflowY: "auto",
                overflowX: "hidden",
                background: '#f0f0f0',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                padding: '10px',
                height: '80vh',
                position: 'absolute',
                backgroundColor: "#ffffff",
                zIndex: 100
            }}
        >
            <Box style={{ marginTop: '8px' }}>

                {isMenuVisible ? (
                    <Box style={{ width: '350px' }}>
                        {sequenceing && Object.keys(sequenceing)?.map((key, index) => {
                            const fieldValue = sequenceing[key]?.metadata?.fieldValue;
                            return (
                                <MenuItem
                                    key={key}
                                    isMenuVisible={isMenuVisible}
                                    text={key}
                                    selected={selectedItem === key}
                                    isCompleted={fieldValue !== null && fieldValue !== ""}
                                    onClick={() => handleClick(index, key)}
                                />
                            );
                        })}
                    </Box>
                ) : (
                    <Box style={{ width: '50px' }}>
                        {sequenceing && Object.keys(sequenceing)?.map((key, index) => {
                            const fieldValue = sequenceing[key]?.metadata?.fieldValue;
                            // console.log(`Field ${key}: fieldValue = ${fieldValue}`);
                            return (
                                <MenuItem
                                    key={key}
                                    isMenuVisible={isMenuVisible}
                                    text={key}
                                    selected={selectedItem === key}
                                    isCompleted={fieldValue !== null && fieldValue !== ""}
                                    onClick={() => handleClick(index, key)}
                                />
                            );
                        })}
                    </Box>
                )}
            </Box>
        </Box >
    );
};

export default LeftSideBar;