import { useState } from "react";
import { Box, Typography, Button, } from "@mui/material";
import { useQuery } from "react-query";
import { getRecentDocs } from "api/services/quantum/dashboard";
import moment from "moment";
import CircularProgressBar from "quantumpages/documents/CircularProgressBar";
import Members from "components/Members";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchContainer from "components/SearchContainer";
import useFilteredData from "hooks/useFilteredData";



const RecentDocumentsCard = () => {
    const { data } = useQuery(["recent-docs"], getRecentDocs, {
        onSuccess: (data) => {
        },
    });

    const [currentIndex, setCurrentIndex] = useState(0);
    const [search, setSearch] = useState("");

    const handleNext = () => {
        setCurrentIndex((prevIndex) => prevIndex + 4);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => prevIndex - 4);
    };
    const filteredData = useFilteredData(data?.data, ["templateName"], search);
    return (
        <Box>
            <Box display="flex" flex={1} gap={2} alignItems="center" margin={"20px"}>
                <SearchContainer
                    // debounced
                    minWidth="400px"
                    onChange={setSearch}
                    placeHolder="Search"
                />
                {/* <Button
                    startIcon={<FilterAltOutlinedIcon />}
                    color="primary"
                    sx={{ border: "1px solid lightgrey", borderRadius: "4px" }}
                >
                    Filters
                </Button> */}

            </Box>
            <Typography color="primary" variant="h4" fontSize={"20px"} sx={{ marginTop: "10px", marginLeft: "25px", marginBottom: "15px" }}>
                Recent
            </Typography>

            <Box display={"flex"} width={"95%"} justifyContent={"space-between"}>
                <Button onClick={handlePrevious}
                    disabled={currentIndex === 0}
                    sx={{ color: currentIndex === 0 ? "grey" : "black" }}><ArrowBackIosIcon /></Button>

                <Box sx={{ padding: "10px", width: "90%", backgroundColor: "#e6eded", display: "flex", gap: "10px", justifyContent: "center" }}>
                    {filteredData &&
                        filteredData.map((item, index) => {
                            if (index < currentIndex || index >= currentIndex + 4) return null; // Show only 4 items at a time
                            return (
                                <Box
                                    key={item.id}
                                    sx={{
                                        marginBottom: "10px",
                                        height: "100%",
                                        border: `1px solid rgba(0,0,0,0.1)`,
                                        borderRadius: "10px",
                                        background: "#ffffff",
                                        cursor: "pointer",
                                        width: "25%"
                                    }}
                                >
                                    <Box
                                        px={2}
                                        onClick={() => {
                                            // navigate(`/template/${item?.processInstanceId}/build`);
                                        }}
                                        py={1}
                                        sx={{ cursor: "pointer", minHeight: "150px" }}
                                    >

                                        <Box display="flex" gap={1} justifyContent="space-between">
                                            <Typography variant="body2" color="gray">
                                                Doc id-{item?.id}
                                            </Typography>
                                            <Typography variant="caption" color="gray">
                                                clientId - {item?.clientId}
                                            </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant="body1" gutterBottom color="primary">
                                                {item?.templateName}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom color="primary">
                                                Priority : <span style={{ color: "gray" }}>{item?.priority}</span>
                                            </Typography>
                                            <Box display="flex" justifyContent="space-between">
                                                <div>
                                                    <Typography variant="caption" color="gray">
                                                        Start date: {item?.createdAt && moment(item?.createdAt).format("DD MMM YYYY")}
                                                    </Typography>
                                                </div>
                                            </Box>
                                            <Typography variant="caption" color="gray">
                                                last updated: {item?.lastUpdated && moment(item?.lastUpdated).format("DD MMM YYYY")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" px={1} py="5px" gap="10px" borderTop="1px solid rgba(0,0,0,0.1)">
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <Box ml={1}>
                                                {item?.approvalStatus === 'false' ? (<CircularProgressBar percentage={item.percentage} color='red' />) :
                                                    (<CircularProgressBar percentage={item.percentage} color='blue' />)}
                                            </Box>

                                        </Box>
                                        <Box flex={1} display="flex" justifyContent="flex-end">
                                            <Members
                                                size="small"
                                                data={item?.assignedUsers?.map((item: any) => ({
                                                    src: item?.imageUrl,
                                                    title: item?.fullName,
                                                }))}
                                            />
                                        </Box>
                                    </Box>
                                    {/* {item?.status ? (

                                        <>
                                            {item?.approvalStatus === null || !item?.aprovalProgress?.[0]?.["user"] ? <></> : <>

                                                <Box display="flex" px={1} py="5px" gap="10px" borderTop="1px solid rgba(0,0,0,0.1)">
                                                    <Box flex={1} display="flex" justifyContent="flex-start">
                                                        {item?.approvalStatus === 'false' ? <Typography color='red'>{item?.aprovalProgress?.[0]?.["value"]}</Typography> :
                                                            <Typography>{item?.aprovalProgress?.[0]?.["value"]}</Typography>}
                                                    </Box>
                                                </Box>
                                                <Box display="flex" px={1} py="5px" gap="10px" borderTop="1px solid rgba(0,0,0,0.1)" bgcolor={"#8A49FF2E"}>
                                                    {item?.aprovalProgress?.[0]?.["user"] ? (<Box flex={1} display="flex" justifyContent="flex-start">
                                                        <Typography
                                                            component='span'
                                                            variant="body2"
                                                        >Reviewer: {item?.aprovalProgress?.[0]?.["user"]}</Typography>
                                                    </Box>) : <>
                                                        <Box flex={1} display="flex" justifyContent="flex-start" height={'20px'}>

                                                        </Box>
                                                    </>}
                                                </Box></>}




                                        </>) : <>

                                    </>} */}

                                    {/* {item?.approvalStatus === 'false' ? (
                                        <Box>

                                        </Box>

                                    ) : <></>} */}




                                </Box>
                            );
                        })}
                </Box>
                <Button onClick={handleNext}
                    disabled={currentIndex + 4 >= filteredData?.length}
                    sx={{ color: currentIndex + 4 >= filteredData?.length ? "grey" : "black" }}><ArrowForwardIosIcon /></Button>
            </Box>
        </Box>
    );
};

export default RecentDocumentsCard;
