import {
    Box,
    Button,
    Dialog,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


const SelectDownloadMethod = (props: any) => {
    const navigate = useNavigate()
    const { open, setOpen, processId } = props;
    const [isLoading, setLoading] = useState(false);

    const handleClose = () => {
        navigate(`/template/download/${processId}`);
        setOpen(false);
    };

    const onOk = () => {
        setLoading(true);
        navigate(`/Signdashboard/${processId}`)
        setOpen(false);
    };



    return (
        <Box height={"300px"}>
            <Dialog
                sx={{ alignItems: "flex-start", maxHeight: 600 }}
                fullWidth
                maxWidth="sm"
                open={open}
                PaperProps={{
                    elevation: 0,
                    sx: { display: "flex", flexDirection: "column" },
                }}
            >
                <Box p={2}>
                    <Box>
                        <Typography variant="body1" color="textSecondary" sx={{ fontWeight: "800", color: "black" }}>
                            Do You Want to Sign the Document?
                        </Typography>
                    </Box>
                    <Box mt={3} display="flex" gap={2} justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{ minWidth: 70 }}
                            size="small"
                            onClick={onOk}
                            disabled={isLoading}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ minWidth: 70 }}
                            size="small"
                            color="secondary"
                            onClick={handleClose}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
};

export default SelectDownloadMethod;