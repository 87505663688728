import { Box, IconButton, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { StyledTaskBox } from 'views/dashboard/OrgDashboard/styles';
import DocumentByStatus from './dashboard-analytics/document-by-status';
import NumberOfDocuments from './dashboard-analytics/number-of-documents';
import UsersTable from "./dashboard-analytics/users-table"
import OnlyApproved from './dashboard-analytics/only-approved';
import useTitle from 'hooks/useTitle';

function DashboardAnalytics() {
  useTitle("Dashboard");

  const navigate = useNavigate();


  return (
    <Box sx={{ overflow: "scroll", height: "80vh", paddingBottom: "30px" }}>
      <Box sx={{ display: "flex" }}>
        <DocumentByStatus />
        <NumberOfDocuments />
        {/* <OnlyApproved/> */}
      </Box>
      <UsersTable />
    </Box>

  );
}






export default DashboardAnalytics
