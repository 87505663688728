import QuantumRoutesContainer from "app/QuantumRoutesContainer";
import RoutesContainer from "app/RoutesContainer";

export const APPS = [
    {
        subdomain: 'localhost',
        app: RoutesContainer,
        main: true
    },
    {
        subdomain: 'test-ca',
        app: RoutesContainer,
        main: true
    },
    {
        subdomain: 'atom',
        app: RoutesContainer,
        main: true
    },    
    {
        subdomain: 'quantum',
        app: QuantumRoutesContainer,
        main: false
    },    
    {
        subdomain: 'qtmtest',
        app: QuantumRoutesContainer,
        main: false
    }
];