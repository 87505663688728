import { getProfile } from "api/services/users";
import Loader from "components/Loader";
import { createContext, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";

export const AppProfileContext = createContext<any>(null);

function AppProfileProvider({ children }) {

  const { data, isLoading, error }: ResType = useQuery(["user"],
    getProfile,
    {
      onSuccess: (res: any) => {
        if (res?.data) {
          if (res?.data?.profile?.config == null) {
            // window.location.href = 'http://localhost:3000/login';
          }
          // if (res?.data?.profile?.config !== null) {
          //   if (data?.data?.profile?.config?.quantum) {
          //     if (!window.location.href.includes('quantum')) {
          //       window.location.href = 'http://quantum.localhost:3000/dashboard';
          //     }
          //   }
          //   if (data?.data?.profile?.config?.atom) {
          //     if (window.location.href.includes('quantum')) {
          //       window.location.href = 'http://localhost:3000/dashboard';
          //     }
          //   }
          // }
        }
      },
      cacheTime: 0,
      enabled: !!localStorage.getItem("token"),
    }
  );

  useEffect(() => {
    if (data?.data?.profile?.config !== null) {

    }
  }, []);

  if (isLoading) return <Loader minHeight="60vh" />;

  return (
    <AppProfileContext.Provider value={{ data: data?.data?.profile?.config, isLoading, error }}>
      {children}
    </AppProfileContext.Provider>
  );
}

export const useAppData = () => useContext(AppProfileContext);

export default AppProfileProvider;
