import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import io from 'socket.io-client';
import ServiceCard from "views/services/ServiceCard";

const Ondemand = () => {
  const SERVER = "http://localhost:5000";

  const [services, setServices] = useState([]);
  let page = 1;
  let limit = 9;
  let offset = (page - 1) * limit;

  const parms = {
    search: '',
    category: '',
    subCategory: '',
    offset: offset,
    limit: limit
  }

  const doEveryMinute = (socket: any) => {
    setTimeout(() => {
      setInterval(() => doEveryMinute(socket), 3000);
      socket.send(JSON.stringify({
        event: 'message',
        api: 'getServices',
        query: parms,
        data: '',
        userId: '1004'
      })
      )
    }, 3000);
  }

  useEffect(() => {
    const socket = io(SERVER, { transports: ["websocket"] });

    // const socket = io.connect('ws://localhost:5000'); // Replace with your backend WebSocket server URL
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');

      // doEveryMinute(socket);

      // socket.send();
    });

    socket.on('onMessage', (data: any) => {
      console.log('Received message from server:', data);
      if (data && data?.content && data?.content) {
        const content = JSON.parse(data?.content);
        if (content && content?.result) {
          const totalPages = content?.result?.totalCount/10;
          const curr = JSON.parse(JSON.stringify(services));
          const alphaNumeric = curr.concat(content['result']);
          setServices(alphaNumeric);
        }
      }
    });



    // Clean up the WebSocket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [services]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >

      On demand

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {services?.map((service: any, index: number) => (
          <Grid item xs={4}>
            <ServiceCard data={service} key={index} />
          </Grid>
        ))}
      </Grid>

    </div>
  );
};

export default Ondemand;
