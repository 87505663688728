import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import {
  PersonAddAltOutlined,
  ChatRounded,
  InfoRounded,
  ModeEditOutlineOutlined,
  TuneRounded,
  Timer,
  FindReplaceOutlined,

} from '@mui/icons-material';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import DrawerWrapper from "components/DrawerWrapper";
import AddUser from './AddUser';
import Comments from './Comments';
import DocumentDetails from './DocumentDetails';
import ContractExpairy from './ContractExpairy';
import SettingsDetails from './Settings';
import EsignDetails from './Esign';
import FindDetails from './FindDoc';
import { Drawer } from '@mui/material';
import Approval from './Approval';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import CustomTooltip from 'components/Tooltip';



const RightPanel = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);



  const openDrawer = (icon) => {
    setSelectedIcon(icon);
    setDrawerOpen(true);
  };

  return (
    <>
      <div style={{ position: 'absolute', top: 130, right: 0, display: 'flex', height: '100%', color: '#182F53' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <IconButton onClick={() => openDrawer('add')}>
            <CustomTooltip title="Add users">
              <PersonAddAltOutlined style={{ fontSize: '28px', color: '#182F53' }} />
            </CustomTooltip>
          </IconButton>
          <IconButton onClick={() => openDrawer('chat')}>
            <CustomTooltip title="Comments">

              <ChatRounded style={{ fontSize: '28px', color: '#182F53' }} />
            </CustomTooltip>

          </IconButton>
          <IconButton onClick={() => openDrawer('details')}>
            <CustomTooltip title="Document details">

              <InfoRounded style={{ fontSize: '28px', color: '#182F53' }} />
            </CustomTooltip>

          </IconButton>
          {/* <IconButton onClick={() => openDrawer('sign')}>
          <CustomTooltip title ="E-sign">

            <ModeEditOutlineOutlined style={{ fontSize: '28px', color: '#182F53' }} />
            </CustomTooltip>
          </IconButton> */}

          {/* <IconButton onClick={() => openDrawer('tune')}>
          <CustomTooltip title ="Customization">

            <TuneRounded style={{ fontSize: '28px', color: '#182F53' }} />
            </CustomTooltip>

          </IconButton> */}
          <IconButton onClick={() => openDrawer('timer')}>
            <CustomTooltip title="Contract expiry date">

              <CalendarMonthIcon style={{ fontSize: '28px', color: '#182F53' }} />
            </CustomTooltip>

          </IconButton>
          {/* <IconButton onClick={() => openDrawer('findReplace')}>
          <CustomTooltip title ="Find and Replace">

            <FindReplaceOutlined style={{ fontSize: '28px', color: '#182F53' }} />
            </CustomTooltip>

          </IconButton> */}
          <IconButton onClick={() => openDrawer('approval')}>
            <CustomTooltip title="Approvals">

              <DeviceHubOutlinedIcon style={{ fontSize: '28px', color: '#182F53' }} />
            </CustomTooltip>

          </IconButton>
        </div>
      </div>

      {isDrawerOpen && selectedIcon === 'add' && (
        <DrawerWrapper open={isDrawerOpen} setOpen={setDrawerOpen} title="Add Users" width={400}>
          <AddUser />
        </DrawerWrapper>
      )}
      {isDrawerOpen && selectedIcon === 'chat' && (
        <DrawerWrapper open={isDrawerOpen} setOpen={setDrawerOpen} title="Comments" width={400}>
          <Comments />
        </DrawerWrapper>
      )}
      {isDrawerOpen && selectedIcon === 'details' && (
        <DrawerWrapper open={isDrawerOpen} setOpen={setDrawerOpen} title="Document Details" width={400}>
          <DocumentDetails />
        </DrawerWrapper>
      )}

      {isDrawerOpen && selectedIcon === 'sign' && (
        <DrawerWrapper open={isDrawerOpen} setOpen={setDrawerOpen} title="E-sign" width={400}>
          <EsignDetails />
        </DrawerWrapper>
      )}

      {isDrawerOpen && selectedIcon === 'tune' && (
        <DrawerWrapper open={isDrawerOpen} setOpen={setDrawerOpen} title="Customization" width={400}>
          <SettingsDetails />
        </DrawerWrapper>
      )}
      {isDrawerOpen && selectedIcon === 'timer' && (
        <DrawerWrapper open={isDrawerOpen} setOpen={setDrawerOpen} title="Contract Expiry date" width={400}>
          <ContractExpairy setDrawerOpen={setDrawerOpen} />
        </DrawerWrapper>
      )}
      {isDrawerOpen && selectedIcon === 'findReplace' && (
        <DrawerWrapper open={isDrawerOpen} setOpen={setDrawerOpen} title="Find and" width={400}>
          <FindDetails />
        </DrawerWrapper>
      )}

      {isDrawerOpen && selectedIcon === 'approval' && (
        <DrawerWrapper open={isDrawerOpen} setOpen={setDrawerOpen} title='Approval Processes' width={400}>
          <Approval />
        </DrawerWrapper>
      )}


    </>
  );
};

export default RightPanel;
