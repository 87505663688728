import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import React, { useState } from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

interface StateProps {
  msg: string;
  action: () => void;
}

type ContextProps = (args: StateProps) => void;

export const ConfirmDialogContext = React.createContext<ContextProps>(() => { });

function ConfirmDialogProvider({ children }: any) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = React.useState<StateProps>({
    msg: "",
    action: () => { },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onOk = () => {
    setLoading(true);
    state.action();
    handleClose();
  };

  const confirm = (args: StateProps) => {
    const { msg, action } = args;
    setLoading(false);
    setOpen(true);
    setState({
      msg,
      action,
    });
  };

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
        <Box p={2}>
          <Box>
            <Typography
              color="primary"
              gutterBottom
              variant="subtitle1"
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              {/* Warning <WarningAmberRoundedIcon color="secondary" fontSize="medium" /> */}
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{fontWeight:"800",color:"black"}}>
              {state.msg}
            </Typography>
          </Box>
          <Box mt={3} display="flex" gap={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              sx={{ minWidth: 70 }}
              size="small"
              onClick={onOk}
              disabled={isLoading}
            >
              Ok
            </Button>
            <Button
              variant="outlined"
              sx={{ minWidth: 70 }}
              size="small"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
    </ConfirmDialogContext.Provider>
  );
}

export const useConfirm = () => React.useContext(ConfirmDialogContext);

export default ConfirmDialogProvider;
