import { getComments, getUsersToProcess, sendMessage } from "api/services/quantum/template";
import { useMutation, useQuery } from "react-query";
import { ResType, SubmitType } from "types";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Divider, IconButton, Typography } from "@mui/material";
import Members from "components/Members";
import { useRef, useState } from "react";
import { getUsers } from "api/services/users";
import { snack } from "components/toast";
import Message from "./Message";
import { StyledChatInput } from "views/chats/styles";
import AttachmentRoundedIcon from "@mui/icons-material/AttachmentRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

const QuantumComments = () => {
  const processId1 = useParams()?.id;
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [usersListDeatils, setUsersList1]: any = useState([]);
  const [newMessage, SetNewMessage] = useState("");
  const [chatList, setChatList]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: userNames, isLoading }: ResType = useQuery("users", getUsers, {
    onSuccess(res1: any) {
      setUsersList1(res1?.data);
    },
  });

  const { data, isLoading: UHSUS }: ResType = useQuery(["comments", processId1], getComments, {
    onSuccess: (data1: any) => {
      setChatList(data1?.data);
      // setActivityDetails(data1.data[0]);
    },
  });

  const { data: users, isLoading: loader }: ResType = useQuery(
    ["get-users", processId1],
    getUsersToProcess,
    {
      onSuccess(res: any) {
        setUsersList(res?.data);
      },
    }
  );

  const { mutate } = useMutation(sendMessage, {
    onSuccess: (res) => {
      setChatList(res?.data);
      setMessage("");
      snack.success("Comment added Successfully");
      // queryClient.invalidateQueries("get-users");
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });

  const handleChange = (e) => {
    SetNewMessage(e.target.value);
  };

  const handleClick = async () => {
    await mutate({ processId1, newMessage });
  };
  const handleSubmit = async (e: SubmitType) => {
    e.preventDefault();
    if (!isInputEmpty) {
      // Check if input is not empty or only spaces
      await mutate({ processId1, newMessage: message });
    }
  };

  const onMessageChange = (e: any) => {
    setMessage(e.target.value);
    setIsInputEmpty(e.target.value.trim() === ""); // Check if input is empty or contains only spaces
  };

  return (
    <Box height={"80vh"}>
      <Box >
        <Box>
          <Box p={1}>
            <Members
              size="small"
              data={users?.data?.map((item: any) => ({
                src: item?.imageUrl,
                title: item?.fullName,
              }))}
            />
          </Box>
          <Typography color="primary">
            Users: <b>{users?.data?.length}</b>
          </Typography>
        </Box>
        <Divider />

        <Box height={"60vh"} overflow={"auto"}>
          {chatList &&
            chatList.length >= 0 &&
            chatList.map((message, index) => (
              <Message key={index} data={message} members={usersList} />
              // <Typography>{item.message}</Typography>

            ))}
        </Box>
      </Box>
      <StyledChatInput>
        <form onSubmit={handleSubmit}>
          <input
            value={message}
            onChange={onMessageChange}
            autoFocus
            type="text"
            placeholder="Type a comment..."
          />
          <div>
            <IconButton
              type="submit"
              size="small"
              color="secondary"
              disabled={isInputEmpty} // Disable the send button when input is empty
            >
              <SendRoundedIcon />
            </IconButton>
          </div>
        </form>
        <input onChange={handleChange} ref={inputRef} type="file" style={{ display: "none" }} />
      </StyledChatInput>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default QuantumComments;
