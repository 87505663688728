import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StyledButtonFilterItem } from "quantumpages/templates/styles";
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { deleteDocument, getUsersToProcess } from "api/services/quantum/template";
import { useState } from "react";
import { ResType } from "types";
import Members from "components/Members";
import { useMutation, useQuery } from "react-query";
import Loader from "components/Loader";
import { snack } from "components/toast";
import { useConfirm } from "context/ConfirmDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledProfileNav, StyledProfileNavItem } from "views/clients/styles";
import RouterLink from "components/RouterLink";
import { quantumDashboardMenu } from "data/constants";

function Nav() {
  const [usersList, setUsersList] = useState<any[]>([]);
  const processId1 = useParams()?.id;
  const processId = useParams()?.id
  const location = useLocation();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const handleClick = (path) => {
    navigate(path);
  };



  return (
    <Box

    >


      <StyledProfileNav>
        {quantumDashboardMenu.map((item: any, index) => (
          <RouterLink to={`/dashboard/${item.path}`} key={index}>
            <StyledProfileNavItem active={(location.pathname.includes(item?.path) || location.pathname.includes(item?.subPath)) ? 1 : 0}>
              {item.title}
            </StyledProfileNavItem>
          </RouterLink>
        ))}
      </StyledProfileNav>

    </Box>
  );
}

export default Nav;
