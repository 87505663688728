import { Box, Breadcrumbs, Typography } from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import RouterLink from "components/RouterLink";
import ClientDataProvider, { ClientDataContext } from "context/ClientData";
import useTitle from "hooks/useTitle";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { quantumClientMenu } from "data/constants";
import { StyledProfileNav, StyledProfileNavItem } from "views/clients/styles";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import { useEffect } from "react";
import BlockIcon from "@mui/icons-material/Block";

function QuantumClientLayout() {
  useTitle("Clients");
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <ClientDataProvider>
      <Box sx={{ position: "sticky", top: 55, bgcolor: "white", zIndex: 2 }}>
        <ClientDataContext.Consumer>
          {({ data }) => {
            let content;
            if (data && data.data) {
              // Customize the return value based on the data.data value

              content = (
                <>
                  <Box p={2}>
                    <Breadcrumbs>
                      <LinkRouter
                        underline="hover"
                        color="inherit"
                        to="/quantumclients"
                      >
                        Clients
                      </LinkRouter>
                      <Typography>
                        {data.data.displayName} - {data.data.clientId}
                      </Typography>
                    </Breadcrumbs>
                  </Box>
                  <StyledProfileNav>
                    {quantumClientMenu.map((item, index) => (
                      <RouterLink
                        to={`/quantumclients/${params.clientId}${item.path}`}
                        key={index}
                      >
                        <StyledProfileNavItem
                          active={location.pathname.includes(item.path) ? 1 : 0}
                        >
                          {item.title}
                        </StyledProfileNavItem>
                      </RouterLink>
                    ))}
                  </StyledProfileNav>
                </>
              );

            } else {
              content = (
                <Box
                  minHeight="80vh"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    p={2}
                    borderRadius={2}
                    minWidth={300}
                    textAlign="center"
                    bgcolor="rgb(253, 237, 237)"
                  >
                    <BlockIcon
                      fontSize="large"
                      sx={{
                        color: "rgb(95, 33, 32)",
                      }}
                    />
                    <Typography variant="subtitle2" color="rgb(95, 33, 32)">
                      Access Denied
                    </Typography>
                  </Box>
                </Box>
              );
            }
            return (
              <>
                {content}

              </>
            );
          }}
        </ClientDataContext.Consumer>
      </Box>
      <Outlet />
    </ClientDataProvider>
  );

}

export default QuantumClientLayout;
