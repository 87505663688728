import { Avatar, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { getDocmentDetails } from "api/services/quantum/template";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom"
import { ResType } from "types";
import moment from "moment";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from "react";
import Loader from "components/Loader";
import BottomBar from "./updateDetailsButton";
import { CustomSelect } from "views/taskview/Details/Fields";
import { PriorityEnum } from "data/constants";
import { getTitle } from "utils";
import DetailSection from "./DetailSectionQtm";


const DocumentDetails = () => {
    const [activityDetails, setActivityDetails]: any = useState({});
    const processId = useParams()?.id;
    const [state, setState] = useState<any>({});

    const { data, isLoading }: ResType = useQuery(
        ["Docdetails", processId],
        getDocmentDetails,
        {
            onSuccess: (data1: any) => {
                setActivityDetails(data1.data[0]);
                setState(data?.data[0]);
            }
        }
    );

    useEffect(() => {
        setState(data?.data[0]);
    }, [data])
    const handleChange = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    if (isLoading) return <Loader />;
    return (
        <Grid container spacing={3}>

            <Grid item xs={12} sm={12} >
                <Typography color={"#000000"} variant="h5" > Owner of the Document</Typography></Grid>
            <Grid item xs={4} >
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Avatar src={activityDetails?.user?.imageUrl || ""} />
                </Box>
            </Grid>

            <Grid item xs={8} >
                <Typography variant="h6" color='primary'>{activityDetails?.user?.fullName}</Typography>
                <Typography variant="body1">{activityDetails?.user?.email}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Typography sx={{ fontWeight: "500" }} color="primary" variant="subtitle2">Organization Name</Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
                <Typography variant="body1">{activityDetails?.organization?.primaryContactFullName}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography sx={{ fontWeight: "500" }} color="primary" variant="subtitle2">Document Name</Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            type="text"
                            size="small"
                            name="templateName"
                            value={state?.templateName || ""}
                            onChange={handleChange}
                            inputProps={{
                                minLength: 3,
                                maxLength: 100
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DetailSection label="Priority">
                    <CustomSelect
                        value={state?.priority || ""}
                        onChange={handleChange}
                        options={Object.values(PriorityEnum).map((item) => ({
                            label: item,
                            value: item,
                        }))}
                        name="priority"
                    />
                </DetailSection>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" color="primary" >Created at:</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Typography sx={{ color: "#535353" }}>{moment(activityDetails?.createdAt).format("DD-MM-YYYY")}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" color="primary" >Updated at:</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Typography sx={{ color: "#535353" }}>{moment(activityDetails?.lastUpdated).format("DD-MM-YYYY")}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" color="primary" >Status</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Button
                    startIcon={<FiberManualRecordIcon sx={{ height: "10px" }} />}
                    style={{ color: activityDetails?.status ? "#182F53" : "#147804" }}
                    variant="outlined"
                    sx={{ borderRadius: "17px", fontSize: "10px", fontWeight: "500", paddingTop: "0px", paddingBottom: "0px", mr: "10px" }}
                >
                    {getTitle(state?.documentStatus)}
                </Button>
            </Grid>
            <BottomBar data={data?.data?.[0]} setState={setState} state={state} processId={processId} />

        </Grid>
    )
}

export default DocumentDetails